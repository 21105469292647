
import React, {useState, useEffect, useRef} from 'react';
import  {Router,Routes, Route, Link as rLink,   useRoutes, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
// import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Popover from '@mui/material/Popover';

import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';


import { Link, AppBar, Toolbar, Container, Avatar, Hidden, IconButton, Paper, Drawer, Divider, List, ListItem, TextField, Box} from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";


import { styled } from '@mui/material/styles'; 
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';  // https://mui.com/material-ui/material-icons/?query=arrow+down


import Menu from '@mui/material/Menu';



function BasicMenu({title}) {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItemAnchorEl, setMenuItemAnchorEl] = React.useState(null);

  const [anchorSubEl, setAnchorSubEl] = React.useState({evt: null, itemMenu: null});
  const [menuItemAnchorSubEl, setMenuItemAnchorSubEl] = React.useState(null);
  
  const openSub = Boolean(anchorSubEl.evt);
  const open = Boolean(anchorEl);
 

  const navigate = useNavigate();

   
  
  const handleClick= (event, key) => {
  
    
    event.preventDefault();
  

     switch (title) {
      
         case 'Setup':
              setAnchorEl(event.currentTarget)
              break;
         
         case 'Employee Additions':
              setAnchorEl(event.currentTarget)
              break;

         case 'Employee Deductions':
              setAnchorEl(event.currentTarget)
              break;

         case 'Payroll Process':
              setAnchorEl(event.currentTarget)
              break;

         case 'Payroll Adjustment':
              setAnchorEl(event.currentTarget)
              break;

         case 'Employees':
              alert('nice');
              break;

         case 'Travel / O.B':
              setAnchorEl(event.currentTarget)
              break;
        
         default:
     }

     // display grid now for the record, or use another <Listing />

  };

  const onMouseOverSubMenu = (Props, event) => {
    
    const itemName = Props.item;
    const itemStatus = Props.status; 
    
    // console.log (Props.status)
    setAnchorSubEl( {evt: event.currentTarget, itemMenu: Props.status} );  
    
  } 
  
  

  const handleMenuClick = ( Props, event) => {
    
    
    const itemName = Props.item;
    const itemStatus = Props.status;
    
    

    return () => {  
      
        
        // alert(`this is a prop (item): ${Props.item},   item Status: ${itemStatus} ` )
       //  alert (event.target.name)     
       
        switch (itemName) { // switch (Props.item) {
            
            case 'Setup':
              
                  if (itemStatus === 'Type of Allowance')                      {  navigate ("/dashboard/pms/type-of-allowance")  };
                  if (itemStatus === 'Type of Incentive / Bonuses')            {  navigate ("/dashboard/pms/type-of-incentive")  };
                  if (itemStatus === 'Type of Deduction')                      {  navigate ("/dashboard/pms/type-of-deduction")  };
                  if (itemStatus === 'Type of Loan')                           {  navigate ("/dashboard/pms/type-of-loan")  };
                  
                  if (itemStatus === "Gov't Table of Contributions & taxes")   { navigate ("/dashboard/pms/sss-table") };  // the default is: SSS Contri. Table
                    
                  handleClose();
                  break; 
                  
            case 'Employee Additions':
                  // if (itemStatus === 'Active')     {  window.location = '/dashboard/hr/employees/active'};    
                  
                  if (itemStatus === 'Allowances')           {  navigate ("/dashboard/pms/employee-additions/allowances")  };
                  if (itemStatus === 'Incentives')           {  navigate ("/dashboard/pms/employee-additions/incentives")  };
                  if (itemStatus === 'Other Additions')      {  navigate ("/dashboard/pms/employee-additions/other-additions")  };
                  
                  handleClose();
                  break; 

            case 'Employee Deductions':
                    
                    if (itemStatus === 'Loans')              {  navigate ("/dashboard/pms/employee-deductions/loans")  };
                    if (itemStatus === 'Deductions')         {  navigate ("/dashboard/pms/employee-deductions/deductions")  };
                    if (itemStatus === 'Other Deductions')   {  navigate ("/dashboard/pms/employee-deductions/other-deductions")  };
                    
                    handleClose();
                    break;       

            case 'Leaves':
                    
                  if (itemStatus === 'Type of Leaves')            {  navigate ("/dashboard/hr/typeofleaves")  };
                  if (itemStatus === 'Leave Application')         {  navigate ("/dashboard/hr/leaves")  };
                  if (itemStatus === 'Leave Credit & Balances')   {  navigate ("/dashboard/hr/leave-credit-and-balances")  };
                  
                  // if (itemStatus === 'Type of Leaves')         {  navigate ("/dashboard/hr/leave-credit-&-Balances")  };
                  // if (itemStatus === 'Type of Leaves')         {  navigate ("/dashboard/hr/leave-credit-Generations")  };
                  // if (itemStatus === 'Type of Leaves')         {  navigate ("/dashboard/hr/leave-monitoring")  };
                    
                  handleClose();
                  break;       
            

            case 'Performance Eval.':
                 
                  if (itemStatus === 'Recommendations'){alert('Recommendations')};
                  if (itemStatus === 'Promotions'){alert('Promotions')};
                  
                  handleClose();
                  break;

            
            default:
                  alert('No Selection!');

        }

         // return null;    
    }
     
  };

  
  const handleClose = () => {
       setAnchorEl(null);
       setAnchorSubEl({evt: null, itemMenu: null})
  };


  
  return (

    <div>
      
      {
          <Popover
                open={ anchorSubEl.itemMenu === "Gov't Table of Contributions & taxes"? openSub: false }
                anchorEl={ anchorSubEl.evt }
                anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                targetOrigin={{horizontal: 'right', vertical: 'top'}}
                // placement="right-end"
                onClose={handleClose}
                // onRequestClose={handleClose} 
            >				  
                <Box > 
                      <MenuItem key='D1' onMouseDown = { () => navigate ("/dashboard/pms/sss-table") }                onClick = { handleClose} > SSS Table </MenuItem>
                      <MenuItem key='D2' onMouseDown = { () => navigate ("/dashboard/pms/philhealth-table") }         onClick = { handleClose} > PhilHealth Table </MenuItem>
                      <MenuItem key='D3' onMouseDown = { () => navigate ("/dashboard/pms/pag-ibig-table") }           onClick = { handleClose} Divider sx={{ ...(Divider && { margin: 0 }) }} > Pag-IBIG Table </MenuItem>
                      <MenuItem key='D4' onMouseDown = { () => navigate ("/dashboard/pms/wtax-table/semi-monthly") }  onClick = { handleClose} > W/Tax Table </MenuItem>  
                </Box>

            </Popover>
      }

      {
          <Popover
                open={ anchorSubEl.itemMenu === "Loans"? openSub: false }
                anchorEl={ anchorSubEl.evt }
                anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                targetOrigin={{horizontal: 'right', vertical: 'top'}}
                // placement="right-end"
                onClose={handleClose}
                // onRequestClose={handleClose} 
            >				  
                <Box > 
                      <MenuItem key='L1' onMouseDown = { () => navigate ("/dashboard/pms/employee-deductions/loans/sss-loan") }  onClick = { handleClose} > SSS Loan </MenuItem>
                      <MenuItem key='L2' Divider sx={{ ...(Divider && { margin: 0 }) }} onMouseDown = { () => navigate ("/dashboard/pms/employee-deductions/loans/pag-ibig-loan") }  onClick = { handleClose} > Pag-IBIG Loan </MenuItem>
                      <MenuItem key='L3' onMouseDown = { () => navigate ("/dashboard/pms/employee-deductions/loans/other-loans/all") } onClick = { handleClose} > Other Loans </MenuItem>
                </Box>
                
            </Popover>
      }

     
        
      
      
      
      <Button
        id="basic-button"
        variant='contained'
        aria-controls={ open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={<ExpandMoreIcon />} 
        onClick={handleClick}
      >
         
         {title} 

      </Button>
      
      <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
              'aria-labelledby': 'basic-button',
         }}
      >
       
           {
              title === 'Setup'? 
                <>
                  <MenuItem onClick={ handleMenuClick( {item: 'Setup', status: 'Type of Allowance'}) } > Type of Allowance </MenuItem>
                  <MenuItem onClick={ handleMenuClick( {item: 'Setup', status: 'Type of Incentive / Bonuses'}) }> Type of Incentive / Bonuses </MenuItem>
                  <MenuItem onClick={ handleMenuClick( {item: 'Setup', status: 'Type of Deduction'}) }> Type of Deduction </MenuItem>
                  <MenuItem divider={Divider} sx={{ ...(Divider && { margin: 0 }) }} onClick={ handleMenuClick( {item: 'Setup', status: 'Type of Loan'}) }> Type of Loan </MenuItem>  

                  <MenuItem onMouseOver={ e => onMouseOverSubMenu( { item: 'Setup', status: "Gov't Table of Contributions & taxes" } , e) }  > Gov't Table of Contributions & taxes <ChevronRight /> </MenuItem>

                </>
                : null
           }
           
           {
               title === 'Employee Additions'? 
               <>
                 <MenuItem onClick = { handleMenuClick( {item: 'Employee Additions', status: 'Allowances'}) } > Allowances </MenuItem>
                 <MenuItem divider = { Divider} sx={{ ...(Divider && { margin: 0 }) } }  onClick={ handleMenuClick( {item: 'Employee Additions', status: 'Incentives'}) } > Incentives (Bonus) </MenuItem>
                 <MenuItem onClick = { handleMenuClick( {item: 'Employee Additions', status: 'Other Additions'}) } > Other Additions </MenuItem> 
               </>
               : null
           }
           
           {
               title === 'Employee Deductions'? 
               <>
                 <MenuItem onMouseOver={ e => onMouseOverSubMenu( { item: 'Employee Deductions', status: "Loans" } , e) } onClick = { handleMenuClick( {item: 'Employee Deductions', status: 'Loans'}) } > Loans <ChevronRight /> </MenuItem>
                 <MenuItem divider = { Divider} sx={{ ...(Divider && { margin: 0 }) }} onClick={ handleMenuClick( {item: 'Employee Deductions', status: 'Deductions'}) }> Dedutions </MenuItem>
                 <MenuItem onClick = { handleMenuClick( {item: 'Employee Deductions', status: 'Other Deductions'}) } > Other Deductions (Misc.) </MenuItem>
               </>
               : null
           }
           {
               title === 'Payroll Adjustment'? 
               <>
                 <MenuItem onClick={handleClose}>Payroll Adjustment</MenuItem>
                 <MenuItem onClick={handleClose}>Payroll Exemption</MenuItem>
               </>
               : null
           }
          
           {
             title === 'Payroll Process'? 
               <>
                 <MenuItem divider={Divider} sx={{ ...(Divider && { margin: 0 }) }}   onClick={handleClose}>Set CutOff</MenuItem>
                 <MenuItem onClick={handleClose}>Generate DTR</MenuItem>
                 <MenuItem onClick={handleClose}>Process Payroll</MenuItem>
               </>
               : null
           } 
           

           {
               title === 'Travel / O.B'? 
               <>
                 <MenuItem onClick={handleClose}>Local</MenuItem>
                 <MenuItem onClick={handleClose}>Abroad</MenuItem>
               </>
               : null
           }    
        
      </Menu>





    </div>

  );

}


const navigationLinks = [
  {  
    name: "Setup",
    href: ''
  },
  {  
    name: "Employee Additions",
    href: ''
  },
  {  
    name: "Employee Deductions",
    href: ''
  },
  {  
    name: 'Payroll Adjustment',
    href: ''
  },
  {  
    name: 'Payroll Process',
    href: ''
  },
  
  {  
    name: 'Settings',
    href: ''
  },
  
  {  
    name: 'Reports',
    href: ''
  },
  {  
    name: 'Tools',
    href: ''
  },
  {  
    name: 'Help',
    href: ''
  },

  
];



export default function BurgerMenu() {
  
  // const styles  = useStyles;
  
  const [Open, setOpen] = useState(false);

  
  return (
       
      <div style={{ width: '100%' }} >
       
          <AppBar position='relative' color='primary' >
        
            
            <Container maxWidth='xl' maxHieght='sm' id='rcorners1'>
                
                <Toolbar disableGutters>

                      
                      <Avatar sx={ { alignItems: 'left', marginLeft: '-90px', marginRight: '80px', color: 'white', backgroundColor: 'blue'} } > Pms </Avatar>                              
                      
                      <Hidden sxDown> 
                          {   
                            navigationLinks.map ((menu, index) => (
                                // <StyledPaper> <Link key={index} color='textPrimary' variant='button' underline='none' href = {menu.href} > {menu.name} </Link> </StyledPaper>
                                // <MyComponent> </MyComponent>
                                //    <Link sx={ {marginRight: 5} } key={index} color='white' variant='button' underline='none' href = {menu.href} > {menu.name} </Link>
                                
                                <BasicMenu key={index} title={menu.name} index={index} />
                                
                            )) 
                          }
                      </Hidden>
                    
                      <Hidden smUp>
                          <IconButton>
                              <MenuIcon onClick={() => setOpen(true)} />
                          </IconButton>
                      </Hidden>
                    
                    

                </Toolbar>
            </Container>
             
             <Drawer anchor='right' open = {Open} onOpen = {  () => setOpen(true)} onClose = { () => setOpen(false)} >
               <div>
                <IconButton> <ChevronRight onClick={() => setOpen(false)} /> </IconButton>
               </div>
               <Divider /> 
                   <List>
                        {   
                          navigationLinks.map ((menu, index) => (
                              <ListItem key={index}> 
                                  <Link color='textPrimary' variant='button' underline='none' href = {menu.href} > {menu.name} </Link> 
                              </ListItem>
                          )) 
                        }
                    </List>  
             </Drawer>    

          </AppBar>    

      </div>     

  )
}