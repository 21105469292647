import React from 'react';

import { FormControl, FormLabel, RadioGroup as MUIRadioGroup, FormControlLabel, Radio } from '@mui/material';


export default function RadioGroup (props) {
    
   const {name, label, value, onChange, items } = props;

   return (
          <FormControl>
                <FormLabel> {label} </FormLabel>
                <MUIRadioGroup 
                          row
                          name={name}
                          value={value}
                          onChange={onChange}
                >
                     {   
                        items.map( (item) => (
                                       <FormControlLabel 
                                             key={item.id} 
                                             label={item.label} 
                                             value={item.id} 
                                             control={<Radio />}
                                       />
                                 ))
                     }           
   
                </MUIRadioGroup>
          </FormControl>
   )   
};