
// use for user authentication purposes ...
import {createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';


export const getAllShifts =  createAsyncThunk("shifts/getAll",  async () => {
      
      let response = [];

      try {
            response = await Axios.get(`/api/shifts`)
                                  .then ((res) => res.data);
            
      } catch (error) {
            alert (`error ${error}`)
      }

      return response;

 });


 const initialState = {
      List:       [],
      Profile:    {
                    ShiftID:     '',
                    Shiftname:   '',
                  },
      fetchStatus:     'Idle'   // 'Idle' || 'Loading' || 'Succeeded' || 'Failed' 
};



const shiftsSlice = createSlice (
   {
      name: 'shifts',
      initialState,
      reducers:  {

      },
      
      extraReducers: (builder) => {
               builder
                 .addCase(getAllShifts.pending, (state, action) => {
                       state = {...state, fetchStatus: 'Idle',  List: [] };    
                       return state;       
                 })
                 .addCase(getAllShifts.fulfilled, (state, action) => {
                     //  alert('Promise has been fulfilled !!!')  
                       state = {...state, fetchStatus: 'Succeeded',  List: action.payload };
                     //  console.log(`status state ${JSON.stringify(state.List)} `) 
                       return state;       
                 })
                 .addCase(getAllShifts.rejected, (state, action) => {
                       state = {...state, fetchStatus: 'Rejected',  List: [] };    
                       return state;       
                 })
      }      
            
   }
)


export const  allShifts = (state) => state.shifts.List;
export const  shiftData = (state) => state.shifts.Profile;
export const  fetchStatus = (state) => state.shifts.fetchStatus;
// export const { setStatus, setCurrentID } = statusSlice.actions;
export default shiftsSlice.reducer;
