import { 
         FormControl, FormControlLabel, Checkbox as MuiCheckBox , Typography 
       } from '@mui/material';

       
 export default function CheckBox (Props) {
       const {name, label, value, fontColor, onChange} = Props;

       const convertToDefaultParam = (name, value) => (
              { 
                 target:  { name, value },
              }
       ) 
       
       return (
             <FormControl>
                  <FormControlLabel
                        control={<MuiCheckBox 
                                       name={name}
                                       color='primary'
                                       checked={value}
                                       onChange={ e => onChange ( convertToDefaultParam(name, e.target.checked)) } 
                                       size="small" 
                                       style={{ width: "20px"}}
                                       sx={{padding: .5,}} 
                                 />}
                        // label={label}
                        // label={<Typography variant="h10" style={{ color: '#2979ff' }}> {label} </Typography>}
                        label={<Typography variant="h10" style={{ color: fontColor }}> {label} </Typography>}
                  />
             </FormControl>
       )
 }      
 


 /* <FormControl>
<FormControlLabel
      control={<MuiCheckBox 
                        name={name}
                        color='primary'
                        checked={value}
                        onChange={ e => onChange ( convertToDefaultParam(name, e.target.checked)) } 
                        size="small" 
                        style={{ width: "20px", color: fontColor !== ""? fontColor: "#000000"  }}
                        sx={{padding: .5,}} 
                  />}
      label={label}
/>
</FormControl> */ 

 // onChange={ e => onChange ( convertToDefaultParam(name, e.target.checked) ) }