
import React, {useState} from 'react';
import { Link, AppBar, Toolbar, Container, Avatar, Hidden, IconButton, Paper, Drawer, Divider, List, ListItem} from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";

import { styled } from '@mui/material/styles'; 
import ChevronRight from "@mui/icons-material/ChevronRight";



const navigationLinks = [
  {  
    name: 'Employees',
    href: ''
  },
  {  
    name: 'Applicant Tracking System (ATS)',
    href: ''
  },
  {  
    name: 'Training',
    href: ''
  },
  {  
    name: 'Succession Planning',
    href: ''
  },
  {  
    name: 'Performance Management',
    href: ''
  },
  {  
    name: 'Benefits Administration',
    href: ''
  },
  {  
    name: 'Reporting And Analytics',
    href: ''
  },

];



export default function BurgerMenu() {
  
  // const styles  = useStyles;
  
  const [Open, setOpen] = useState(false);

  
  return (
       
        <AppBar position='sticky' color='primary' >
        
            
            <Container maxWidth='xl' maxHieght='sm' id='rcorners1'>
                <Toolbar disableGutters>

                      
                      <Avatar sx={ {marginRight: 'auto', color: 'white', backgroundColor: 'blue'} } > Mgmt </Avatar>                
                      
                      <Hidden sxDown> 
                          {   
                            navigationLinks.map ((menu, index) => (
                                
                                <Link sx={ {marginRight: 5} } key={index} color='white' variant='button' underline='none' href = {menu.href} > {menu.name} </Link>
                                
                            )) 
                          }
                      </Hidden>
                    
                      <Hidden smUp>
                          <IconButton>
                              <MenuIcon onClick={() => setOpen(true)} />
                          </IconButton>
                      </Hidden>
                    
                    

                </Toolbar>
            </Container>
             
             <Drawer anchor='right' open = {Open} onOpen = {  () => setOpen(true)} onClose = { () => setOpen(false)} >
               <div>
                <IconButton> <ChevronRight onClick={() => setOpen(false)} /> </IconButton>
               </div>
               <Divider /> 
                   <List>
                        {   
                          navigationLinks.map ((menu, index) => (
                              <ListItem key={index}> 
                                  <Link color='textPrimary' variant='button' underline='none' href = {menu.href} > {menu.name} </Link> 
                              </ListItem>
                          )) 
                        }
                    </List>  
             </Drawer>    

          </AppBar>    
      
  )
}