    import {useLocation} from 'react-router-dom';
    import PropTypes from 'prop-types';

    // @mui
    import { styled } from '@mui/material/styles';
    import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';

    // utils
    import { bgBlur } from '../../../utils/cssStyles';

    // components
    import Iconify from '../../../components/iconify';

    //
    
    // import Searchbar from './Searchbar';

    import AccountPopover from './AccountPopover';
    import LanguagePopover from './LanguagePopover';
    import NotificationsPopover from './NotificationsPopover';


    import LogoHr from '../../../pages/Assets/Images/human-64.png'  
    import LogoTms from '../../../pages/Assets/Images/delivery-time-96.png'  
    import LogoPms from '../../../pages/Assets/Images/money-transfer-96.png'  
    import LogoMgmt from '../../../pages/Assets/Images/management-96.png'  

    
    // ----------------------------------------------------------------------

    const NAV_WIDTH = 280;

    const HEADER_MOBILE = 64;

    const HEADER_DESKTOP = 92;

    const StyledRoot = styled(AppBar)(({ theme }) => ({
      ...bgBlur({ color: theme.palette.background.default }),
      boxShadow: 'none',
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
      },
    }));

    const StyledToolbar = styled(Toolbar)(({ theme }) => ({
      minHeight: HEADER_MOBILE,
      [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
      },
    }));

    // ----------------------------------------------------------------------

    Header.propTypes = {
      onOpenNav: PropTypes.func,
    };

    
    const GetLastPartoftheUrl = () => ( useLocation().pathname.split('/').at(-1) )


    function initLogo  (page) {
        
       
         if (page === 'hr') {
             return  (<>
                          <div>
                            <img src={LogoHr} alt='Hr logo' style={{ width: '75px' }} /> 
                          </div>
                          <div><h2> <font color='gray'><bold>Human Resources</bold></font></h2></div>
                      </>)
          };
          
           if (page === 'tms') {
              return  (<>
                          <div>
                            <img src={LogoTms} alt='Hr logo' style={{ width: '75px' }} /> 
                          </div>
                          <div><h2> <font color='gray'><bold>Time-Keeping</bold></font></h2></div>
                       </>)
          };

          if (page === 'pms') {
            return  (<>
                        <div>
                          <img src={LogoPms} alt='Pms logo' style={{ width: '75px' }} /> 
                        </div>
                        <div><h2> <font color='gray'><bold>Payroll</bold></font></h2></div>
                    </>)
          };

          if (page === 'mgmt') {
            return  (<>
                        <div>
                          <img src={LogoMgmt} alt='Mgmt logo' style={{ width: '75px' }} /> 
                        </div>
                        <div><h2> <font color='gray'><bold>Management</bold></font></h2></div>
                    </>)
          };
       

          return null; 


    }

    

    
    export default function Header({onOpenNav}) {

        return (
            <StyledRoot>
                  
                  <StyledToolbar>
                        
                        <IconButton
                          onClick={onOpenNav}
                          sx={{
                            mr: 1,
                            color: 'text.primary',
                            display: { lg: 'none' },
                          }}
                        >
                        
                          <Iconify icon="eva:menu-2-fill" />
                        
                        </IconButton>

                        {/* <Searchbar />  */}
                        
                        
                        {/* HR ==================================================================================================================================================================  */}
                        
                        { useLocation().pathname === '/dashboard/hr/branches' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/departments' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/sections' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/statuses' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/positions' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/typeofwarnings' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/typeofleaves' ? initLogo('hr'):  null }
                        

                        { useLocation().pathname === '/dashboard/hr' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/employees/active' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/employees/inactive' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/employees/all' ? initLogo('hr'):  null }
                        
                        { useLocation().pathname === '/dashboard/hr/holidays' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/events' ? initLogo('hr'):  null }

                        { useLocation().pathname === '/dashboard/hr/leaves' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/leave-credit-and-balances' ? initLogo('hr'):  null }
                        { useLocation().pathname === '/dashboard/hr/itinerary' ? initLogo('hr'):  null }



                        {/* TMS ==================================================================================================================================================================  */}
                        { useLocation().pathname === '/dashboard/tms'? initLogo('tms'):  null } 
                        { useLocation().pathname === '/dashboard/tms/timecards'? initLogo('tms'):  null } 
                        { useLocation().pathname === '/dashboard/tms/time-attendance'? initLogo('tms'):  null } 
                        { useLocation().pathname === '/dashboard/tms/shifts'? initLogo('tms'):  null } 
                        { useLocation().pathname === '/dashboard/tms/change-shifts'? initLogo('tms'):  null } 
                        { useLocation().pathname === '/dashboard/tms/change-restday'? initLogo('tms'):  null } 
                        { useLocation().pathname === '/dashboard/tms/ot-authorizations/std'? initLogo('tms'):  null } 
                        { useLocation().pathname === '/dashboard/tms/ot-authorizations/adv'? initLogo('tms'):  null } 


                        


                        {/* PAYROLL ===============================================================================================================================================================  */}
                        { useLocation().pathname === '/dashboard/pms'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/type-of-allowance'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/type-of-incentive'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/type-of-deduction'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/type-of-loan'? initLogo('pms'):  null } 

                        { useLocation().pathname === '/dashboard/pms/sss-table'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/philhealth-table'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/pag-ibig-table'? initLogo('pms'):  null } 
                        
                        { useLocation().pathname === '/dashboard/pms/wtax-table/daily'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/wtax-table/weekly'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/wtax-table/semi-monthly'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/wtax-table/monthly'? initLogo('pms'):  null } 

                        { useLocation().pathname === '/dashboard/pms/employee-additions/allowances'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/employee-additions/incentives'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/employee-additions/other-additions'? initLogo('pms'):  null } 
                        
                        { useLocation().pathname === '/dashboard/pms/employee-deductions/loans/sss-loan'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/employee-deductions/loans/pag-ibig-loan'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/employee-deductions/loans/other-loans/all'? initLogo('pms'):  null } 
                        {/* { useLocation().pathname === `/dashboard/pms/employee-deductions/loans/other-loans/${GetLastPartoftheUrl()}`? initLogo('pms'):  null }  */}
                        
                        
                        {/* { 
                           useLocation().pathname.slice('/dashboard/pms/employee-deductions/loans/other-loans'.length) === '/dashboard/pms/employee-deductions/loans/other-loans'? initLogo('pms'):  null 
                        }  */}
                        


                        { useLocation().pathname === '/dashboard/pms/employee-deductions/deductions'? initLogo('pms'):  null } 
                        { useLocation().pathname === '/dashboard/pms/employee-deductions/other-deductions'? initLogo('pms'):  null }


                        {/* MANAGEMETN ==================================================================================================================================================================  */}
                        { useLocation().pathname === '/dashboard/mgmt'? initLogo('mgmt'):  null } 
                        


                        <Box sx={{ flexGrow: 1 }} />

                        
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={{
                            xs: 0.5,
                            sm: 1,
                          }}
                        >
                          {/* <LanguagePopover /> */}
                          <NotificationsPopover />
                          <AccountPopover />
                        
                        </Stack>

                  </StyledToolbar>

            </StyledRoot>
        );
      
    }
