import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';

import './generatePdfStyles.css';

// Create styles
// // const styles = StyleSheet.create({
// //   page: {
// //     flexDirection: 'row',
// //     backgroundColor: '#E4E4E4',
// //   },
// //   section: {
// //     margin: 10,
// //     padding: 10,
// //     flexGrow: 1,
// //   },
// // });

// Create Document Component
// const MyDocument = () => {
//    return alert ('nice')
//             // <PDFViewer>
//             //       <Document>
//             //          <Page size="A4" style={styles.page}>
//             //             <View style={styles.section}>
//             //                <Text>Section #1</Text>
//             //             </View>
//             //             <View style={styles.section}>
//             //                <Text>Section #2</Text>
//             //             </View>
//             //          </Page>
//             //       </Document>
//             // </PDFViewer> 
         
// }

// Create Document Component

// Create styles
// // This will log the width of the viewport
// console.log(window.innerWidth);

// // This will log the width of the frame viewport within a frameset
// console.log(self.innerWidth);

// // This will log the width of the viewport of the closest frameset
// console.log(parent.innerWidth);

// // This will log the width of the viewport of the outermost frameset
// console.log(top.innerWidth);


const styles = StyleSheet.create({
        page: {
                  backgroundColor:  "#6495ED", // "#d11fb6",
                  color: "white",
              },
        section: {
                 margin: 10,
                 padding: 10,
            },
        viewer: {
                width:    '100%', // window.innerWidth,  // the pdf viewer will take up all of the width and height
                height:   window.innerHeight,



        },
 });

 
export function MyDocument(fields) {
       
   


        // alert (`fields ${ JSON.stringify(fields)}`)
       
       return (
              <PDFViewer style={styles.viewer}>
                  { /* Start of the document */ }
                  <Document>
                      { /* render a single page */ }
                      <Page size="A4" style={styles.page}>
                          <View style={styles.section}>
                             <Text>(*) Required Fields </Text>
                             <Text> </Text> 
                             <Text> Total number of required fields to fill-in ( { fields.filter ( (fld) => fld !== null ).length } ) </Text>

                          </View>

                          <View style={styles.section}>
                                {     
                                   fields.filter ( (fld) => fld !== null )
                                         .map ( (fld, idx) =>  {
                                                    return <Text> {`${idx+1}. ${fld}`} </Text>
                                            } )
                                }
                          
                          </View>
                          
                          <View style={styles.section}>
                             <Text> To continue saving records, please provide the necessary information. </Text>
                          </View>

                      </Page>

                  </Document>

              </PDFViewer>
        );

 }

 
          
         

function ModalPDF() {

   // return  <MyDocument />
   // return MyDocument();

   
   return ( 
            <>

               

                  <h2>Animated Modal with Header and Footer</h2>

                  {/* Trigger/Open The Modal  */}
                  <button id="myBtn">Open Modal</button>
                  
                  {/* The Modal */}
                  <div id="myModal" className="modal">
                  
                        {/* Modal content */}
                        <div className="modal-content">
                              <div className="modal-header">
                                 <span className="close">&times;</span>
                                 <h2>Modal Header</h2>
                              </div>
                              
                              <div className="modal-body">
                                 {/* <p>Some text in the Modal Body</p>
                                 <p>Some other text...</p> */}
                                   <MyDocument />
                              </div>

                              <div className="modal-footer">
                                 <h3>Modal Footer</h3>
                              </div>
                        </div>
                  
                  </div>
           
            </>       

          );

}
 
export default ModalPDF ;

