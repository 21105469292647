import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import { useDispatch, useSelector } from 'react-redux';
 

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
          Button, Box,  Tab, Paper, TextField, 
          MenuItem, InputLabel,
          Checkbox,
          Grid, 
          FormGroup, 
          FormControl, FormLabel, RadioGroup as MUIRadioGroup, FormControlLabel, Radio, 
          ThemeProvider, useTheme, createTheme,Typography,
          TableContainer, Table, TableBody, TableHead, TableRow, TableCell, TablePagination
          
        } from '@mui/material';


import { styled as styledMui } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';


import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {TabContext, TabList,TabPanel} from '@mui/lab';
import Stack from '@mui/material/Stack';


import FavoriteIcon from '@mui/icons-material/Favorite' 
 // import Typography from 'src/theme/overrides/Typography';
 
import styled from '@emotion/styled'
  
import { useForm, Form,
          TxtField, 
          CheckBox as CheckBox1,
          DatePicker, 
          DateTimePicker, DateTimePickerStaticLandscape,
          Select, 
          RadioGroup
        } from '../../components/controls' 


// import PhotoX from "./Photo/Laura.jpg";  // launch-101.png
import {ModalDisplay} from '../../api/functions';



import { data as dataProfile, fetchTypeofLeaves, fetchProfile, allTypeofLeaves, typeofLeaveData, doFetch, fetchStatus, addNewRecord, editRecord  } from '../../features/hr/typeofLeavesSlice';


const Div = styled.div({
   color: 'red'
})

 // use this to apply your own customized theme.
 const theme = createTheme({
      palette:  {
         mode: 'light',
         primary: {
             main: "#D433FF",
         }
      },
      Typography: {
           h1:  {
              fontSize: "1000px",
           }
      }

})

const H1 = styled.h1(
      {
        fontSize: 14,
      },
      props => ({ color: props.color })
)    

function a11yProps (index) {
    
      return {
            id:              `full-width-tab-${index}`,
           'aria-controls':  `full-width-tabpanel-${index}`,
      };
    }
  




// ====================================================================================================================================================================================

const TypeofLeave =({UserRecordLevelRights, List, data, setData}) => {
           
      const dispatch = useDispatch(); 
      
      // In order to gain access to the child component instance, you need to assign it to a `ref`, so we call `useRef()` to get one
      const refDataEntry = useRef();

      const [DialogActionsVisibility,setDialogActionsVisibility] = useState(true);

      
      const [Mode, setMode]  = useState(data.Mode);
      const [Open, setOpen] = useState(true);
      const [validatePdfReport, setValidatePdfReport] = useState({fields: [], validateReport: false});

      
      const fStatus  = useSelector (fetchStatus);
      
      const curData = useSelector(typeofLeaveData) 
      const [Profile, setProfile] =  useState({...dataProfile});
      const [isGenerateNewID, setGenerateNewID] =  useState(false);    
      
      

      useEffect ( () => {
            dispatch(doFetch(Mode === 'AddNewRecord'? 'Init': 'Idle'))
      }, [dispatch]);  
      

      
      useEffect ( () => {
            
            if  (Mode === 'AddNewRecord') {

                  // Need a Dropdown List for multiple areacode accounts selections by the admin or coordinators based on record-level user rights.
                  setGenerateNewID (true); 
                  setProfile ( {...dataProfile} ); 

                  
            } else {
                  
                  if (fStatus === 'Idle') {
                        dispatch (fetchProfile( (typeof data.dataProfile.LVType !== 'undefined') ? data.dataProfile.LVType: curData.Profile.LVType   ) ) ;   
                  // setProfile ( curData );  
                  }
                  if (fStatus === 'Succeeded' && (typeof curData !== 'undefined' && curData !== '') ) {
                        setProfile ( {...curData} );    
                  }

                  setProfile ( curData );  

            }
  
      }, [Mode, fStatus, data.dataProfile.LVType, dispatch ]) 
    
      
      useEffect(() => {

            const handleEsc = (event) => {
                  
                  if (event.key === 'Escape') {
                        OnXButton()
                  }
            };

            const handleEnterButton = (event) => {

                  if (event.keyCode === 13) {
                       // Note that this doesn't honour tab-indexes

                        event.preventDefault();

                        // Isolate the node that we're after
                        const currentNode = event.target;

                        // find all tab-able elements: 'audio, button, canvas, details, iframe, input, select, summary, textarea, video, [accesskey], [contenteditable], [href], [tabindex]'
                        const allElements = document.querySelectorAll('audio, canvas, iframe, input, button, a, area, object, select, details, summary, textarea, a[href], [accesskey], [contenteditable], [tabindex]');

                        // Find the current tab index.
                        const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el))

                        // focus the following element
                        const targetIndex = (currentIndex + 1) % allElements.length;
                        allElements[targetIndex].focus();  
                 }
           }

            document.addEventListener('keyup',  handleEsc, );
            document.addEventListener('keydown',  handleEnterButton, );
            

            return () => {
                 document.removeEventListener('keyup', handleEsc);
                 document.removeEventListener('keydown', handleEnterButton);
            };

      }, []);


      function OnXButton() {
            setOpen(false); 
            setData( {Mode: '', dataProfile })
      }  
      
      function setFocusToTextBox(id){
            // ref.current.focus();
            document.getElementById(id).focus();  // bad practice for accessing DOM Element directly !!!, but we allow it to do shortcuts accessing the child component's element directly!
      }
      
 
      function OnSaveRecord (evt = null, validatePdfReport, Mode = 'AddNewRecord', Btn = 'Save&New') {
            
            if (Btn === 'Save&New') {
                  // Save&New

                  if (Mode === 'AddNewRecord') { 
                  
                     dispatch ( addNewRecord (refDataEntry.current.getCurrent_Data()) )

                  }

                  if (Mode === 'EditRecord') { 
                   
                     dispatch ( editRecord (refDataEntry.current.getCurrent_Data()) )
                        
                  }

                  
                  setMode ('AddNewRecord')

                  setFocusToTextBox ('LVType');
                

            } else {
                  // Save&Close


                  if (Mode === 'AddNewRecord') { 
                  
                      dispatch ( addNewRecord (refDataEntry.current.getCurrent_Data()) )

                  }

                  if (Mode === 'EditRecord') { 

                      dispatch ( editRecord (refDataEntry.current.getCurrent_Data()) )  

                  }
                  
                  
                  OnXButton();
              
            }      

      }


      // console.log(`Profile ${JSON.stringify(Profile)}`)


      return (
      //      <ThemeProvider theme = {theme} >
                  <Paper>
                        
                        <Dialog PaperProps={{ style: { borderRadius: 10 } }}
                                    open = {Open} 
                                    onCLose = {false} 
                                    fullwidth maxWidth = {'sm'}
                                    
                        >
                        <DialogTitle id='dialog-title' marginLeft='-10px' > {`Type of Leave [ ${data.Mode} ]`} 
                        
                              <IconButton
                                    aria-label="Close"
                                    onClick={() => OnXButton(true) }
                                    sx={{
                                          position: 'absolute',
                                          right: 8,
                                          top: 8,
                                          color: (theme) => theme.palette.grey[500],
                                    }}
                                    >
                                    <CloseIcon />
                              </IconButton>
                                          
                        </ DialogTitle>
                        
                        {/* <DialogContent style={{ height: '100%', overflow: "hidden"  }}  > */}
                        <DialogContent style={{ height: '481px', width: '100%', overflow: "hidden"  }}>
                              <DialogContentText id='dialog-description'> 
                                    {/* Employee Profile [New] */}
                              </DialogContentText>
                              
                              <DataEntry ref={refDataEntry} List={List} Profile={Profile} setProfile={setProfile} setDialogActionsVisibility = {setDialogActionsVisibility} /> 
                              

                        </DialogContent>    

                        {  DialogActionsVisibility? 
                                    // <DialogActions  sx={{marginTop: '-29px', marginBottom: '5px', marginRight: '5px',}} >
                                    //       <Button autoFocus onClick={ () => setOpen(true) } sx={{backgroundColor: 'light', color: 'darkblue', borderColor: 'darkblue', }} variant='outlined'> Save & New </Button>    
                                    //       <Button onClick={ () => OnXButton(true) } sx={{backgroundColor: 'light', color: 'darkblue', borderColor: 'darkblue', }} variant='outlined'> Save & Close </Button>    
                                    //       <Button onClick={ () => OnXButton(true) } sx={{backgroundColor: 'light', color: 'darkblue', borderColor: 'darkblue', }} variant='outlined'> Cancel </Button>    
                                    // </DialogActions>
                                    
                                    <DialogActions sx={{marginTop: '-25px', marginBottom: '5px', marginRight: '25px',}} >
                                    
                                          {/* Save&New */}
                                          <Button autoFocus onClick = { (evt) => OnSaveRecord(evt, validatePdfReport, Mode, 'Save&New')  } sx={{backgroundColor: 'light', color: 'darkblue', borderColor: Mode === 'AddNewRecord'? 'darkblue': 'lightblue',  }} variant='outlined'> Save & New </Button>    
                                          

                                          {/* Save&Close */}
                                          <Button onClick = {  (evt) => OnSaveRecord(evt, validatePdfReport,  Mode,  'Save&Close') }  sx={{backgroundColor: 'light', color: 'darkblue', borderColor: Mode === 'EditRecord'? 'darkblue': 'lightblue',  }} variant='outlined'> Save & Close </Button>    
                                          

                                          {/* Cancel */}
                                          <Button onClick = { () => OnXButton() } sx={{backgroundColor: 'light', color: 'darkblue', borderColor: 'lightblue', }} variant='outlined'> Cancel </Button>    
                                    
                                    </DialogActions>

                            : null        
                        }
                        </Dialog>

                  </Paper>        
            // </ThemeProvider>
      );
};



// ====================================================================================================================================================================================

const DataEntry = forwardRef( ({List, Profile, setProfile, setDialogActionsVisibility}, ref) => {
       
      const [value, setValue] = useState(0);
            
      const handleChange = (evt) => {
            setValue (evt.target.value);
      }
      


      useImperativeHandle(ref, () => ({
            
            initData() {
                  // alert ('initData')
                  // setProfile(data.dataProfile)
                  setProfile(  {...dataProfile})
                  
                  // setAccountability([])

            },

            getCurrent_Data() {
                  return {...Profile};
            },
            
            getCurrent_Id() {
                 // alert (`getCurrent_Id.Profile.EmployeeID; ${Profile.EmployeeID}`)
                 return Profile.LVType;
            },

      
            // getEInformation() {
            // // console.log(`childEPersonalInfo ${childEPersonalInfo.current()}`)
            // return childEInfo.current.getPhotoBlob()
            // }, 

            
      }) ) ;
    

  
      return (
        
           //   <Box sx={{ borderBottom: 1, borderBottomWidth: '4px', borderColor: 'divider',  bgcolor: 'background.paper', width: '460px', height: '320px', }}   >
           <Box sx={{ borderBottom: 0, borderBottomWidth: '0px', borderColor: 'divider', margin: '0px',  bgcolor: 'background.paper', width: '460px', height: '320px', padding: '0px' }}   >
                 
                  <TabContext value = { Number(value) } >
                       {/* borderColor: 'divider' */}
                      <Box sx={{borderBottom: 5, borderColor: 'divider', width: '481px', marginLeft: '-12px', marginTop: '0px', marginBottom: '-22px',  }}>
                           
                           <TabList
                                aria-label='Department'     
                                onClick={ handleChange }
                                indicatorColor="secondary"
                                TabIndicatorProps={{ sx: {backgroundColor: 'orange', height: 4} }}
                                textColor='inherit' 
                                variant='scrollable'
                                
                                sx={{  "& button": {borderTopLeftRadius: 30, borderTopRightRadius: 0, borderBottomRightRadius: 30, backgroundColor: 'darkblue'}, 
                                       "& button:hover": {backgroundColor: 'blue'},
                                       "& button:focus": {backgroundColor: 'darkblue'},
                                       "& button:active": {backgroundColor: 'cyan'},
                                       "& button:Mui-selected": {backgroundColor: 'darkblue'},
                                       
                                       minHeight: "25px", height: "35px", 
                                        
                                       
                                       
                                 }}
                                 
                                // style={{display:'flex',alignItems:'center'}}
                                // style={{ display: "inline-block", marginBottom: "0px" }} 
                                style={{ display: "flex", alignItems:'center',  marginBottom: "0px" }} 

                                // title= 'Tooltiptext'
                            >

                               {/* <Tab label='Employee Information' {...a11yProps(0)}  Value='0' icon={<FavoriteIcon/> } iconPosition='start' /> */}
                               <Tab label='Details' {...a11yProps(0)}  Value='0' style={{color: 'orange', fontSize: '12px', padding: 0, margin: 1, }} onClick={()=> setDialogActionsVisibility(true)}/>
                               <Tab label='Lookup'  {...a11yProps(1)}  Value='1' style={{color: 'orange', fontSize: '12px', padding: 0, margin: 1, }} onClick = { ()=> setDialogActionsVisibility(false) } />
                               
                           </TabList>

                      </Box>

                      {/* DataEntry -> Details */}
                      <TabPanel value={0} >  
                         <Box border={2} color='divider' padding={1} sx={{ marginLeft: '-35px', width: '480px'}}  >
                             <Details Profile={Profile} setProfile={setProfile}/>  
                         </Box>
                      </TabPanel>
                      
                      {/* DataEntry -> Lookup */}
                      <TabPanel value={1}>  
                         <Box border={2} color='divider' padding={1} sx={{ marginLeft: '-35px', width: '480px' }}  >
                             <Lookup List={List} /> 
                         </Box>   
                      </TabPanel>
                    
                  </TabContext>   
              
            </Box>

      )

} );


// ====================================================================================================================================================================================

const Details = ({Profile, setProfile}) => {
    
    const onInputChange = (evt) => {
          setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) )         
    }

 
    return (
    
//      <Form noValidate autoComplete='off' >
    
       <Grid container direction="row" item xs={12} alignItems="flex-start" justify="space-between"  padding-top padding-bottom padding={1} >
    
         <Grid container direction="column" item xs={12} alignItems="flex-start" justify="space-between"  padding-top padding-bottom >

                  <Grid container direction="row" alignItems="flex-start" item xs={6} marginTop={0}>
                        <Grid container direction="column" alignItems="flex-start" item xs={6}>
                            <H1><font color='darkblue'>Data Entry</font></H1> 
                        </Grid>
                  </Grid>
                   
                  
                  <Grid container direction="row" justifyContent ='left' item xs={12}  justify="space-between"  >
                       <Grid container direction="column" alignItems="flex-start" item xs={6}  sx={{ padding: .3 }} >
                           <TextField name='LVType' label='LVType ID' value={Profile.LVType}  onChange ={ onInputChange } inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}  size="medium"  variant="filled" color='secondary'  required  />  
                       </Grid>
                       {/* <Grid container direction="column" alignItems="flex-start" item xs={6}  sx={{ padding: .3 }} >
                           <TextField name='EnrollNo' label='EnrollNo' inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}  size="medium"  variant="filled" color='secondary'  />  
                       </Grid> */}
                  </Grid>


                  <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  >
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                        <TextField name='LVDesc'   label='Name' value={Profile.LVDesc}  onChange ={ onInputChange }  inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth required/>
                      </Grid> 
                      
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                        <TextField name='Description' label='Description'  value={Profile.Description}  onChange ={ onInputChange }  inputProps={{ style: { fontSize: "1rem", height: '46px', padding: '0 70px',}, }}  size="medium"  multiline rows={5} autoComplete="off" variant="filled" fullWidth/>
                      </Grid> 
                      
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                        <TextField name='LVPYear' label='LVPYear'  value={Profile.LVPYear}  onChange ={ onInputChange }  inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}  size="medium"   autoComplete="off" variant="filled" fullWidth/>
                      </Grid>

                      {/* <CheckBox1 name='isMonetizable'  value= { Profile.isMonetizable === 'undefined'? 0: Profile.isMonetizable } label='Is Monetizable' fontColor='#0099ff' onChange={ (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value })  ) } />       */}
                      <CheckBox1 name='isMonetizable'  value= { Profile.isMonetizable === 'undefined'? 0: Profile.isMonetizable } label='Is Monetizable' fontColor='#000000' onChange={ onInputChange } />      
                      
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                        <TextField name='maxCeiling' label='Maximum Ceiling'  value={Profile.maxCeiling}  onChange ={ onInputChange }  inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}  size="medium"  autoComplete="off" variant="filled" fullWidth/>
                      </Grid>
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                          <CheckBox1 name='IsLWOP'  value= { Profile.IsLWOP === 'undefined'? 0: Profile.IsLWOP } label='Is LWOP' fontColor='#000000' onChange={ onInputChange } />      
                      </Grid>
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                          <CheckBox1 name='IsLeaveReplenish'  value= { Profile.IsLeaveReplenish === 'undefined'? 0: Profile.IsLeaveReplenish } label='Is LeaveReplenish' fontColor='#000000' onChange={ onInputChange } />      
                      </Grid>
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                          <CheckBox1 name='IsAutoLeaveGenerations'  value= { Profile.IsAutoLeaveGenerations === 'undefined'? 0: Profile.IsAutoLeaveGenerations } label='Is AutoLeaveGenerations' fontColor='#000000' onChange={ onInputChange } />      
                      </Grid>
                      
                      

                  </Grid>
                  
         </Grid>
      
      </Grid> 

      // </Form> 
   
    )

}



// ====================================================================================================================================================================================
const Lookup = ({List}) => {
    
      const [page, setPage] = React.useState(0);
      const [rowsPerPage, setRowsPerPage] = React.useState(10);
    
      const handleChangePage = (event, newPage) => {
            setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
      };
      
      // { id: 'Description', label: 'Description'  minWidth: 170, align: 'right', format: (value) => value.toLocaleString('en-US'),},
      
      const columns = [
            { id: 'LVType',                     label: 'LVType ID',                    minWidth: 100 },
            { id: 'LVDesc',                     label: 'Name',                         minWidth: 110 },
            { id: 'Description',                label: 'Description',                  minWidth: 170 },
            { id: 'LVPYear',                    label: 'Total Leave Per Year',         minWidth: 170},
            { id: 'isMonetizable',              label: 'Is Monetizable',               minWidth: 170},
            { id: 'maxCeiling',                 label: 'Maximum Ceiling',              minWidth: 170},
            { id: 'IsLWOP',                     label: 'Is LWOP',                      minWidth: 170},
            { id: 'IsLeaveReplenish',           label: 'Is Leave-Replenish',           minWidth: 170},
            { id: 'IsAutoLeaveGenerations',     label: 'Is Auto-Leave-Generations',    minWidth: 170},
      ];

      
      const rows = List ;  // Source: from masterlist page
     

      return (
      
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  
                  <TableContainer sx={{ minHeight: 355}}>
                  
                       <Table stickyHeader aria-label="sticky table">
                              
                              <TableHead>
                                    <TableRow>
                                          {columns.map((column) => (
                                                <TableCell
                                                      key={column.id}
                                                      align={column.align}
                                                      style={{ minWidth: column.minWidth }}
                                                >
                                                      {column.label}
                                                </TableCell>
                                          ))}
                                    </TableRow>
                              </TableHead>
                              
                              <TableBody>
                                    {
                                      rows
                                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                          .map((row) => {
                                                return (
                                                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                            {columns.map((column) => {
                                                                  const value = row[column.id];
                                                                  return (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                              {column.format && typeof value === 'number'
                                                                              ? column.format(value)
                                                                              : value}
                                                                        </TableCell>
                                                                  );
                                                            })}
                                                      </TableRow>
                                                );
                                          })
                                    }
                              </TableBody>

                       </Table>
                  
                  </TableContainer>
                  
                  <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                  />

            </Paper>

      );
  }
     
 

export default TypeofLeave;

//  <TextField  InputLabelProps={{ shrink: true }} label="Lastname" placeholder='Enter Lastname' sx={{ width: '250px', height: '5px',}}/>
