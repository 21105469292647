
// use for user authentication purposes ...

import {createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';

 
export const fetchTypeofLeaves =  createAsyncThunk("typeofLeaves/getAll",  async ({UserRecordLevelRights, setfetchProgressValue}) => {
    
      let response = [];

      const configProgress = {
            onDownloadProgress: progressEvent => {
                                                      const total = parseFloat(progressEvent.event.target.getResponseHeader('Content-Length'));
                                                      const current = progressEvent.event.target.response.length
                                                
                                                      const percentCompleted = Math.round((current / total) * 100)
                                                      // dispatch(doFetchProgress(percentCompleted))
                                                    
                                                      setfetchProgressValue(percentCompleted) 

                                                     // localStorage.setItem("fetchProgress", percentCompleted);
                                                     // console.log(`Completed: percentCompleted: ${percentCompleted}`)
                                                     // console.log('Completed: ', percentCompleted)
                                                    
                                                 }
      
      };                                           


      try {
            response = await Axios.get(`/api/typeofLeaves`, {params: {UserRecordLevelRights }}, configProgress)
                                  .then ((res) => res.data);
            
      } catch (error) {
            console.error(error);
      }

     // alert (`nice thunkc ${Object.keys(response)}`)
     // alert (`JSON.stringify(data) ${JSON.stringify(response)}`)

      return response;

 });

 export const getNewID = createAsyncThunk("typeofLeaves/getNewID",  async ({AreaCode}) => {
                                          
      // const areacode = action.payload.areacode;
      const zeroPad = (num, places) => String(num).padStart(places, '0');

       const res =  await Axios.get(`/api/typeofLeaves/newid`, {params: {AreaCode} })
                         .then ((res) =>  {
                             // console.log(`res.data ${ JSON.stringify(res.data[0])}`)
                              console.log ('Record has successfuly been updated!!!')
                              return res.data[0]
             
                         })
                         .catch((err) => {
                              console.log (err);
                         })
                   
              // console.log (`getNewID.res  : ${ JSON.stringify(res)}`)
              const newID = `${AreaCode}-${zeroPad (res.NewID, 4)}`
              console.log (`getNewID.res, zeroPad  : ${newID}`)

             // state = {...state,  fetchProfileStatus: 'Idle', Profile: {...data, EmployeeID: newID } };
             
       return newID; // state;  
});

export const fetchProfile =  createAsyncThunk("typeofLeaves/fetchprofile",  async (Id) => {
      
      // alert (`JSON.stringify(data) ${JSON.stringify()}`)
     // alert ('jeff now!')
   
      let response = {};
   
       try {
             
            // response = await Axios.get('/api/employees/data-entry/data', { params: { id: Id} }).then ( (result) => result.data[0]);

             response = await Axios.get('/api/typeofLeaves/data-entry/data', { params: { id: Id} } );
             response = response.data[0];                       

       
       } catch (err) {
            
            console.log(`fetchProfile thunk: error: ${err}`);
            // console.log(`err.response.data : ${err.response.data}`);
            // console.log(`err.response.status : ${err.response.status}`);
            // console.log(`err.response.headers : ${err.response.headers}`);

       } 


   
     // alert (`fetchProfile thunk ${ JSON.stringify(response)}`)
      
      // console.log (`fetchProfile: JSON.stringify(response)`) 
      // console.log (JSON.stringify(response))

      
      return response;
   
   });


export const data = {
                        LVType:                               '', 
                        LVDesc:                               '', 
                        Description:                          '', 
                        LVPYear:                              5, 
                        isMonetizable:                        0, 
                        maxCeiling:                           0, 
                        IsLWOP:                               0, 
                        IsLeaveReplenish:                     0, 
                        IsAutoLeaveGenerations:               0
};

const initialState = {
                        List:                                [],
                        Profile:                             {...data},

                        isValidate:                          false,              //  Validate for saving records.
                        fetchProfileStatus:                  'Idle',             // 'Init || Idle' || 'Loading' || 'Succeeded' || 'Failed' ===> fetch records !!!
                        fetchStatus:                         'Idle',             // 'Idle' || 'Loading' || 'Succeeded' || 'Failed' ===> fetch records !!!
                        fetchProgress:                       0,
} ;    


const typeofLeavesSlice = createSlice (
   {
      name: 'typeofLeaves',
      initialState,
      reducers:  {
            
                     setDepartment:    (state, action) => {
                                             
                                             // state = {...state, Username: action.payload.Username, Password: action.payload.Password,  isValidate: getValidate(action.payload)}; 
                                             // state = {...state, isValidate: action.payload.isValidate}

                                             console.log(`action.payload ${Object.keys(action.payload)}`)

                                            //  console.log(`action.payload ${Object.values(action.payload)}`)
                                             
                                             // state = {...state, ...action.payload};
                                             state = {...state, ...action.payload};
                                             console.log(`state ${state.LVType}`)
                                             return state;
                                    },

                        setCurrentID:    (state, action) => {
                              state.Profile = {...state.Profile, LVType: action.payload.LVType};
                              console.log(`state currentID ${state.Profile.LVType}`)
                              return state;
                        },
                        
                        doFetch: (state) => {
                              
                              state = {...state, fetchStatus: 'Idle' };
                              return state;

                        },

                        doFetchProgress: (state, {percentCompleted}) => {
                              
                              state = {...state, fetchProgress: percentCompleted };
                              return state;

                        },

                        
                        doProfileFetch: (state, action) => {
                              console.log (`doProfileFetch - payload ${action.payload}`)

                              // state = {...state, fetchProfileStatus: 'Idle' };
                              state = {...state, Profile: action.payload === 'Init'? {...data} : {...state.Profile},  fetchProfileStatus: action.payload };
                              
                              return state;

                        },

                        
                        addNewRecord:     (state, {payload})  => {
                              
                                                // state = {...state, fetchStatus: 'Idle', Profile: payload  };
                                                state = {...state, fetchStatus: 'Idle', fetchProfileStatus: 'Init', Profile: payload  };
                                                console.log (`addnewrecord-state: ${ JSON.stringify(state.Profile)}`)
                                                // console.log (`addnewrecord-state ...`)
                                                
                                                Axios.post(`/api/typeofLeaves/:mode='AddNewRecord'/:id='${state.Profile.LVType}'`, state.Profile)
                                                .then ((res) =>  {
                                                      // console.log(`res.data ${ JSON.stringify(res.data[0])}`)
                                                      console.log ('Record has successfuly been updated!!!')
                                                
                                                      })
                                                .catch((err) => {
                                                      console.log (err);
                                                      })
                                                      
                                                
                                                return state;  


                                          } ,

                        editRecord:     (state, {payload})  => {
                              
                                                state = {...state,  fetchStatus: 'Idle',  Profile: payload  };
                                                console.log (`editrecord-state: ${ JSON.stringify(state.Profile)}`)

                                                // alert(`dispatch app.post ('/api/employees/:mode/:id/:data', (req, res) => {`)
                                                // Axios.post(`/api/employees/:mode='EditRecord'/:id='${state.Profile.EmployeeID}'/:data=${state.Profile}`)
                                                Axios.post(`/api/typeofLeaves/:mode='EditRecord'/:id='${state.Profile.LVType}'`, state.Profile)
                                                .then ((res) =>  {
                                                      // console.log(`res.data ${ JSON.stringify(res.data[0])}`)
                                                      console.log ('Record has successfuly been updated!!!')
                                                
                                                      })
                                                .catch((err) => {
                                                      console.log (err);
                                                      })
                                                      
                                                
                                                return state;   


                                          },
                        
                        deleteRecord:     (state, {payload})  => {
                              // *** TEST ***
                                                // console.log(`payload ${ JSON.stringify(payload)}`)
                                                
                                                console.log(`deleteRecord {payload} ${payload}`)
                                                
                                                state = {...state,  fetchStatus: 'Idle' };

                                                const selected =  payload;
                                                const s =  selected.map ( (item, idx) => {
                                                      return console.log(`selected: ${idx +1} : ${item}`)      
                                                } )
                                                
                                                // reserved code: axios.delete("/<your endpoint>", { data:<"payload object">})
                                                
                                                Axios.delete(`/api/typeofLeaves/delete`, {headers: {type: 'Array'}, data: payload} )
                                                .then ((res) =>  {
                                                
                                                console.log ('Record has successfuly been deleted!!!')
                                                
                                                })
                                                .catch((err) => {
                                                      console.log (err);
                                                })
                                                
                                          
                                                return state;   

                                          } 
                        
                       
      },

      
      extraReducers: (builder) => {
               
               builder
               
                 .addCase(fetchTypeofLeaves.pending, (state, action) => {
                       state = {...state, fetchStatus: 'Idle',  List: [] };    
                       return state;       
                 })
                 .addCase(fetchTypeofLeaves.fulfilled, (state, action) => {
                       // console.log(`fetchTypeofLeaves's Promise has been fulfilled !!!`)  
                       state = {...state, fetchStatus: 'Succeeded',  List: action.payload };
                       return state;       
                 })
                 .addCase(fetchTypeofLeaves.rejected, (state, action) => {
                       state = {...state, fetchStatus: 'Rejected',  List: [] };    
                       return state;       
                 })

                 // ===================================================================================================================
                 .addCase(fetchProfile.pending, (state, action) => {
                        state = {...state, fetchProfileStatus: 'Idle',  Profile: {...data} };    
                        return state;       
                  })
                 .addCase(fetchProfile.fulfilled, (state, action) => {
                        // console.log('Promise has been fulfilled !!!')  
                        // console.log(`fetchProfile/action.payload ${JSON.stringify(action.payload)}`)
                        state = {...state, fetchProfileStatus: 'Succeeded', Profile: action.payload !== 'undefined'? action.payload: {...data}  };
                        
                        // alert(`state.Profile ${ JSON.stringify(state.Profile)}`)
                        return state;       
                  })
                 .addCase(fetchProfile.rejected, (state, action) => {
                        state = {...state, fetchProfileStatus: 'Rejected',  Profile: {...data} };    
                        return state;       
                  })
            

      }      
            
   }
)


export const  allTypeofLeaves = (state) => state.typeofLeaves.List;
export const  typeofLeaveData = (state) => state.typeofLeaves.Profile;
export const  fetchStatus = (state) => state.typeofLeaves.fetchStatus;
export const { setTypeofLeave, setCurrentID, addNewRecord, editRecord, deleteRecord, doFetch, doFetchProgress, doProfileFetch } = typeofLeavesSlice.actions;

export default typeofLeavesSlice.reducer;
