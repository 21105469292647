// component
import SvgColor from '../../../components/svg-color';


// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Hr',
    path: '/dashboard/hr',
    icon: icon('ic_user'),
  },

  {
    title: 'Tms',
    path: '/dashboard/tms',
    icon: icon('ic_user'),
  },
  {
    title: 'Pms',
    path: '/dashboard/pms',
    icon: icon('ic_user'),
  },
  
  {
    title: 'Management',
    path: '/dashboard/mgmt',
    icon: icon('ic_user'),
  },

  {
    title: 'products',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'messages(10)',
    path: '/dashboard/messages',
    icon: icon('ic_user'),
  },
  {
    title: 'reminders(3)',
    path: '/dashboard/reminders',
    icon: icon('ic_user'),
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
  },
  {
    title: 'Administrator',
    path: '/dashboard/admin',
    icon: icon('ic_user'),
  },
  {
    title: 'Reports / Analytics',
    path: '/dashboard/analytics',
    icon: icon('ic_user'),
  },
  {
    title: 'logoff',
    path: '/LogOff',
    icon: icon('ic_lock'),
  },
  
];

export default navConfig;
