import React from 'react';
import { FormControl, FormLabel, FormControlLabel, InputLabel, Select as MUISelect, MenuItem } from '@mui/material';


export default function Select (props) {
   
      const {name, label, value, onChange, options, variant, style} = props;
   
      //  notched={true} // Makes space between lines

      if( style.label === 'basic') { 

            return (      <FormControl name = { `frm${name}` } variant='outlined'>
                        
                                    <InputLabel name = { `lbl${name}` } > {label} </InputLabel>
                                    
                                    
                                    <MUISelect
                                          name     = {name}
                                          label    = {label}
                                          value    = {(typeof(value) !== "undefined" && value !== null)? value: '' }      // {Boolean(value) ? value: "" }   // {(typeof(value) !== 'undefined' && value != null)? value: '' }   
                                          onChange = {onChange} 
                                          style    = { { width: 246, height: 31, fontSize: "1rem", padding: 0 } }
                                          variant  = { variant !== ""? variant: "filled"}
                                          
                                    >   
                                    <option disabled value="">
                                          {`Select ${label}`}
                                    </option>
                                          
                                          { 
                                          // alert(`options.length ${options.length}`)
                                          } 
                                          {    // options.map ((option) => <MenuItem key={option.id} value={option.id}> {option.name} </MenuItem> )   
                                          options.length > 0 && options.map ((option) => 
                                                <MenuItem key={option.id} value={option.id}> {option.name} </MenuItem> 
                                                )   
                                          } 

                                    </MUISelect>

                              </FormControl> 
                  ) 
                  
     }  
      
      
      // Customized  
      return (    
                 <FormControl name = { `frm${name}` } variant='outlined'>
                        
                        <div>
                              <div>
                                  <span style={{"font-size": "110%"}}><b>{`${label}`}</b> </span> 
                                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                  {/* <span style={{background: "#ff0000"}}><big>{`${label}`}</big> </span>  */}
                              </div>
                              <div>
                                    <MUISelect
                                          name     = {name}
                                          label    = {label}
                                          value    = {(typeof(value) !== "undefined" && value !== null)? value: '' }      // {Boolean(value) ? value: "" }   // {(typeof(value) !== 'undefined' && value != null)? value: '' }   
                                          onChange = {onChange} 
                                          style    = {style.val}
                                          // variant  = { variant !== ""? variant: "filled"}
                                          variant  = { variant !== ""? variant: "outlined"}
                                          
                                    >   
                                          <option disabled value="">
                                                { `Select ${label}`}
                                          </option> 

                                          { 
                                            // label !=='' && `<option disabled value="" Select ${label}  </option>`
                                          }
                                          
                                          { 
                                          // alert(`options.length ${options.length}`)
                                          } 
                                          {    // options.map ((option) => <MenuItem key={option.id} value={option.id}> {option.name} </MenuItem> )   
                                          options.length > 0 && options.map ((option) => 
                                                <MenuItem key={option.id} value={option.id}> {option.name.toUpperCase()} </MenuItem> 
                                                )   
                                          } 

                                    </MUISelect>
                              </div>
                        </div>



                  </FormControl>
            )
          

       
      
} 