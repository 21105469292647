
// use for user authentication purposes ...
import {createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';


export const fetchUserPrivileges =  createAsyncThunk("userAuthentication/fetchUserPrivileges",  async ({GrpCode, setfetchProgressValue}) => {
      
     
      let response = [];

      const configProgress = {
            onDownloadProgress: progressEvent => {
                                                      const total = parseFloat(progressEvent.event.target.getResponseHeader('Content-Length'));
                                                      const current = progressEvent.event.target.response.length
                                                
                                                      const percentCompleted = Math.round((current / total) * 100)
                                                      
                                                      setfetchProgressValue(percentCompleted) 

                                                    
                                                 }
      };

      try {
            response = await Axios.get(`/api/userAuthentication/privileges/usertype`, { params: { GrpCode} },  configProgress)
                                  .then ((res) => res.data);
            
      } catch (err) {
            console.error(`fetchUserPrivileges thunk: error: ${err}`);
      }
      
            // alert (`fetchEmployees thunk ${Object.keys(response)}`)
            
            console.log (`fetchUserPrivileges thunk: response ${ JSON.stringify(response)}`)
            return response;
  
  });



export const fetchUserRecordLevelRights =  createAsyncThunk("userAuthentication/fetchUserRecordLevelRights",  async ({UsrCode, setfetchProgressValue}) => {
  
      
      let response = [];

      const configProgress = {
            onDownloadProgress: progressEvent => {
                                                      const total = parseFloat(progressEvent.event.target.getResponseHeader('Content-Length'));
                                                      const current = progressEvent.event.target.response.length
                                                
                                                      const percentCompleted = Math.round((current / total) * 100)
                                                      
                                                      setfetchProgressValue(percentCompleted) 

                                                    
                                                 }
      };
      
      // alert (`UsrCode ${UsrCode}`)
  
      try {
            response = await Axios.get(`/api/userAuthentication/userrecordlevelrights/usercode`, { params: { UsrCode} },  configProgress)
                                  .then ((res) => res.data);
            
      } catch (err) {
            console.error(`fetchUserRecordLevelRights thunk: error: ${err}`);
      }
      
            // alert (`fetchEmployees thunk ${Object.keys(response)}`)
            return response;
  
  });



export const authenticateUser =  createAsyncThunk("userAuthentication/validate",  async ( {Username, Password, isAdminAccess, isValidate} ) => {
      
      // alert (`JSON.stringify(data) ${JSON.stringify()}`)
      // alert (`isAdminAccess ${isAdminAccess}`)

      
      let response = {};
   
      try {
             
             // response = await Axios.get('/api/employees/data-entry/data', { params: { id: Id} }).then ( (result) => result.data[0]);
             // response = await Axios.get('/api/userAuthentication/validate', { params: { Username, Password, Group, isValidate } });
             
             response =  await Axios.get('/api/userAuthentication/validate', { params: { Username, Password, isAdminAccess, isValidate } });
             // response = response.length > 0 ? response.data[0]  :   {isValidate: false};                       
             response =  response.data;  


             // response = response ? response.data[0]  :   {isValidate: false};                       
             // response =   response.data[0];                   
             
             // console.log (`authenticateUser: JSON.stringify(response[0])`) 
             // console.log (JSON.stringify(response))
       
       } catch (err) {
            
             console.log(`fetchProfile thunk: error: ${err}`);
             // console.log(`err.response.data : ${err.response.data}`);
             // console.log(`err.response.status : ${err.response.status}`);
             // console.log(`err.response.headers : ${err.response.headers}`);
             // response = {isValidate: false};     
       } 

      
      

      
      return response;
   
   });

// const getValidate =  async (data) =>  {
     
//      const response = await Axios.post(`/api/users`, data);
   
//      alert (`response.data.isValidate : ${response.data.isValidate } `)
//      return response.data.isValidate      
// }
   

// .then ( res => (
//    // console.log (`getValidate: res.data.isValidate() ${ JSON.stringify(res.data)}`)
//     true //  res.data.isValidate
// )).catch (err => ( 
// //  alert(`login error > ${err}`)
//   false
// ))



   // let result = false;
   
   // try {
   //      const res = Axios.post(`/api/users`, data);
        
   //       if (res)  {
   //            console.log (`getValidate: res.data.isValidate() ${res.data.isValidate}`)
   //            result =  res.data.isValidate;
   //        }
   //        alert(`promise aaa: ${ result }`);
    
   // } catch (err) { 
   //       alert(`login error > ${err}`)
   //       result = false;
   // }

  // alert (`JSON.stringify(promise) ${JSON.stringify(result)}`)

export const getValidate =  createAsyncThunk("users/getValidate",  async (data) => {
      
     alert (JSON.stringify(data))

     let response = {};

      try {
            response = await Axios.post(`/api/users`, data)
                                  .then ((res) => res.data);
            // return response.data.isValidate;
      } catch (error) {
            console.error(error);
      }

      alert (`nice thunkc`)
      return response.data;

});



const initialState = {
      UsrCode:                '',
      Username:               '',
      Password:               '',
      isValidate:             false,
      
      fetchProfileStatus:     'Idle',     // 'Init || Idle' || 'Loading' || 'Succeeded' || 'Failed' ===> fetch records !!!
      fetchStatus:            'Idle',     // 'Idle' || 'Loading' || 'Succeeded' || 'Failed' ===> fetch records !!!
      fetchProgress:           0,
      
      GrpCode:                 '',        // Admin (Super User), Coordinator
      Privileges:              [],        // Source: tblprivileges
      UserRecordLevelRights:   [],        // Source: tbluserrecordlevel_rights
}


const userAuthentication = createSlice (
      {
            name: 'userAuthentication',
            initialState,
            reducers:  {
                  
                        validateUser:    (state, action) => {
                                                
                                                // state = {...state, Username: action.payload.Username, Password: action.payload.Password,  isValidate: getValidate(action.payload)}; 
                                                state = {...state, isValidate: action.payload.isValidate}
                                                return state;
                                          },
            },
      
            extraReducers: (builder) => {
                 
                        builder
                        
                              .addCase(fetchUserPrivileges.pending, (state, action) => {
                                         state = {...state, fetchStatus: 'Loading',  Privileges: [] };    
                                         return state;       
                                      })
                              .addCase(fetchUserPrivileges.fulfilled, (state, action) => {
                                         console.log('fetchUserPrivileges.fulfilled: Promise has been fulfilled !!!')  
                                         state = {...state, fetchStatus: 'Succeeded', Privileges: action.payload };
                                         return state;       
                                      })
                              .addCase(fetchUserPrivileges.rejected, (state, action) => {
                                         state = {...state, fetchStatus: 'Rejected', Privileges: [] };    
                                         return state;       
                                      })
                              

                        
                              .addCase(fetchUserRecordLevelRights.pending, (state, action) => {
                                         state = {...state, fetchStatus: 'Idle',  UserRecordLevelRights: [] };    
                                         return state;       
                                    })
                              .addCase(fetchUserRecordLevelRights.fulfilled, (state, action) => {
                                         console.log('Promise has been fulfilled !!!')  
                                         console.log(`fetchUserRecordLevelRights/action.payload ${JSON.stringify(action.payload)}`)
                                         state = {...state, fetchStatus: 'Succeeded', UserRecordLevelRights: action.payload };
                                    
                                         // alert(`state.Profile ${ JSON.stringify(state.Profile)}`)
                                         return state;       
                                    })
                              .addCase(fetchUserRecordLevelRights.rejected, (state, action) => {
                                         state = {...state, fetchStatus: 'Rejected',  UserRecordLevelRights: [] };    
                                         return state;       
                                    })




                              .addCase(authenticateUser.pending, (state, action) => {
                                          state = {...state, fetchProfileStatus: 'Idle', Username: '', GrpCode: '', isRecordLevelRightsAccess: 0, isValidate: false}; 
                                          return state;       
                                    })
                              .addCase(authenticateUser.fulfilled, (state, action) => {
                                          console.log('Promise has been fulfilled !!!')  
                                          console.log(`fetchProfile/action 101 ${JSON.stringify(action)}`)

                                          state = {...state, fetchProfileStatus: 'Succeeded', UsrCode: action.payload.UsrCode, Username: action.payload.Username, GrpCode: action.payload.GrpCode, isRecordLevelRightsAccess: action.payload.isRecordLevelRightsAccess, isValidate: action.payload.isValidate }; 
                                          return state;       
                                    })
                              .addCase(authenticateUser.rejected, (state, payload) => {
                                          state = {...state, fetchProfileStatus: 'Rejected', Username:'', Password: '', GrpCode: '', isRecordLevelRightsAccess: 0, isValidate: false};    
                                          return state;       
                                    })      
                  


                                    
                              .addCase(getValidate.pending, (state, action) => {
                                    //    state.Username = action.payload.Username;
                                    //    state.Password = action.payload.Password;
                                    //    state.isValidate = 'idle';
                                    // state = {...state,   isValidate: 'idle' };    
                                     })
                              .addCase(getValidate.fulfilled, (state, action) => {
                                    // state.Username = action.payload.Username;
                                    // state.Password = action.payload.Password;
                                    // state.isValidate = true;
                                    // state = {...state,  isValidate: true};    
                                      })
                              .addCase(getValidate.rejected, (state, action) => {
                                    // state.Username = action.payload.Username;
                                    // state.Password = action.payload.Password;
                                    // state.isValidate = false;
                                    //  state = {...state,  isValidate: false }; 
                                      })


            } // extraReducers: (builder) => {      
      }

)





export const  userAuthData = (state) => state.userAuthentication;
export const { validateUser } = userAuthentication.actions;
export default userAuthentication.reducer;
