
import {Routes, Route, useLocation} from 'react-router-dom'
import {Timecard} from './PmsPages';

// import Navbar from './HrPages/HrMenu';
import BurgerMenu from './PmsPages/Components/PmsHamburgerMenu';

import TypeofAllowance from './PmsPages/TypeofAllowance';
import TypeofIncentive from './PmsPages/TypeofIncentive';
import TypeofDeduction from './PmsPages/TypeofDeduction';
import TypeofLoan from './PmsPages/TypeofLoan';

import SSSTable from './PmsPages/SSS-Table';
import PhilHealthTable from './PmsPages/PhilHealth-Table';
import PagIBIGTable from './PmsPages/Pag-IBIG-Table';
import WTaxTable from './PmsPages/WTax-Table';

import Allowances from './PmsPages/Allowances';
import Incentives from './PmsPages/Incentives';
import OtherAdditions from './PmsPages/OtherAdditions';

import SSSLoan from './PmsPages/SSSLoan';
import PagIBIGLoan from './PmsPages/Pag-IBIGLoan';
import OtherLoans from './PmsPages/OtherLoans';

import Deductions from './PmsPages/Deductions';
import OtherDeductions from './PmsPages/OtherDeductions';


const GetLastPartoftheUrl = () => ( useLocation().pathname.split('/').at(-1) )

const PmsNavigationMenu = ( ) => (
    
    

     <div style={{ width: '90%', position: 'fixed', border: 0, }} >
          
          
          <BurgerMenu />
          
          <br/>
          <br/>
          
          {useLocation().pathname === '/dashboard/pms'? <Timecard /> : null}
          {useLocation().pathname === '/dashboard/pms/type-of-allowance'? <TypeofAllowance /> : null}
          {useLocation().pathname === '/dashboard/pms/type-of-incentive'? <TypeofIncentive /> : null}
          {useLocation().pathname === '/dashboard/pms/type-of-deduction'? <TypeofDeduction /> : null}
          {useLocation().pathname === '/dashboard/pms/type-of-loan'? <TypeofLoan /> : null}

          {useLocation().pathname === '/dashboard/pms/sss-table'? <SSSTable /> : null}
          {useLocation().pathname === '/dashboard/pms/philhealth-table'? <PhilHealthTable /> : null}
          {useLocation().pathname === '/dashboard/pms/pag-ibig-table'? <PagIBIGTable /> : null}
          {useLocation().pathname === '/dashboard/pms/wtax-table/daily'? <WTaxTable type={'daily'} /> : null}
          {useLocation().pathname === '/dashboard/pms/wtax-table/weekly'? <WTaxTable type={'weekly'} /> : null}
          {useLocation().pathname === '/dashboard/pms/wtax-table/semi-monthly'? <WTaxTable type={'semi-monthly'} /> : null}
          {useLocation().pathname === '/dashboard/pms/wtax-table/monthly'? <WTaxTable type={'monthly'} /> : null}

          {useLocation().pathname === '/dashboard/pms/employee-additions/allowances'? <Allowances /> : null}
          {useLocation().pathname === '/dashboard/pms/employee-additions/incentives'? <Incentives /> : null}
          {useLocation().pathname === '/dashboard/pms/employee-additions/other-additions'? <OtherAdditions /> : null}
          
          {useLocation().pathname === '/dashboard/pms/employee-deductions/loans/sss-loan'? <SSSLoan /> : null}
          {useLocation().pathname === '/dashboard/pms/employee-deductions/loans/pag-ibig-loan'? <PagIBIGLoan /> : null}
          {/* {useLocation().pathname === '/dashboard/pms/employee-deductions/loans/other-loans/all'? <OtherLoans /> : null} */}

          {/* {useLocation().pathname === '/dashboard/pms/employee-deductions/loans/other-loans/all'? <OtherLoans type="" /> : null} */}

          {/* {alert(`/dashboard/pms/employee-deductions/loans/other-loans/${GetLastPartoftheUrl()}`)}  */}
          { 
            // useLocation().pathname === `/dashboard/pms/employee-deductions/loans/other-loans/${GetLastPartoftheUrl()}`? <OtherLoans /> : null 
            // useLocation().pathname === `/dashboard/pms/employee-deductions/loans/other-loans/ofcl`? <OtherLoans /> : null 
          }
          
          {useLocation().pathname === `/dashboard/pms/employee-deductions/loans/other-loans/${GetLastPartoftheUrl()}` ? <OtherLoans type={GetLastPartoftheUrl()} /> : null}
          
          
          {useLocation().pathname === '/dashboard/pms/employee-deductions/deductions'? <Deductions /> : null}
          {useLocation().pathname === '/dashboard/pms/employee-deductions/other-deductions'? <OtherDeductions /> : null}

          
          
          {/* <Routes>
                 <Route path='/dashbord/pms' element={Timecard}/>
                 
          </Routes>  */}
          
     </div>

  )




export default PmsNavigationMenu;