
import React, {useState, useEffect, useRef} from 'react';
import  {Router,Routes, Route, Link as rLink,   useRoutes, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';

import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';

    
import { Link, AppBar, Toolbar, Container, Avatar, Hidden, IconButton, Paper, Drawer, Divider, List, ListItem } from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";

import { styled } from '@mui/material/styles'; 
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';  // https://mui.com/material-ui/material-icons/?query=arrow+down


import Menu from '@mui/material/Menu';
import nb from 'date-fns/locale/nb';




function BasicMenu({key, title, index}) {
  

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const [flag, setFlag] = useState(true);
  const navigate = useNavigate();

  // const itemEls = useRef( Array(10))  
  
   const itemEls = useRef( new Array (13) )  
  // const itemEls = useRef(new [].constructor(13))  
  // const itemEls2 = itemEls.current;
  
  

//   useEffect ( () =>  (itemEls.current.fill())
//    , []
// )



  const handleOnClick= (event, key) => {
    
    event.preventDefault();

    
    // console.log(itemEls.current)
    // itemEls.current.map ( (element, idx) => 
    //        console.log(`this is: ${idx} ,  this is: ${element}`)
            
    //        // btn.color={flag ? "primary" : "secondary"} 
    //   )
  
    
      // setFlag(!flag)
    
      switch (title) {
        
        case 'Setup':
             setAnchorEl(event.currentTarget)
             break;
          
        case 'Employees':
             setAnchorEl(event.currentTarget)
             setFlag(!flag);
             break;
        
        case 'Holidays':
              navigate ("/dashboard/hr/holidays")
              break;

        case 'Event Declaration':
              navigate ("/dashboard/hr/events")
              break;
        
        case 'Transfers':
             setAnchorEl(event.currentTarget)
             break;

        case 'Leaves':
             setAnchorEl(event.currentTarget)
             break;

        case 'Travel / O.B':
             navigate ("/dashboard/hr/itinerary")
             break;
        
        case 'Warnings / Suspensions':
              navigate ("/dashboard/hr/warnings-and-suspensions")
              break;

        case 'Performance Eval.':
              setAnchorEl(event.currentTarget)
             break;

        default:
          setAnchorEl(null)
      }

      // display grid now for the record, or use another <Listing />

   };
  
 
  const handleMenuClick = (Props) => {
    
    
    const itemName = Props.item;
    const itemStatus = Props.status;
    


    return () => {  
        
       // alert(`this is a prop (item): ${Props.item},   item Status: ${itemStatus} ` )
        
       
        switch (itemName) { // switch (Props.item) {
            
            case 'Setup':
              
                  if (itemStatus === 'Branches')               {  navigate ("/dashboard/hr/branches")  };
                  if (itemStatus === 'Departments')            {  navigate ("/dashboard/hr/departments")  };
                  if (itemStatus === 'Sections')               {  navigate ("/dashboard/hr/sections")  };
                  if (itemStatus === 'Statuses')               {  navigate ("/dashboard/hr/statuses")  };
                  if (itemStatus === 'Positions')              {  navigate ("/dashboard/hr/positions")  };
                  if (itemStatus === 'Type of Warnings')       {  navigate ("/dashboard/hr/typeofwarnings")  };
                  if (itemStatus === 'Type of Leaves')         {  navigate ("/dashboard/hr/typeofleaves")  };
               
                  handleClose();
                  break; 


            case 'Employees':
                  // if (itemStatus === 'Active')     {  window.location = '/dashboard/hr/employees/active'};    
                  
                  if (itemStatus === 'Active')     {  navigate ("/dashboard/hr/employees/active")  };
                  if (itemStatus === 'Inactive')   {  navigate ("/dashboard/hr/employees/inactive")  };
                  if (itemStatus === 'All')        {  navigate ("/dashboard/hr/employees/all")  };
                  
                  handleClose();
                  break; 

            case 'Leaves':
                    
                  if (itemStatus === 'Type of Leaves')            {  navigate ("/dashboard/hr/typeofleaves")  };
                  if (itemStatus === 'Leave Application')         {  navigate ("/dashboard/hr/leaves")  };
                  if (itemStatus === 'Leave Credit & Balances')   {  navigate ("/dashboard/hr/leave-credit-and-balances")  };
                  
                  // if (itemStatus === 'Type of Leaves')         {  navigate ("/dashboard/hr/leave-credit-&-Balances")  };
                  // if (itemStatus === 'Type of Leaves')         {  navigate ("/dashboard/hr/leave-credit-Generations")  };
                  // if (itemStatus === 'Type of Leaves')         {  navigate ("/dashboard/hr/leave-monitoring")  };
                    
                  handleClose();
                  break;       
            

            case 'Performance Eval.':
                 
                  if (itemStatus === 'Recommendations'){alert('Recommendations')};
                  if (itemStatus === 'Promotions'){alert('Promotions')};
                  
                  handleClose();
                  break;

            
            default:
                  alert('No Selection!');

        }

         return null;    
    } 
     
  };


  const handleClose = () => {
         setAnchorEl(null);
  };



   return (
 
    <div key={`dvPrt${index}`}>

       <Button
            key={`btn${index}`}
            id="basic-button"
            variant='contained'
            //  aria-owns={open ? 'basic-menu' : undefined}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disabled = { title === 'Awards / Citations' || title === 'Memos' || title === 'Requisitions' || title === 'Complaints' || title === 'Violations' || title === 'Performance Eval.' ? true: null }
            
            endIcon={ title === 'Setup' || title === 'Employees' || title === 'Transfers' || title === 'Leaves' || title === 'Performance Eval.' ?   <ExpandMoreIcon /> 
                      : null } 
            
            onClick={(event) => handleOnClick (event, `btn${index}`) }
            
            // ref={ (element) => itemEls.current.push(element) }
            // color={flag ? "primary" : "secondary"}   
            
          //   style={{
          //     borderRadius: 35,
          //     backgroundColor: "#21b6ae",
          //     padding: "18px 36px",
          //     fontSize: "18px"
          // }}
       >
         
          {title} 
         
       </Button>
       
       <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              
            }}
         
       >
        
            {
              title === 'Setup'? 
                <div key={`dvChd${index}`}>
                  <MenuItem onClick = { handleMenuClick( {item: 'Setup', status: 'Branches'}) }> Branch </MenuItem>
                  <MenuItem onClick = { handleMenuClick( {item: 'Setup', status: 'Departments'}) }> Department </MenuItem>
                  <MenuItem divider = { Divider} sx={{ ...(Divider && { margin: 0 }) }}  onClick={ handleMenuClick( {item: 'Setup', status: 'Sections'}) }> Section </MenuItem>
                  
                  <MenuItem onClick = { handleMenuClick( {item: 'Setup', status: 'Statuses'}) }> Working Status </MenuItem>
                  <MenuItem onClick = { handleMenuClick( {item: 'Setup', status: 'Positions'}) }> Positions </MenuItem>
                  <MenuItem divider = { Divider} sx={{ ...(Divider && { margin: 0 }) }}  onClick={ handleMenuClick( {item: 'Setup', status: 'Type of Warnings'})  }> Type of Warning and Suspension </MenuItem>
                  
                  <MenuItem onClick = { handleMenuClick( {item: 'Setup', status: 'Type of Leaves'}) }> Leave Type </MenuItem>

                </div>
                : null
            }
            
            {
              title === 'Employees'? 
                <div key={`dvChd${index}`}>
                  <MenuItem onClick={ handleMenuClick( {item: 'Employees', status: 'Active'}) }> Active </MenuItem>
                  <MenuItem divider={Divider} sx={{ ...(Divider && { margin: 0 }) }}   onClick={ handleMenuClick( {item: 'Employees', status: 'Inactive'}) }> Inactive </MenuItem>
                  <MenuItem onClick={ handleMenuClick( {item: 'Employees', status: 'All'}) }> All Employees </MenuItem>
                  <Divider component="MenuItem" sx={{borderBottomWidth: 4}}/>
                </div>
                : null
            }
           
            {
              title === 'Transfers'? 
                <div key={`dvChd${index}`}>
                  <MenuItem onClick={handleClose}> Department </MenuItem>
                  <MenuItem onClick={handleClose}> Branch </MenuItem>
                  <MenuItem onClick={handleClose}> Company </MenuItem>
                  <Divider component="MenuItem" sx={{borderBottomWidth: 4}}/>
                </div>
                : null
            } 
            
            {
                title === 'Leaves'? 
                <div key={`dvChd${index}`}>
                  <MenuItem onClick={ handleMenuClick( {item: 'Leaves', status: 'Type of Leaves'}) }> Leave Type </MenuItem>
                  <MenuItem onClick={ handleMenuClick( {item: 'Leaves', status: 'Leave Application'}) }> Leave Application </MenuItem>
                  <MenuItem onClick={ handleMenuClick( {item: 'Leaves', status: 'Leave Credit & Balances'}) }> Leave Credit & Balances </MenuItem>
                  <MenuItem onClick={ handleMenuClick( {item: 'Leaves', status: 'Leave Credit Generations'}) }> Leave Credit Generations </MenuItem>
                  <MenuItem onClick={ handleMenuClick( {item: 'Leaves', status: 'Leave Monitoring'}) }> Leave Monitoring </MenuItem>
                </div>
                : null
            }

            {/* {
                title === 'Travel / O.B'? 
                <div key={`dvChd${index}`}>
                  <MenuItem onClick={handleClose}>Local</MenuItem>
                  <MenuItem onClick={handleClose}>Abroad</MenuItem>
                </div>
                : null
            } */}

            {
                title === 'Performance Eval.'? 
                <div key={`dvChd${index}`}>
                  <MenuItem onClick={handleClose}>Recommendations</MenuItem>
                  <MenuItem onClick={handleClose}>Promotions</MenuItem>
                </div>
                : null
            }

         
       </Menu>

     </div>

   );
 }


const navigationLinks = [
      {  
        name: 'Setup',
        href: ''
      },
      {  
        name: 'Employees',
        href: ''
      },
      {  
        name: 'Holidays',
        href: ''
      },
      {  
        name: 'Event Declaration',
        href: ''
      },
      {  
        name: 'Transfers',
        href: ''
      },
      {  
        name: 'Leaves',
        href: ''
      },
      {  
        name: 'Travel / O.B',
        href: ''
      },
      {  
        name: 'Awards / Citations',
        href: ''
      },
      {  
        name: 'Memos',
        href: ''
      },
      {  
        name: 'Requisitions',
        href: ''
      },
      {  
        name: 'Warnings / Suspensions',
        href: ''
      },
      {  
        name: 'Complaints',
        href: ''
      },
      {  
        name: 'Violations',
        href: ''
      },
      {  
        name: 'Performance Eval.',
        href: ''
      },
     
      
];

// *** future reference ***
// const MyThemeComponent = styled('div')(({ theme }) => ({
//   color: theme.palette.primary.contrastText,
//   backgroundColor: theme.palette.primary.main,
//   padding: theme.spacing(1),
//   borderRadius: theme.shape.borderRadius,
// }));


// const useStyles = makeStyles(() => (
//       {
//         link: { 
//                  marginRight: 20,
//               },
//         avatar: { 
//                  marginRight: 'auto',
//                  color: 'white',
//                  backgroundColor: 'black'
//              }
//       } 
// ));

// const MyComponent = styled('div')({
//   color: 'darkslategray',
//   backgroundColor: 'aliceblue',
//   padding: 8,
//   borderRadius: 4,
  
// });


// const StyledPaper = styled(Paper, {name: 'StyledPaper', slot: 'Wrapper'}) ({
//     color: 'green',
//     // bacgroundImage: 'url("https:picsum.photos/200/300")',
//     backgroundColor: 'silver',
//     margin: 'auto',
//     borderRadius: 2,
//     height: 50,
//     width: 300,
//     display: 'flex',
//     alignment: 'center',
//     alignItems: 'center',
//     justifyContent: 'center',
//         '.MuiButton-root': {color: '#FF0000'}
// });


// function MenuListComposition() {

//   const [open, setOpen] = React.useState(false);
//   const anchorRef = React.useRef<HTMLButtonElement>(null);

//   const handleToggle = () => {
//     setOpen((prevOpen) => !prevOpen);
//   };

//   const handleClose = (event: Event | React.SyntheticEvent) => {
//     if (
//       anchorRef.current &&
//       anchorRef.current.contains(event.target as HTMLElement)
//     ) {
//       return;
//     }

//     setOpen(false);
//   };

//   function handleListKeyDown(event: React.KeyboardEvent) {
//     if (event.key === 'Tab') {
//       event.preventDefault();
//       setOpen(false);
//     } else if (event.key === 'Escape') {
//       setOpen(false);
//     }
//   }

//   // return focus to the button when we transitioned from !open -> open
//   const prevOpen = React.useRef(open);
//   React.useEffect(() => {
//     if (prevOpen.current === true && open === false) {
//       anchorRef.current!.focus();
//     }

//     prevOpen.current = open;
//   }, [open]);

//   return (
//     <Stack direction="row" spacing={2}>
      
//       <div>
//         <Button
//           ref={anchorRef}
//           id="composition-button"
//           aria-controls={open ? 'composition-menu' : undefined}
//           aria-expanded={open ? 'true' : undefined}
//           aria-haspopup="true"
//           onClick={handleToggle}
//         >
//           Dashboard
//         </Button>
//         <Popper
//           open={open}
//           anchorEl={anchorRef.current}
//           role={undefined}
//           placement="bottom-start"
//           transition
//           disablePortal
//         >
//           {({ TransitionProps, placement }) => (
//             <Grow
//               {...TransitionProps}
//               style={{
//                 transformOrigin:
//                   placement === 'bottom-start' ? 'left top' : 'left bottom',
//               }}
//             >
//               <Paper>
//                 <ClickAwayListener onClickAway={handleClose}>
//                   <MenuList
//                     autoFocusItem={open}
//                     id="composition-menu"
//                     aria-labelledby="composition-button"
//                     onKeyDown={handleListKeyDown}
//                   >
//                     <MenuItem onClick={handleClose}>Profile</MenuItem>
//                     <MenuItem onClick={handleClose}>My account</MenuItem>
//                     <MenuItem onClick={handleClose}>Logout</MenuItem>
//                   </MenuList>
//                 </ClickAwayListener>
//               </Paper>
//             </Grow>
//           )}
//         </Popper>
//       </div>
//     </Stack>
//   );

              

export default function BurgerMenu() {
  
  // const styles  = useStyles;
  
  const [Open, setOpen] = useState(false);
  
  

  return (
        
      <div style={{ width: '100%' }} >
        
          <AppBar position='relative' color='primary' >
 
              <Container maxWidth='xl' maxHieght='sm' id='rcorners1'>
                
                  <Toolbar disableGutters>
                        
                        
                        <Avatar sx={ { alignItems: 'left', marginLeft: '-90px', marginRight: '80px', color: 'white', backgroundColor: 'blue'} } > Hr </Avatar>                
                        
                      
                        <Hidden sxDown> 
                            
                              {     
                                      navigationLinks.map ((menu, index) => (
                                            // <Link sx={ {marginRight: 5} } key={index} color='white' variant='button' underline='none' href = {menu.href} > 
                                            //       { menu.name }
                                            // </Link>

                                            <BasicMenu key={index} title={menu.name} index={index} />
                                        ))
                              } 
                            
                              
                        </Hidden>
                      
                     
                        
                        <Hidden smUp>
                            <IconButton>
                                <MenuIcon onClick={() => setOpen(true)} />
                            </IconButton>
                        </Hidden>
                      
                      

                  </Toolbar>
                  
              </Container>
              
              <Drawer anchor='right' open = {Open} onOpen = {  () => setOpen(true)} onClose = { () => setOpen(false)} >
                
                    <div>
                        <IconButton> <ChevronRight onClick={() => setOpen(false)} /> </IconButton>
                    </div>

                    <Divider /> 

                    <List>
                          {   
                              navigationLinks.map ((menu, index) => (
                                  <ListItem key={index}> 
                                      <Link color='textPrimary' variant='button' underline='none' href = {menu.href} > 
                                          {menu.name}
                                      </Link> 
                                  </ListItem>
                              )) 
                          }
                    </List>  

              </Drawer>    

          </AppBar>    
          
      </div>
  )
}