import {configureStore} from '@reduxjs/toolkit';

import userAuthReducer from './features/users/authsSlice';

// HR
import employeeReducer from './features/hr/employeesSlice';
import departmentReducer from './features/hr/departmentsSlice';
import branchReducer from './features/hr/branchesSlice';
import sectionReducer from './features/hr/sectionsSlice';
import statusReducer from './features/hr/statusesSlice';
import positionReducer from './features/hr/positionsSlice';
import typeofWarningReducer from './features/hr/typeofWarningsSlice';
import typeofLeaveReducer from './features/hr/typeofLeavesSlice';

import separationCodesReducer from './features/hr/separationCodesSlice';

// TMS
import shiftReducer from './features/hr/shiftsSlice';
import otAuthorizationReducer from './features/tms/otAuthorizationsSlice';



import timecardReducer from './features/tms/timecardsSlice';

// Note: userAuthentication, employees, departments , branches ==> are example of slice's name.

export const store = configureStore ({
      reducer: {
                  userAuthentication:       userAuthReducer,
                  // hr
                  employees:                employeeReducer,
                  departments:              departmentReducer,
                  branches:                 branchReducer,
                  sections:                 sectionReducer,

                  statuses:                 statusReducer,
                  positions:                positionReducer,
                  typeofWarnings:           typeofWarningReducer,
                  typeofLeaves:             typeofLeaveReducer,
                  
                  // tms
                  shifts:                   shiftReducer,
                  otAuthorizations:         otAuthorizationReducer,
                  timecards:                timecardReducer,

                  // pms
               
               },

               middleware: getDefaultMiddleware => getDefaultMiddleware({
                  serializableCheck: false
                })
});

