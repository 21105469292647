
import {Routes, Route, useLocation} from 'react-router-dom'
import {Timecards, TimeAttendance, Shifts, ChangeShifts, ChangeRestday, OTAuthorizations} from './TmsPages';



// import Navbar from './HrPages/HrMenu';
import BurgerMenu from './TmsPages/Components/TmsHamburgerMenu';


const TmsNavigationMenu = () => (
  
      <div style={{ width: '90%', position: 'fixed', border: 0, }} >
        

          <BurgerMenu />
          
          <br/>
          <br/>
          
          { useLocation().pathname === '/dashboard/tms'? <Timecards /> : null}
          { useLocation().pathname === '/dashboard/tms/timecards'? <Timecards /> : null }
          { useLocation().pathname === '/dashboard/tms/time-attendance'? <TimeAttendance /> : null }
          { useLocation().pathname === '/dashboard/tms/shifts'? <Shifts /> : null }
          { useLocation().pathname === '/dashboard/tms/change-shifts'? <ChangeShifts /> : null }
          { useLocation().pathname === '/dashboard/tms/change-restday'? <ChangeRestday /> : null }
          { useLocation().pathname === '/dashboard/tms/ot-authorizations/std'? <OTAuthorizations type='std' /> : null }
          { useLocation().pathname === '/dashboard/tms/ot-authorizations/adv'? <OTAuthorizations type='adv' /> : null }

          
          
           {/* <Routes>
                 <Route path='/dashbord/tms/timecards' element={Timecards} />
           </Routes>   */}
          
      </div>

  )




export default TmsNavigationMenu;