
import {Routes, Route, useLocation} from 'react-router-dom'
import {Timecard} from './ManagementPages';

// import Navbar from './HrPages/HrMenu';
import BurgerMenu from './ManagementPages/Components/MgmtHamburgerMenu';

const MgmtNavigationMenu = () => (
        <>
          {/* <Navbar /> */}
          
          <BurgerMenu />
          
          <br/><br/>
          
          {useLocation().pathname === '/dashboard/mgmt'? <Timecard /> : null} 

          
          
          
          <Routes>
                 <Route path='/dashbord/mgmt' element={Timecard}/>
                 
          </Routes> 
          
        </>

  )




export default MgmtNavigationMenu;