

// Ref: https://stackoverflow.com/questions/37949981/call-child-method-from-parent
// import React, {useRef, forwardRef, useImperativeHandle} from 'react';

import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import Axios from 'axios';
import { decode } from "base64-arraybuffer";

import { useDispatch, useSelector } from 'react-redux';

// import {Buffer} from 'buffer';



import styled from '@emotion/styled'

import {  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
          Popover, Button, Box,  Tab, Paper, TextField, 
          MenuItem, InputLabel,
          Checkbox,
          Grid, 
          FormGroup, 
          FormControl, FormLabel, RadioGroup as MUIRadioGroup, FormControlLabel, Radio, 
          ThemeProvider, useTheme, createTheme,Typography,
          TableContainer, Table, TableBody, TableHead, TableRow, TableCell
          
        } from '@mui/material';


import moment from 'moment';

import { styled as styledMui } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';


import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {TabContext, TabList,TabPanel} from '@mui/lab';
import Stack from '@mui/material/Stack';

// 


import FavoriteIcon from '@mui/icons-material/Favorite' ;
import { grey } from '@mui/material/colors';
import  {FileAttachment, LoadPic, StripDataImageinBase64, convertBase64toBlob, getDataBlob,getBlobFile } from '../../api/functions';
import Iconify from '../../components/iconify';

 // import Typography from 'src/theme/overrides/Typography';
 
 
import { useForm, Form,
          TxtField, 
          CheckBox as CheckBox1,
          DatePicker,TimePicker, 
          DateTimePicker, DateTimePickerStaticLandscape,
          Select, 
          RadioGroup
        } from '../../components/controls' 



import { fetchDepartments,  allDepartments, departmentData as DProfile ,fetchStatus as fetchDeptStatus} from '../../features/hr/departmentsSlice';
import { fetchEmployees, allEmployees, fetchStatus as fetchEmpStatus, addNewRecord, editRecord } from '../../features/hr/employeesSlice';
import { fetchProfile as fetchTimeProfile, data as dataTProfile } from '../../features/tms/timecardsSlice';





  
const Div = styled.div({
   color: 'red'
})

 // use this to apply your own customized theme.
 const theme = createTheme({
      palette:  {
         mode: 'light',
         primary: {
             main: "#D433FF",
         }
      },
      Typography: {
           h1:  {
              fontSize: "1000px",
           }
      }

})

const H1 = styled.h1(
      {
        fontSize: 14,
      },
      props => ({ color: props.color })
)    






// ====================================================================================================================================================================================



const Timecard = ({data, setTProfile}) => {

      
     const dispatch = useDispatch(); 

     const [Mode, setMode]  = useState(data.Mode);
     const [Open, setOpen] = useState(true);
      


     // In order to gain access to the child component instance,
     // you need to assign it to a `ref`, so we call `useRef()` to get one
     const childProfile = useRef();
  
      
     
     

      
      useEffect(() => {

            const handleEsc = (event) => {
                  
                  if (event.key === 'Escape') {
                        OnXButton()
                  }
            };

            document.addEventListener('keyup',  handleEsc, );

            // Plain Javascript
            // document.getElementsByTagName('imgPhoto')[0].addEventListener("click",  (event) => {
            //       console.log(event.target.src);
            // });

            return () => {
               document.removeEventListener('keyup', handleEsc);
            };

      }, []);


      
      // Departments
      const fDeptStatus  = useSelector (fetchDeptStatus);

      useEffect ( () => {

            if (fDeptStatus === 'Idle') {
                dispatch ( fetchDepartments ());
            }
            
      }, [fDeptStatus, dispatch]) 

      
      // Employees
      const fEmpStatus  = useSelector (fetchEmpStatus);

      useEffect ( () => {

            if (fEmpStatus === 'Idle') {
                  dispatch ( fetchEmployees ());
            }
            
      }, [fEmpStatus, dispatch]) 


 
      


      
      // window.onload = function() {

      //       document.getElementById('imgPhoto').addEventListener('click',  (e) => {
            
      //             //   const img = document.createElement('img');
      //       //   img.setAttribute('src', 'http://blog.stackoverflow.com/wp-content/uploads/stackoverflow-logo-300.png');
      //       //   e.target.appendChild(img);
      //        alert ('nice')
            
      //       });
          
      //     };

          

      
          // document.addEventListener('keyup', (e) => {
         //       if (e.key === "Escape") { 
         //           // hide modal code
         //           // alert("Escape key pressed")
         //           OnXButton()
         //       }
         //   });
         


          
      function OnXButton() {
            setOpen(false); 
            setTProfile( (prev) => ({...prev, Mode: '', dataTProfile}) ) 
      }   
      
     
      function setFocusToTextBox(id){
          
            // ref.current.focus();
            document.getElementById(id).focus();  // bad practice for accessing DOM Element directly !!!, but we allow it to do shortcuts accessing the child component's element directly!
      
      }

      function saveUploadImage (empID) {

            const formData = new FormData();
            console.log(' -------------  '  )
            console.log( document.getElementById('InputFilePhoto').files[0])
            console.log(' -------------  '  )
            
            formData.append('files', document.getElementById('InputFilePhoto').files[0] , empID )
            // formData.append('files', blob )
            
               
               
           //     // formData.append('PhotoBlob', blob)
               
           //   // console.log(`document.getElementById('InputFilePhoto').files[0] ${document.getElementById('InputFilePhoto').files[0]}`)
     
           //     // formData.append('File', blob )
               
               Axios.post ('/api/upload', formData)
                    .then ( res => {
                             if (res.data.Status === 'Success'){
                                   console.log('Succeeded!')
                             } else {
                                   console.log(`Failed! ${ JSON.stringify(res.data.Message)}`)
                             }
                          })
                    .catch( err => console.log(`catch err: ${err}`));
     
         }


      
      function OnSaveRecord (Mode = 'AddNewRecord', Btn = 'Save&New') {
             
            if (Btn === 'Save&New') {
                  // Save&New

                  if (Mode === 'AddNewRecord') { 
                  
                     dispatch ( addNewRecord (childProfile.current.getCurrent_Data()) )

                  }

                  if (Mode === 'EditRecord') { 
                   
                     dispatch ( editRecord (childProfile.current.getCurrent_Data()) )
                        
                  }

                  
                  saveUploadImage(childProfile.current.getCurrent_Data().EmployeeID)

                  // Refocus - for data entry 
                 setMode ('AddNewRecord')
                 childProfile.current.initData() 
                 setFocusToTextBox ('EmployeeID');
    


            }      
            else {
                  // Save&Close

                  // alert (`Mode = 'AddNewRecord', Btn = 'Save&New', Mode: ${Mode} , Btn: ${Btn}}`)

                  setTProfile( (prev) => ({...prev, Mode: '', dataTProfile}) )

                  if (Mode === 'AddNewRecord') { 
                  
                      dispatch ( addNewRecord (childProfile.current.getCurrent_Data()) )

                  }

                  if (Mode === 'EditRecord') { 

                      dispatch ( editRecord (childProfile.current.getCurrent_Data()) )  

                  }
                  
                  saveUploadImage(childProfile.current.getCurrent_Data().EmployeeID)
                  
                  OnXButton();
                  
                  

            }      

     }
      
      

      
      
      return (

                  <Paper>
                              
                              <Dialog PaperProps={{ style: { borderRadius: 10 } }}
                                      open = {Open} 
                                      onCLose = {false} 
                                      fullwidth maxWidth = {'sm'} 
                                      
                              >
                              {/* <DialogTitle id='dialog-title'> {`Timecard [ ${data.Mode} + ' -- ' + ${data.Id}  ]`}  */}
                              <DialogTitle id='dialog-title'> {`Timecard [ ${ Mode === 'AddNewRecord'? 'AddNew Record': 'Edit Record' } ]`} 
                              
                                    <IconButton
                                          id=''
                                          aria-label="Close"
                                          onClick={() => OnXButton() }
                                          sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                          }}
                                          >
                                          <CloseIcon />
                                    </IconButton>
                                                
                              </ DialogTitle>
                              
                              {/* <DialogContent style={{ height: '100%', overflow: "hidden"  }}  > */}
                              <DialogContent style={{ height: '68%', overflow: "hidden"  }}>
                                    <DialogContentText id='dialog-description'> 
                                          {/* Timecard [New] */}
                                    </DialogContentText>
                                    
                                          {/* {console.log  (`{data} ${JSON.stringify(data)}}`)} */}
                                          
                                          <Profile ref={childProfile} data={data} /> 

                                    
                              </DialogContent>    

                              {/* ${data.Mode} */}

                              <DialogActions sx={{marginTop: '1px', marginBottom: '10px', marginRight: '25px',}} >
                                    <Button autoFocus onClick = { () => OnSaveRecord(Mode, 'Save&New')  } sx={{backgroundColor: 'light', color: 'darkblue', borderColor: Mode === 'AddNewRecord'? 'darkblue': 'lightblue',  }} variant='outlined'> Save & New </Button>    
                                    <Button onClick = { () => OnSaveRecord(Mode, 'Save&Close')  } sx={{backgroundColor: 'light', color: 'darkblue', borderColor: Mode === 'EditRecord'? 'darkblue': 'lightblue',  }} variant='outlined'> Save & Close </Button>    
                                    <Button onClick = { () => OnXButton(true) } sx={{backgroundColor: 'light', color: 'darkblue', borderColor: 'lightblue', }} variant='outlined'> Cancel </Button>    
                              </DialogActions>

                               
                        </Dialog>
                              
                                             

                  </Paper>        
                
                );
};



// ====================================================================================================================================================================================

function a11yProps (index) {
    
    return {
          id:              `full-width-tab-${index}`,
         'aria-controls':  `full-width-tabpanel-${index}`,
    };
  }


// ====================================================================================================================================================================================




const Profile = forwardRef( ({data}, ref) => { 

      const dispatch = useDispatch();  
      
      const [value, setValue] =  useState(0);
      const [Profile, setProfile] =  useState({});
      

      const childEInfo = useRef();
      
      

      useEffect (() => {
                  if (data.Mode === 'AddNewRecord') {
                        setProfile(data.dataTProfile)
                  } else {     
                        Axios.get(`/api/timecards/dataentry/:trid='${data.TRID}'`)
                              .then ((res) =>  {
                                    console.log(`Axios.get(/api/timecards/dataentry/:trid='$ {data.TRID}' ${ JSON.stringify(res.data[0])}`)
                                    setProfile(res.data[0])
                              })
                              .catch((err) => {
                               console.log (err);
                              })
                        }            
            }
      
      , []);

      
      
      // Departments
      const fDeptStatus  = useSelector (fetchDeptStatus);

      useEffect ( () => {

            if (fDeptStatus === 'Idle') {
                  dispatch ( fetchDepartments ());
            }
            
      }, [fDeptStatus, dispatch]) 

      
      // Employees
      const fEmpStatus  = useSelector (fetchEmpStatus);

      useEffect ( () => {

            if (fEmpStatus === 'Idle') {
                  dispatch ( fetchEmployees ());
            }
            
      }, [fEmpStatus, dispatch]) 
      
      
    
    const handleChange = (evt) => {
      
      setValue (evt.target.value);
      
    }
      

   
    useImperativeHandle(ref, () => ({
        
        initData() {
           
           setProfile(dataTProfile)
         
        },

        
        
        getCurrent_Data() {
          
             console.log (`Profile ${ JSON.stringify( {...Profile, Photo: document.getElementById('imgPhoto').src   }) }`)           
           
             return {...Profile};

        },
        
        
        getEInformation() {
            return childEInfo.current.getPhotoBlob()
            
        }, 

       
   
        
        
    }) ) ;


      



    return (
        
       <Box sx={{ borderBottom: 1, borderBottomWidth: '8px', borderColor: 'divider',  bgcolor: 'background.paper', width: '565px', height: '615px', }}   >
                
                  <TabContext value = { Number(value) } >
                      <Box sx={{borderBottom: 0, borderColor: 'divider', width: '97%', marginLeft: '8px',  marginBottom: '-23px',   }}>
                           
                           <TabList
                                aria-label='Data Entry'     
                                onClick={ handleChange }
                                indicatorColor="secondary"
                                TabIndicatorProps={{ sx: {backgroundColor: 'orange', height: 5} }}
                                textColor='inherit' 
                                variant='scrollable'
                                sx={{  "& button": {borderTopLeftRadius: 8, borderTopRightRadius: 8, backgroundColor: 'darkblue' }, 
                                       "& button:hover": {backgroundColor: 'blue'},
                                       "& button:focus": {backgroundColor: 'darkblue'},
                                       "& button:active": {backgroundColor: 'cyan'},
                                       "& button:Mui-selected": {backgroundColor: 'darkblue'},
                                       
                                 }}

                              // title= 'Tooltiptext'
                              
                           >

                               {/* <Tab label='Employee Information' {...a11yProps(0)}  Value='0' icon={<FavoriteIcon/> } iconPosition='start' /> */}
                               <Tab label='Data Entry' {...a11yProps(0)}  Value='0' style={{color: 'orange', fontSize: '12px', paddingLeft: 38, paddingRight: 38, paddingTop: 2, paddingBottom: 2,  margin: 0, }} />
                               
                           </TabList>

                      </Box>
                      
                      <TabPanel value={0} >  
                         <Box border={2} borderColor='divider' height='100%' padding={.5} sx={{ marginLeft: '-15px', }}  >
                           <DataEntry 
                                ref = {childEInfo}
                                Profile = {Profile}  
                                setProfile = {setProfile} 
                           />
                         </Box>

                      </TabPanel>
                      
                      
                  </TabContext>   

                                    
              
            </Box>

          )

 } )


 // ====================================================================================================================================================================================
 
 const DataEntry = forwardRef( ({Profile, setProfile}, ref ) => {
    
     const imgPhotoRef = useRef();
      
     
     const fDepartments  = useSelector (allDepartments);
     const fEmployees  = useSelector (allEmployees);
      
     

   
    // use this to apply your own customized theme.
    const theme = createTheme({
           palette:  {
              mode: 'dark',
              primary: {
                  main: "#D433FF",
              }
           },
           Typography: {
                h1:  {
                   fontSize: "1000px",
                }
           }

     })

   
   
      const inputOpenFileRef = React.createRef()

      const showOpenFileDlg = () => {
           
            inputOpenFileRef.current.click()
           
            console.log (` inputOpenFileRef.current.value ${inputOpenFileRef.current.value}`);
          
      }
      
      function getImgFileBase64(inputElementID) {
            
            const files = document.getElementById(inputElementID).files;
            
            let fileBase64 = null;
 
             if (files.length > 0) {
                 
                   const reader = new FileReader();
                         
                   reader.readAsDataURL(files[0]);
                   // reader.readAsText(files[0], 'UTF-8');
 
                   reader.onload = function () { 
                         
                          fileBase64  =  reader.result;   
                         
                         if ( fileBase64.length > 0 ) {
                              
                              const myBlob = convertBase64toBlob(fileBase64) // decode(fileBase64);
                              console.log(`myBlob ${myBlob}`)
                              console.log(` URL.createObjectURL(myBlob) ${  URL.createObjectURL(myBlob) }`)
 
                              setProfile({...Profile, Photo: myBlob})
 
                              
                              
                         }  
 
 
                        
                   };
 
                   reader.onerror = function (error) { 
                         console.log('Error: ', error); 
                   };
             }
 
             return fileBase64;
     } 

    
      useImperativeHandle(ref, () => ({

            getPhotoBlob() {
                  
                  return alert('for future use')
      
            },

         
  
    }) )  ;
          
    
    
    


 return (
    
      <Form noValidate autoComplete='off' >
    
            <Grid container direction="row" item xs={12} alignItems="flex-start" justify="space-between"  padding-top padding-bottom padding={.5}>
      
                        {/* transferred code */}
                        <Grid container direction="rows" item xs={12} alignItems="flex-start" justify="space-between"  padding-top padding-bottom >

                              <Grid container direction="column" item xs={8} alignItems="flex-start" justify="space-between"  padding-top padding-bottom >

                                    {/* <hr width="100%" color='gray'/> */}
                                    
                                          <Grid container direction="column" alignItems="flex-start" item xs={8}>
                                                <Grid container direction="column" alignItems="flex-start" item xs={12}>
                                                     <h3 style={{color: "#0099ff"}}> Select: </h3> 
                                                </Grid>
                                          </Grid>
                                    
                                    {/* 
                                          =================================================================================================================================================================  
                                          Department list 
                                          =================================================================================================================================================================
                                    */}
                                    <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                                                
                                                { 
                                                            <Select
                                                                  name='DeptID'
                                                                  label='Departments'
                                                                  value = {Profile.DeptID}
                                                                  onChange={ (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) ) }
                                                                  options={ 
                                                                        fDepartments.map( (item, idx) => {
                                                                                          return {id: item.DeptID, name: item.Department}
                                                                                    } )
                                                                        
                                                                        }
                                                                  variant="filled"            
                                                                  style={ {val: { width: 315, height: 31, fontSize: "1rem", padding: 0 }, label: 'customized'} }
                                                            /> 
                                                
                                                }

                                    </Grid>
                                    
                                          {/* 
                                          =================================================================================================================================================================  
                                          Employees list 
                                          =================================================================================================================================================================
                                    */}

                                    <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                                          
                                          { 
                                                      <Select
                                                            name='EmployeeID'
                                                            label='Employees'
                                                            value = {Profile.EmployeeID}
                                                            onChange={ (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) ) }
                                                            options={ 
                                                                  fEmployees.map( (item, idx) => {
                                                                                    return {id: item.EmployeeID, name: item.Fullname}
                                                                              } )
                                                                  
                                                                  }
                                                            variant="filled"
                                                            style={ {val: { width: 315, height: 31, fontSize: "1rem", padding: 0 }, label: 'customized' } }
                                                      /> 
                                          
                                          }

                                    </Grid>
                              
                              </Grid>

                              <Grid container direction="column" item xs={4} alignItems="flex-start" justify="space-between"  padding-top padding-bottom marginTop={1} marginBottom={3}>

                                    <img id='imgPhoto' 
                                    src = { Boolean(Profile.Photo) && ( Profile.Photo instanceof Blob ? URL.createObjectURL(Profile.Photo) : LoadPic("data:image/png;base64, ", Profile.Photo) )  }
                                    alt="Employee Photos" 
                                    align='center' 
                                    width={160} height={180} 
                                    style={{ cursor: 'pointer', borderRadius: '3%', border: "2px dashed  #808080", margin: "0px" }} 
                                    border='1'
                                    
                                    />
                                
                              </Grid>      

                        </Grid>
                        

                        {  /* 
                        ======================================================================================================================================================================== 
                                                                                                            DATA
                        ======================================================================================================================================================================== 
                        */
                        }
                        
                        
                        <Grid container direction="row" alignItems="flex-start" item xs={12} >
                              
                              {/* <Grid container direction="row" alignItems="flex-start" item xs={12} border={3}> </Grid> */}
                              <br/><br/>
                              <Grid container direction="column" alignItems="flex-start" item xs={12} border= {0} backgroundColor="#0099ff" >
                                  {/* <h3 style={{color: "#000000"}}> Data </h3>  */}
                                  <span style={{color: "#000000"}} > &nbsp; <b>Data</b> </span> 
                              </Grid>
                              
                              <span>Select Date & Time</span>
                        </Grid>
                        
                        <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  marginLeft={2}   >
                        
                              <Grid container direction="row" item xs={12}  alignItems="flex-start" sx={{ padding: 0, alignContent: 'left' }} >
                                    <Grid container direction="column" item xs={1.5}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>Date From</span>
                                    </Grid> 
                                    <Grid container direction="column" alignItems="left"  item xs={4} marginLeft={1}  border={0} marginBottom={.5} marginTop={.5} >
                                          <DatePicker 
                                                      name     = 'DateFrom'
                                                      label    = ''
                                                      value    = { Profile.LogDate } 
                                                      onChange = { (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value })) }  
                                                      
                                          /> 
                                    </Grid>
                                    <Grid container direction="column" item xs={1}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>Date To</span>
                                    </Grid> 
                                    <Grid container direction="column" alignItems="left"  item xs={4} marginLeft={1}  border={0} marginBottom={0} marginTop={.5} >
                                          <DatePicker 
                                                      name     = 'DateTo'
                                                      label    = ''
                                                      value    = { Profile.LogDate } 
                                                      onChange = { (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value })) }  
                                                      
                                          /> 
                                    </Grid>
                              </Grid>      
                                    
                              <Grid container direction="row" item xs={12}  alignItems="flex-start" sx={{ padding: 0, alignContent: 'left' }} >

                                    <Grid container direction="column" item xs={1.5}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>Log-Time</span>
                                    </Grid> 
                                    <Grid container direction="column" alignItems="left"  item xs={4} marginLeft={1}  border={0} marginBottom={1} marginTop={0} >
                                          <TimePicker
                                                name     = "LogTime"
                                                label    = ""
                                                value    = { Profile.LogTime}
                                                onChange = { (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value })) }  
                                          />
                                    </Grid>      
                              </Grid>      

                        </Grid>

                        
                        
                        {/* <Grid container direction="row" justifyContent ='left' item xs={12}  justify="space-between"  >
                        <Grid container direction="column" alignItems="flex-start" item xs={6}  sx={{ padding: .3 }} >
                              <TextField id='EmployeeID' name='EmployeeID' label='Employee ID' inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}  value={Profile.EmployeeID } onChange={ (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) )   } size="medium"  variant="filled" color='secondary'  required  />  
                        </Grid>
                        <Grid container direction="column" alignItems="flex-start" item xs={6}  sx={{ padding: .3 }} >
                              <TextField name='EnrollNo' label='EnrollNo' inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}  value={Profile.EnrollNo } onChange={ (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) )   } size="medium"  variant="filled" color='secondary'  />  
                        </Grid>
                        </Grid>


                        <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  >
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='Lastname'   label='Lastname'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}  value={Profile.Lastname} onChange={ (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) )  }  size="medium" variant="filled" fullWidth required/>
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                              <TextField name='Firstname'  label='Firstname'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}  value={Profile.Firstname} onChange={ (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) ) } size="medium" variant="filled" fullWidth required/>
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                              <TextField name='Middlename' label='Middlename'  inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }} value={Profile.Middlename} onChange={ (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) )  } size="medium" variant="filled" fullWidth required/>
                        </Grid>
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='Address'      label='Address'   inputProps={{ style: { fontSize: "1rem", height: '35px', padding: '0 70px',}, }}  size="medium"  multiline rows={2} autoComplete="off" variant="filled" value={Profile.Address} onChange={  (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) )  } fullWidth/>
                        </Grid>
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                              <TextField name='City' label='City'  inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }} size="medium" variant="filled" fullWidth required value={Profile.City} onChange={ (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) )  }  />
                        </Grid>
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                              <RadioGroup name=     'Gender'   label=    'Gender' value=    {Profile.Gender}
                                          items=    { [ {'id': 'Male', label: 'Male'}, {'id': 'Female', label: 'Female'}] }
                                          onChange= {  (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) )  }
                              
                              />
                        </Grid>

            

                        

                        <br /> <br /><br />
                        */}

                         {/* <hr width="100%" color='gray' style={{ height: '2px', 'background-color': '#ff0000', 'border-bottom': '3px dotted grey', display: 'block' }} />  */}
                        
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='Notes'  label='Notes'   inputProps={{ style: { fontSize: "1rem", height: '110px', padding: '0 0px',}, }}  size="medium"  multiline rows={5} autoComplete="off" variant="filled" value={Profile.Notes} onChange={ (evt) => setProfile ( (prev) => ({...prev, [evt.target.name]: evt.target.value }) ) } fullWidth/>
                        </Grid> 

                        
                        
            </Grid>

          
      </Form> 
   
    )

} );




 



export default Timecard;

//  <TextField  InputLabelProps={{ shrink: true }} label="Lastname" placeholder='Enter Lastname' sx={{ width: '250px', height: '5px',}}/>
