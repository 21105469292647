import React, {useState, useEffect, useRef} from 'react';
import  {Router,Routes, Route, Link as rLink,   useRoutes, useNavigate } from 'react-router-dom';


import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';

import Popper from '@mui/material/Popper';
import Popover from '@mui/material/Popover';

import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';

    
import { Link, AppBar, Toolbar, Container, Avatar, Hidden, IconButton, Paper, Drawer, Divider, List, ListItem, Box} from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";

import { styled } from '@mui/material/styles'; 
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';  // https://mui.com/material-ui/material-icons/?query=arrow+down


import Menu from '@mui/material/Menu';



function BasicMenu({key, title, index}) {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItemAnchorEl, setMenuItemAnchorEl] = React.useState(null);

  const [anchorSubEl, setAnchorSubEl] = React.useState({evt: null, itemMenu: null});
  const [menuItemAnchorSubEl, setMenuItemAnchorSubEl] = React.useState(null);

  const openSub = Boolean(anchorSubEl.evt);
  const open = Boolean(anchorEl);


  const navigate = useNavigate();

  
  
  const handleClick = (event) => {
    
     event.preventDefault();
 
     
     switch (title) {
      
         case 'Setup':
              setAnchorEl(event.currentTarget)
              break;
      
         case 'Timecard':
              navigate ("/dashboard/tms/timecards")
              break;

         case 'Time Attendance':
               navigate ("/dashboard/tms/time-attendance")
               break;
         
         case 'Get Logs':
               setAnchorEl(event.currentTarget)
               break;

        //  case 'Shifts':
        //       navigate ("/dashboard/tms/shifts")
        //       break;
         
        //  case 'Change Shifts':
        //       navigate ("/dashboard/tms/change-shifts")
        //       break;
         
        //  case 'Change Restday':
        //       navigate ("/dashboard/tms/change-restday")
        //       break;     

        //  case 'O.T Authorizations':
        //       setAnchorEl(event.currentTarget)
        //       break;

        //  case 'Travel / O.B':
        //       setAnchorEl(event.currentTarget)
        //       break;

         default:
              setAnchorEl(null)
     }

     // display grid now for the record, or use another <Listing />

  };

  
  const onMouseOverSubMenu = (Props, event) => {
    
       const itemName = Props.item;
       const itemStatus = Props.status; 
      
       setAnchorSubEl( {evt: event.currentTarget, itemMenu: Props.status} );  
     
  }



  const handleMenuClick = (Props) => {
    
     const itemName = Props.item;
     const itemStatus = Props.status;
    
    
    return () => {  
       
        switch (itemName) {
            case 'Setup':
                 
                  if (itemStatus === 'Shifts')                navigate ("/dashboard/tms/shifts");
                  if (itemStatus === 'Change Shifts')         navigate ("/dashboard/tms/change-shifts");
                  if (itemStatus === 'Change Restday')        navigate ("/dashboard/tms/change-restday");
                  if (itemStatus === 'O.T Authorizations')    navigate ("/dashboard/tms/ot-authorizations/std")  // Default
                  
                  handleClose();
                  break;
            
            case 'Get Logs':
                  handleClose();
                  break;

            case 'Process DTR':
                  handleClose();
                  break;

            case 'Reports':
                  handleClose();
                  break;

            case 'Tools':
                  handleClose();
                  break;

            case 'Help':  
                  handleClose();
                  break;

                //   case 'O.T Authorizations':
                 
                //   if (itemStatus === 'Standard OT')          navigate ("/dashboard/tms/ot-authorizations/std");
                //   if (itemStatus === 'Advance OT (Early)')   navigate ("/dashboard/tms/ot-authorizations/adv");
                  
                //  handleClose();
                //  break;

            default:
                 alert('No Selection!');

        }

         return null;    
    } 
     
  };
  
  
  const handleClose = () => {
        setAnchorEl(null);
        setAnchorSubEl({evt: null, itemMenu: null})
  };



  return (

   <div>

      {
          <Popover
                open={ anchorSubEl.itemMenu === "O.T Authorizations"? openSub: false }
                anchorEl={ anchorSubEl.evt }
                anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                targetOrigin={{horizontal: 'right', vertical: 'top'}}
                // placement="right-end"
                onClose={handleClose}
                // onRequestClose={handleClose} 
            >				  
                <Box> 
                      <MenuItem key='ot-adv' onMouseDown = { () => navigate ("/dashboard/tms/ot-authorizations/adv") }   onClick = { handleClose } > Advance OT (Early) </MenuItem>
                      <MenuItem key='ot-std' onMouseDown = { () => navigate ("/dashboard/tms/ot-authorizations/std") }   onClick = { handleClose } > Standard OT (Std) </MenuItem>
                </Box>

            </Popover>
      } 


        <Button
            id="basic-button"
            variant='contained'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            endIcon={ title === 'Setup' || title === 'Get Logs' || title === 'Process DTR' || title === 'Reports' || title === 'Tools' || title === 'Help'  ?   <ExpandMoreIcon />  : null } 
            
            onClick={handleClick}
        >
          
            {title} 

        </Button>
      
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
        >
       
                {
                  title === 'Setup'? 
                    <>
                      <MenuItem divider={Divider} sx={{ ...(Divider && { margin: 0 }) }}  onClick={handleClose}> Shifts </MenuItem>
                      <MenuItem onClick={handleClose}> Change Shifts </MenuItem>
                      <MenuItem divider={Divider} sx={{ ...(Divider && { margin: 0 }) }}   onClick={handleClose}> Change Restday </MenuItem>
                      <MenuItem onMouseOver={ e => onMouseOverSubMenu( { item: 'Setup', status: "O.T Authorizations" } , e) }  > O.T Authorizations <ChevronRight /> </MenuItem>
                      
                    </>
                    : null
                }

              {
                  title === 'Timecard'? 
                  <>
                    <MenuItem onClick={handleClose}>Payroll Adjustment</MenuItem>
                    <MenuItem onClick={handleClose}>Payroll Exemption</MenuItem>
                  </>
                  : null
              }
              
              {
                title === 'Shifts'? 
                  <>
                    <MenuItem divider={Divider} sx={{ ...(Divider && { margin: 0 }) }}   onClick={handleClose}>CutOff</MenuItem>
                    <MenuItem onClick={handleClose}>Generate DTR</MenuItem>
                    <MenuItem onClick={handleClose}>Process Payroll</MenuItem>
                  </>
                  : null
              } 
              
              {
                title === 'O.T Authorizations'? 
                  <>
                    <MenuItem divider = {Divider} sx={{ ...(Divider && { margin: 0 }) }}   onClick = { handleMenuClick( {item: 'O.T Authorizations', status: 'Standard OT'})} > Standard OT </MenuItem>
                    <MenuItem onClick = { handleMenuClick( {item: 'O.T Authorizations', status: 'Advance OT (Early)'}) } > Advance OT (Early) </MenuItem>
                  </>
                  : null
              } 

              {
                  title === 'Travel / O.B'? 
                  <>
                    <MenuItem onClick={handleClose}>Local</MenuItem>
                    <MenuItem onClick={handleClose}>Abroad</MenuItem>
                  </>
                  : null
              }    
        
        </Menu>

    </div>

  );
}


const navigationLinks = [
  {  
    name: 'Setup',
    href: ''
  },
  {  
    name: 'Timecard',
    href: ''
  },
  {  
    name: 'Time Attendance',
    href: ''
  },
  {  
    name: 'Get Logs',
    href: ''
  },
  // {  
  //   name: 'Shifts',
  //   href: ''
  // },
  // {  
  //   name: 'Change Shifts',
  //   href: ''
  // },
  // {  
  //   name: 'Change Restday',
  //   href: ''
  // },
  // {  
  //   name: 'O.T Authorizations',
  //   href: ''
  // },
  {  
    name: 'Process DTR',
    href: ''
  },
  {  
    name: 'Download Logs',
    href: ''
  },
  {  
    name: 'Reports',
    href: ''
  },
  {  
    name: 'Tools',
    href: ''
  },
  {  
    name: 'Help',
    href: ''
  },

  
];



export default function BurgerMenu() {
  
  // const styles  = useStyles;
  
  const [Open, setOpen] = useState(false);

  
  return (
       
    <div style={{ width: '100%' }} >
    
    
         <AppBar position='relative' color='primary' >
        
            
            <Container maxWidth='xl' maxHieght='sm' id='rcorners1'>
               
                <Toolbar disableGutters>

                      
                <Avatar sx={ { alignItems: 'left', marginLeft: '-90px', marginRight: '80px', color: 'white', backgroundColor: 'blue'} } > Tms </Avatar>                
                                              
                      
                      <Hidden sxDown> 
                          {   
                            navigationLinks.map ((menu, index) => (
                                // <StyledPaper> <Link key={index} color='textPrimary' variant='button' underline='none' href = {menu.href} > {menu.name} </Link> </StyledPaper>
                                // <MyComponent> </MyComponent>
                                //    <Link sx={ {marginRight: 5} } key={index} color='white' variant='button' underline='none' href = {menu.href} > {menu.name} </Link>
                                
                                <BasicMenu key={index} title={menu.name} index={index} />
                                
                            )) 
                          }
                      </Hidden>
                    
                      <Hidden smUp>
                          <IconButton>
                              <MenuIcon onClick={() => setOpen(true)} />
                          </IconButton>
                      </Hidden>
                    
                
                </Toolbar>

            </Container>
             
            <Drawer anchor='right' open = {Open} onOpen = {  () => setOpen(true)} onClose = { () => setOpen(false)} >
                
                  <div>
                        <IconButton> 
                            <ChevronRight onClick={() => setOpen(false)} /> 
                        </IconButton>
                  </div>
                  
                  <Divider /> 
                      
                      <List>
                            {   
                              navigationLinks.map ((menu, index) => (
                                  <ListItem key={index}> 
                                      <Link color='textPrimary' variant='button' underline='none' href = {menu.href} > {menu.name} </Link> 
                                  </ListItem>
                              )) 
                            }
                      </List>  

            </Drawer>    

        </AppBar>    
     
     </div>     

  )

}