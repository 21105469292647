
import {createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import moment from 'moment';

import { useDispatch } from 'react-redux';


export const fetchTimecards =  createAsyncThunk("timecards/fetchTimecards",  async ({setfetchProgressValue}) => {
      
      // const dispatch  = useDispatch();

      // console.log (`fetchTimecards ${ Status }`)
  
      let response = [];
      // const configProgress = {
      //       onDownloadProgress: progressEvent => {
      //                                                 const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
      //                                                 const current = progressEvent.currentTarget.response.length
                                                
      //                                                 const percentCompleted = Math.floor((current / total) * 100)
      //                                                 console.log('Completed: ', percentCompleted)
      //                                            }
      // };
      const configProgress = {
            onDownloadProgress: progressEvent => {
                                                      const total = parseFloat(progressEvent.event.target.getResponseHeader('Content-Length'));
                                                      const current = progressEvent.event.target.response.length
                                                
                                                      const percentCompleted = Math.round((current / total) * 100)
                                                      // dispatch(doFetchProgress(percentCompleted))
                                                    
                                                      setfetchProgressValue(percentCompleted) 

                                                     // localStorage.setItem("fetchProgress", percentCompleted);
                                                     // console.log(`Completed: percentCompleted: ${percentCompleted}`)
                                                     // console.log('Completed: ', percentCompleted)
                                                    
                                                 }
      };

      // const configProgress = { 
      //         onDownloadProgress: (progressEvent) => {
      //                                                       const progress = progressEvent.loaded / progressEvent.event.target.getResponseHeader('x-file-size');
      //                                                       const percent = Math.round(progress * 100);
      //                                                       console.log(`Percent Complete  ${percent}%`)
      //                                                }
      // }

  
      try {
            response = await Axios.get(`/api/timecards`, configProgress)
                                  .then ((res) => res.data);
            
      } catch (error) {
            console.error(`fetchTimecards thunk: error: ${error}`);
      }
      
            // alert (`fetchTimecards thunk ${Object.keys(response)}`)
            return response;
  
  });
 


// hold this fetchProfile , dont use !
export const fetchProfile =  createAsyncThunk("timecards/fetchprofile",  async (TRID) => {
      
      // alert (`JSON.stringify(data) ${JSON.stringify()}`)
     // alert ('jeff now!')
   
      let response = {};
   
       try {
             response = await Axios.get(`/api/timecard/dataentry/:id='${TRID}'`)
                                   .then ((res) => res.data[0]);
                                   
             // Id.OnInput ();  

       } catch (error) {
             console.error(error);
       } 
   
      // alert (`fetchProfile thunk ${ JSON.stringify(response)}`)
       return response;
   
   });

export const data = {
                        TRID:          '',
                        DeviceNo:      '',
                        EnrollNo:      '',
                        EmployeeID:    '',
                        VerifyMode:    '',
                        InOutMode:     '',
                        LogDate:       moment(new Date()).format('MM/DD/YYYY'),
                        LogTime:       moment(new Date()).format('MM/DD/YYYY HH:NN AMPM'),
                        Photo:         null,
                        Notes:         '',
                        UserName:      '',


   };

const initialState = {
                        List:                [],
                        Profile:             data,
 
                        isValidate:          false,              // Validate for saving records.
                        addRequestStatus:    'Idle',             // 'Idle' || 'Pending' || 'Succeeded' || 'Failed'
                        fetchProfileStatus:  'Idle',             // 'Idle' || 'Loading' || 'Succeeded' || 'Failed' ===> fetch records !!!
                        fetchStatus:         '',                // 'Idle' || 'Loading' || 'Succeeded' || 'Failed' ===> fetch records !!!
                        fetchProgress:        0,
                     }


const timecardsSlice = createSlice (
   {
      name: 'timecards',
      initialState,
      reducers:  {
            
                        setTimecard:    (state, action) => {
                                             
                                             // state = {...state, Username: action.payload.Username, Password: action.payload.Password,  isValidate: getValidate(action.payload)}; 
                                             // state = {...state, isValidate: action.payload.isValidate}

                                             console.log(`action.payload ${Object.keys(action.payload)}`)

                                            //  console.log(`action.payload ${Object.values(action.payload)}`)
                                             
                                             // state = {...state, ...action.payload};
                                             state.Profile = {...state.Profile, ...action.payload};
                                             console.log(`state ${state.Profile.TRID}`)
                                             return state;
                                    },

                        setCurrentID:    (state, action) => {
                                    
                              // console.log(`action.payload ${Object.keys(action.payload)}`)
                              //  console.log(`action.payload ${Object.values(action.payload)}`)
                              
                              // state = {...state, ...action.payload};
                              // state = {...state, {...Profile, TRID: action.payload.TRID} };
                              state.Profile = {...state.Profile, TRID: action.payload.TRID };
                              console.log(`state currentID ${state.TRID}`)
                              return state;
                        },
                        
                        isValidate: (state, action) => {
                              // validate the state.Profile if applicable for saving...
                              
                              // Form level validation or Field Level Validation (ref=useReference)
                              // const isCanSave = [state.Profile.TRID, state.Profile.Lastname, state.Profile.Firstname, state.Profile.Middlename, state.Profile.StatusID, state.Profile.DeptID].every(Boolean) && addRequestStatus === 'Idle' ;
                              const isCanSave = [state.Profile.TRID, state.Profile.Lastname, state.Profile.Firstname, state.Profile.Middlename].every(Boolean) ;


                              state = {...state, isValidate: isCanSave };
                              return state;

                        },

                        
                        doFetch: (state) => {
                              
                              state = {...state, fetchStatus: 'Idle' };
                              return state;

                        },

                        doFetchProgress: (state, {percentCompleted}) => {
                              
                              state = {...state, fetchProgress: percentCompleted };
                              return state;

                        },



                        addNewRecord:     (state, payload)  => {
                              
                              state = {...state, fetchStatus: 'Idle', Profile: payload.payload  };
                              console.log (`addnewrecord-state: ${ JSON.stringify(state.Profile)}`)
                              // console.log (`addnewrecord-state ...`)
                              
                              Axios.post(`/api/timecards/:mode='AddNewRecord'/:id='${state.Profile.TRID}'`, state.Profile)
                                   .then ((res) =>  {
                                        // console.log(`res.data ${ JSON.stringify(res.data[0])}`)
                                        console.log ('Record has successfuly been updated!!!')
                                   
                                    })
                                   .catch((err) => {
                                         console.log (err);
                                    })
                                    
                              
                              return state;  


                        } ,

                        editRecord:     (state, payload)  => {
                              
                              state = {...state,  fetchStatus: 'Idle',  Profile: payload.payload  };
                              console.log (`editrecord-state: ${ JSON.stringify(state.Profile)}`)

                              // alert(`dispatch app.post ('/api/timecards/:mode/:id/:data', (req, res) => {`)
                              // Axios.post(`/api/timecards/:mode='EditRecord'/:id='${state.Profile.TRID}'/:data=${state.Profile}`)
                              Axios.post(`/api/timecards/:mode='EditRecord'/:id='${state.Profile.TRID}'`, state.Profile)
                                   .then ((res) =>  {
                                        // console.log(`res.data ${ JSON.stringify(res.data[0])}`)
                                        console.log ('Record has successfuly been updated!!!')
                                   
                                    })
                                   .catch((err) => {
                                         console.log (err);
                                    })
                                    
                              
                              return state;   


                        },
                        
                        deleteRecord:     (state, {payload})  => {
                              // *** TEST ***
                              // console.log(`payload ${ JSON.stringify(payload)}`)
                              // console.log(`{payload} ${payload}`)
                              
                              state = {...state,  fetchStatus: 'Idle' };

                              const selected =  payload;
                              const s =  selected.map ( (item, idx) => {
                                    return console.log(`selected: ${idx +1} : ${item}`)      
                              } )
                              
                              // reserved code: axios.delete("/<your endpoint>", { data:<"payload object">})
                              
                              Axios.delete(`/api/timecards/delete`, {headers: {type: 'Array'}, data: payload} )
                              .then ((res) =>  {
                                   
                                   console.log ('Record has successfuly been updated!!!')
                              
                               })
                              .catch((err) => {
                                    console.log (err);
                               })
                               
                         
                              return state;   

                        } 
      },

      extraReducers: (builder) => {
         builder
           .addCase(fetchTimecards.pending, (state, action) => {
                 state = {...state, fetchStatus: 'Loading',  List: [] };    
                 return state;       
           })
           .addCase(fetchTimecards.fulfilled, (state, action) => {
                 console.log('Promise has been fulfilled !!!')  
                 
                 state = {...state, fetchStatus: 'Succeeded', List: action.payload };
                 console.log(`state`, state)  

                 return state;       
           })
           .addCase(fetchTimecards.rejected, (state, action) => {
                 state = {...state, fetchStatus: 'Rejected', List: [] };    
                 return state;       
           })
           
           
           // ===================================================================================================================
           
           .addCase(fetchProfile.pending, (state, action) => {
                 state = {...state, fetchProfileStatus: 'Idle',  List: [] };    
                 return state;       
            })
            .addCase(fetchProfile.fulfilled, (state, action) => {
                 console.log('Promise has been fulfilled !!!')  
                 console.log(`action.payload ${action.payload}`)
                 state = {...state, fetchProfileStatus: 'Succeeded', Profile: action.payload };
                 
                // alert(`state.Profile ${ JSON.stringify(state.Profile)}`)
                 return state;       
            })
            .addCase(fetchProfile.rejected, (state, action) => {
                 state = {...state, fetchProfileStatus: 'Rejected',  List: [] };    
                 return state;       
            })
      }      
            
   }
)


export const  timecardData = (state) => state.timecards.Profile;
export const  allTimecards = (state) => state.timecards.List;
export const  fetchStatus = (state) => state.timecards.fetchStatus;
export const  fetchProgress = (state) => state.timecards.fetchProgress;
export const  fetchProfileStatus = (state) => state.timecards.fetchProfileStatus;

export const { setTimecard, setCurrentID, addNewRecord, editRecord, deleteRecord, doFetch, doFetchProgress } = timecardsSlice.actions;
export default timecardsSlice.reducer;


