import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';

import swAlert from 'sweetalert2';
import moment from 'moment';


// @mui
import { Card, Stack, Paper, Avatar, Button, Popover, Checkbox, 
         TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Container, MenuItem, Typography, 
         IconButton
       } from '@mui/material';




// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';

// sections
import { ListHeaders, MasterListToolbar } from '../../sections/@dashboard/Hr';


import OTAuthorization from './OTAuthorization';
import { data, allOTAuthorizations, fetchOTAuthorizations, fetchStatus, doFetch, deleteRecord} from '../../features/tms/otAuthorizationsSlice';
import { userAuthData } from '../../features/users/authsSlice';




const styles = {
    button: {
              width: 30, 
              height: 30,
              padding: 3,
            },
    icon:   {
              width: 5, 
              height: 5,
            },
};



const TABLE_HEAD = [
                       { id: '',                 label: 'No.s',                  alignRight: false },
                       { id: 'OTcode',           label: 'OTcode',                alignRight: false },
                       { id: 'DateFrom',         label: 'Date From',             alignRight: false },
                       { id: 'DateTo',           label: 'Date To',               alignRight: false },
                       { id: 'TimeBegin',        label: 'Time Begin',            alignRight: false },
                       { id: 'TimeEnd',          label: 'Time End',              alignRight: false },
                       { id: 'OTHrsMax',         label: 'OTHrs Max',             alignRight: false },
                       { id: 'isBRKTimeSet',     label: 'is BRKTime (Set)',      alignRight: false },
                       { id: 'BrkTimeFrom',      label: 'BrkTime From',          alignRight: false },
                       { id: 'BrkTimeTo',        label: 'BrkTime To',            alignRight: false },
                       { id: 'Reason',           label: 'Reason',                alignRight: false },
                       { id: 'IsApprovedOT',     label: 'Is ApprovedOT?',        alignRight: false },
                       { id: 'Type',             label: 'Type',                  alignRight: false },

                       { id: ''},
                  ];
// ----------------------------------------------------------------------


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  
      // orig: return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
      
      return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

}

// (a, b) => descendingComparator(a, b, orderBy)

function applySortFilter(array, comparator, query) {
     
   // alert(query)

     const stabilizedThis = array.map((el, index) => [el, index] )
      
     stabilizedThis.sort((a, b) => {
     
          // jeff test: const order = a[0].key === 'ShiftID'? comparator(Number(a[0]), Number(b[0])) : comparator(a[0], b[0])
          const order = comparator(a[0], b[0])

          if (order !== 0) return order;
          return a[1] - b[1];
     });
     
     if (query) {
            return filter(array, (_shift) => _shift.shiftID.toLowerCase().indexOf(query.toLowerCase()) !== -1);
     }

    return stabilizedThis.map((el) => el[0]);

}

 
const OTAuthorizations = ( Props ) =>  {
   
      const dispatch = useDispatch();
      const hprogress = useRef();

      const dataProfile =  { ...data };
      const [Profile, setProfile] = useState( {Mode: '', dataProfile, } );


      const [open, setOpen] = useState({selected: null, id: ''}); // const [open, setOpen] = useState(null);

      const [page, setPage] = useState(0);

      const [order, setOrder] = useState('asc');

      const [selected, setSelected] = useState([]);

      const [orderBy, setOrderBy] = useState('OTcode');

      const [filterName, setFilterName] = useState('');

      const [rowsPerPage, setRowsPerPage] = useState(25);
     
      const [fetchProgressValue, setfetchProgressValue]  = useState (0);


       // alert (JSON.stringify(Props))


      useEffect ( () => {
          dispatch(doFetch())
      }, [dispatch, Profile])  

      const fStatus  = useSelector (fetchStatus);

    
      const {UserRecordLevelRights}  = useSelector (userAuthData);
    
    
      useEffect ( () => {
    
                  if (fStatus === 'Idle') {
                      dispatch (fetchOTAuthorizations( {UserRecordLevelRights, Type: Props.type, setfetchProgressValue} ) );
                  } 
      }, [fStatus, dispatch, UserRecordLevelRights])  // }, [fStatus, Status, dispatch]) 



      const List  =  useSelector (allOTAuthorizations); 


      const handleOpenMenu = (evt, ID) => setOpen({selected: evt.currentTarget, id: ID});
      const handleCloseMenu = () => setOpen({selected: null, id: ''});

      const handleRequestSort = (event, property) => {
            
            const isAsc = orderBy === property && order === 'asc';
            
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);

      };

      const handleSelectAllClick = (event) => {
         
            if (event.target.checked) {
                const newSelecteds = List.map((n) => n.ShiftCode);
                setSelected(newSelecteds);
                return;
            }

         setSelected([]);
         
      };

    
      const handleClick = (event, ID) => {
            
        
            const selectedIndex = selected.indexOf(ID);
           
            let newSelected = [];
      
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, ID);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            }
      
            setSelected(newSelected);
      
      };


      const handleChangePage = (event, newPage) => {
            setPage(newPage);
      };

      const handleChangeRowsPerPage = (event) => {
            setPage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
      };

      const handleFilterByName = (event) => {
        
            setPage(0);
            setFilterName(event.target.value);
            
            
            // alert(event.target.value)
      };


      

      const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - List.length) : 0;

      const filteredList =  applySortFilter(List, getComparator(order, orderBy), filterName);   

      const isNotFound = !filteredList.length && !!filterName; 
  
      

  return (
    

    <div style={{ width: '95%', height: '80vh', border: 0, overflow: 'hidden'  }} >


      {
          // Profile.Mode === 'AddNewRecord' &&  <EmpProfile data={Profile} setProfile = { (prev) => setProfile({...prev, Mode: '', dataProfile: {...data} } )  } /> 
          Profile.Mode === 'AddNewRecord' &&  <OTAuthorization UserRecordLevelRights={UserRecordLevelRights} Type={ Props.type } List={List} data={Profile} setData = { (prev) => setProfile({...prev, Mode: '', dataProfile: {...data} } )  } /> 
      }
      
      {
          // Profile.Mode === 'EditRecord' &&  <EmpProfile data={Profile} setProfile = { (prev) => setProfile({...prev, Mode: ''} )  } /> 
          Profile.Mode === 'EditRecord' &&  <OTAuthorization UserRecordLevelRights={UserRecordLevelRights} Type={ Props.type } List={List} data={Profile} setData = { (prev) => setProfile({...prev, Mode: ''} )  } /> 
          
      } 
      
      


      <Helmet>
         <title> O.T Authorizations </title>
      </Helmet>

      {/* <Container sx={{ minWidth: '95%', minHeight: '55%', overflow: 'hidden', border: 2, borderColor: 'red'}}  > */}
      {/* <Container sx={{ minWidth: '95%', minHeight: '25%', overflow: 'hidden', border: 1, borderColor: 'red'}}  > */}
      <Container sx={{ minWidth: '95%', height: '55vh', border: 0, borderColor: 'red'}}  >
      
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          
              <Typography variant="h4" gutterBottom>
                    O.T Authorizations { Props.type === 'std'? ' - Std' : ' - Advance (Early)' }
              </Typography>

              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} color='secondary' onClick={ () =>  setProfile( {Mode: 'AddNewRecord', dataProfile }) }>
                    New OT
              </Button>
          
        </Stack>

        {/* <Card sx={{ minWidth: '95%', minHeight: '10%' }}> */}
        <Card>

          
                <MasterListToolbar lsType="O.T Authorizations" list={selected} numSelected = {selected.length} filterName = {filterName} onFilterName = { handleFilterByName } />


                <Scrollbar>
                        
                        {/* <TableContainer component = {Paper} style={{ height: '55vh', maxBodyHeight: '600px',   }} sx={{ border: 0,  maxWidth: '100%', overflow: 'scroll', 'flex-grow': 0, }} > */}
                        <TableContainer component = {Paper} style={{ height: '55vh', maxBodyHeight: '600px',   }} sx={{ border: 0,  maxWidth: '100%', overflow: 'vscroll', }} >
                        {/* <TableContainer component = {Paper} style={{ height: '10%'  }} sx={{ border: 0,  maxWidth: '100%', maxBodyHeight: 80, }} > */}
                        

                            {/* <Table stickyHeader aria-label="sticky table" style={{ height: '10%', maxBodyHeight: 80,  }} sx={{ border: 0,  maxWidth: '100%',  }} > */}
                            {/* <Table stickyHeader aria-label="sticky table"  sx={{ maxWidth: '100%', maxHeight: '55px',  border: 2,  }} > */}
                            <Table stickyHeader aria-label="sticky table"  >
                               
                                <ListHeaders 
                                      order=             {order}
                                      orderBy=           {orderBy}
                                      headLabel=         {TABLE_HEAD}
                                      rowCount=          {List.length}
                                      numSelected=       {selected.length}
                                      onRequestSort=     {handleRequestSort}
                                      onSelectAllClick=  {handleSelectAllClick}
                                />
                                

                                <TableBody>
                                
                                  { 
                                        fStatus === 'Succeeded'?
  
                                        ( filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => {
                                        

                                                const { OTcode, DateFrom, DateTo, TimeBegin, TimeEnd, OTHrsMax,isBRKTimeSet,BrkTimeFrom,BrkTimeTo,Reason, IsApprovedOT, Type } = row;
                                                const selectedRecord = selected.indexOf(OTcode) !== -1;

                                                // alert(selected.indexOf(ShiftID))

                                                return (
                                                
                                                      <TableRow hover key={OTcode} tabIndex={-1} role="checkbox" selected={selectedRecord} style={{cursor: 'pointer'}} 
                                                                onDoubleClick  = { () =>  { 
                                                                                  setProfile( (prev) => ({...prev, Mode: 'EditRecord',  dataProfile: {...prev.dataProfile, OTcode}  }) );
                                                                        } }
                                                      >
                                                    
                                                          <TableCell padding="checkbox" size='small'>
                                                              <Checkbox checked={selectedRecord} onChange={(event) => handleClick(event, OTcode)} />
                                                          </TableCell>

                                                          <TableCell align="left"  size='small'  padding="none"> { idx+1 } </TableCell>


                                                          <TableCell component="th" scope="row" padding="none"  size='small' >
                                                              <Stack direction="row" alignItems="center" spacing={1}>
                                                                  {/* <Avatar alt={ID} src={avatarUrl} /> */}
                                                                  <Typography variant="subtitle2" noWrap>
                                                                       {OTcode}
                                                                  </Typography>
                                                              </Stack>
                                                          </TableCell>


                                                          <TableCell align="left"  size='small' padding="none"> {  moment(DateFrom).format('MM/DD/YYYY') } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> {  moment(DateTo).format('MM/DD/YYYY') } </TableCell>
                                                          
                                                          <TableCell align="left"  size='small' padding="none"> {  moment(TimeBegin).format('MM/DD/YYYY hh:mm A') } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> {  moment(TimeEnd).format('MM/DD/YYYY hh:mm A') } </TableCell>
                                                          
                                                          <TableCell align="left"  size='small' padding="none"> {  OTHrsMax} </TableCell>

                                                          <TableCell align="left" size='small' padding="none">
                                                              <Label color={ (isBRKTimeSet === 1 && 'success') || 'error'} > {sentenceCase( isBRKTimeSet === 1? 'Yes' : 'No')} </Label>
                                                          </TableCell> 
                                                          

                                                          <TableCell align="left"  size='small' padding="none"> { moment(BrkTimeFrom).format('MM/DD/YYYY hh:mm A') } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> {  moment(BrkTimeTo).format('MM/DD/YYYY hh:mm A') } </TableCell>

                                                          <TableCell align="left"  size='small' padding="none"> { Reason } </TableCell>

                                                          <TableCell align="left" size='small' padding="none">
                                                              <Label color={ (IsApprovedOT === 1 && 'success') || 'error'} > {sentenceCase( IsApprovedOT === 1? 'Yes' : 'No')} </Label>
                                                          </TableCell>

                                                          
                                                          <TableCell align="left"  size='small' padding="none"> {  Type } </TableCell> 
                                                          

                                                          {/* <TableCell align="left"  size='small' padding="none"> { SStartMon } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStopMon } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStartTue } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStopTue } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStartWed } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStopWed } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStartThu } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStopThu } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStartFri } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStopFri } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStartSat } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStopSat } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStartSun } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { SStopSun } </TableCell> */}


                                                          {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                                                          
                                                          <TableCell align="right" size='small' padding="none">
                                                                  <IconButton style={styles.button } iconStyle={styles.icon} color="inherit" onClick = { (evt) => handleOpenMenu (evt, OTcode) }>                                                                      
                                                                      <Iconify icon={'eva:more-vertical-fill'}  />
                                                                  </IconButton>
                                                              </TableCell>
                                                    
                                                    </TableRow>

                                                ); // return (
                                       }) // filteredList.slice(page

                                    ):

                                    fStatus === 'Loading'? <div>  <h1 id='hprogress' ref={hprogress}> {`Waiting ... ${fetchProgressValue}%`} </h1>  <progress value={ fetchProgressValue } max="100" >  </progress>  </div> : <h1> {`Error has been encountered !!! ${fStatus}` } </h1> 


                                  }
                                  
                                  {
                                    emptyRows > 0 && (
                                      <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={12} />
                                      </TableRow>
                                      )
                                  }
                                
                                </TableBody>


                                { isNotFound && (
                                
                                      <TableBody>
                                            
                                          <TableRow>
                                            
                                              <TableCell align="center" colSpan={24} sx={{ py: 3 }}>
                                                    <Paper
                                                      sx={{
                                                        textAlign: 'center',
                                                      }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found !!!
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{filterName}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                              </TableCell>
                                            
                                          </TableRow>

                                      </TableBody>

                                )}

                          </Table>

                        </TableContainer>

                </Scrollbar>

              <TablePagination
                  rowsPerPageOptions = {[5, 10, 25, 50, 75, 100]}
                  component=           "div"
                  count=               {List.length}
                  rowsPerPage=         {rowsPerPage}
                  page=                {page}
                  onPageChange=        {handleChangePage}
                  onRowsPerPageChange= {handleChangeRowsPerPage}
              />

        </Card>

      </Container>

      <Popover
          open =            {Boolean(open.selected)}
          anchorEl =        {open.selected}
          onClose =         {handleCloseMenu}
          anchorOrigin =    {{ vertical: 'top', horizontal: 'left' }}
          transformOrigin = {{ vertical: 'top', horizontal: 'right' }}
          PaperProps = {{
                          sx: {
                            p: 1,
                            width: 140,
                            '& .MuiMenuItem-root': {
                              px: 1,
                              typography: 'body2',
                              borderRadius: 0.75,
                            },
                          },
                      }}
      >
            {/* Mode: 'AddNewRecord' */}
            
            { // alternate code:
            /* <MenuItem  onClick={ () =>  (function (){ 
                                          setProfile( {Mode: 'EditRecord', Id: open.id} );
                                          setOpen ({selected: null, id: ''})
                                        })() */}

            <MenuItem  onClick={ () =>  { 
                                          // setProfile( (prev) => ({...prev, Mode: 'EditRecord',  Id: open.id}) );
                                          setProfile( (prev) => ({...prev, Mode: 'EditRecord',  dataProfile: {...prev.dataProfile, OTcode: open.id}  }) );
                                          
                                          setOpen ({selected: null, id: ''});
                                        }
                               } 
            >
                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} /> { `Edit ${open.id}` }
            </MenuItem>

            <MenuItem sx={{ color: 'error.main' }}  
                      onClick={ () => { 
                                    // setProfile( (prev) => ({...prev, Mode: 'DeleteRecord',  dataProfile: {...prev.dataProfile, EmployeeID: open.id}  }) ) 
                                    
                                    // Ref: https://bobbyhadz.com/blog/javascript-edit-alert-title#:~:text=It%20is%20not%20possible%20to,module%20using%20NPM%20or%20YARN.

                                    if (selected.length === 0) {
                                      // alert ("Record is not yet selected!, Just select the record and try again", "Record is not yet selected")
                                      swAlert.fire({
                                        title: 'Record Status',
                                        text: 'Record is not yet selected! Just select the record and try again',
                                        icon: 'info',
                                        confirmButtonText: 'OK',
                                      });
                                   }  
                                    
                                    // if (selected.length > 0 && window.confirm(`Are you sure you want to delete this record?`, `Delete Record (${selected.length})`) === true ) {
                                    //     dispatch (deleteRecord(selected) )
                                    // }

                                    if (selected.length > 0 ) {
                                    
                                              swAlert.fire({
                                                            title: 'Are you sure?',
                                                            text: "You won't be able to revert this!",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: `Yes, delete (${selected.length}) it!`
                                                            })
                                                       .then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                dispatch (deleteRecord(selected) )    
                                                                                swAlert.fire(
                                                                                              'Deleted!',
                                                                                              `Record${selected.length>1? 's':''} has been deleted.`,
                                                                                              'success'
                                                                                )
                                                                            }
                                                       })

                                    }
                        }} 
            >

                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} /> { `Delete ${open.id}` }
            </MenuItem>

      </Popover>
 
   

    </div>
  );
}










export default OTAuthorizations;


