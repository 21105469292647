
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';

import Axios from 'axios';
import moment from 'moment';


// @mui
import { Card, Stack, Paper, Avatar, Button, Popover, Checkbox, 
         TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Container, MenuItem, Typography, 
         IconButton
       } from '@mui/material';




// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';

// sections
import { ListHeaders, MasterListToolbar } from '../../sections/@dashboard/Hr';


import Profile from './Department';


const styles = {
    button: {
              width: 30, 
              height: 30,
              padding: 3,
            },
    icon:   {
              width: 5, 
              height: 5,
            },
};



const TABLE_HEAD = [
                       { id: '',                  label: 'No.s',                 alignRight: true },
                       { id: 'ID',                label: 'ID',                   alignRight: false },
                       { id: 'DecDayName',        label: 'Events',               alignRight: false },
                       { id: 'DecDayDfrom',       label: 'Date From',            alignRight: false },
                       { id: 'DecDayDto',         label: 'Date To',              alignRight: false },
                       { id: 'isApplyLateRules',  label: 'is Apply LateRules?',  alignRight: false },
                       { id: 'isApplyUtRules',    label: 'is Apply UtRules?',    alignRight: false },
                       { id: 'isPaid',            label: 'is Paid?',             alignRight: false },
                       { id: 'Remarks',           label: 'Remarks',              alignRight: false },
                       { id: ''},
                  ];
// ----------------------------------------------------------------------


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  
      return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

}


function applySortFilter(array, comparator, query) {
  
     const stabilizedThis = array.map((el, index) => [el, index]);
  
     stabilizedThis.sort((a, b) => {
                 const order = comparator(a[0], b[0]);
                 if (order !== 0) return order;
                     return a[1] - b[1];
            });
     if (query) {
             return filter(array, (_user) => _user.Fullname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
     }

     return stabilizedThis.map((el) => el[0]);

}

 
const Events = () =>  {
   
      const [List, setList] = useState([]);
      const [newProfile, setNewProfile] = useState( {IsAddNewProfile: false, Mode: 'Edit', } );


      const [open, setOpen] = useState(null);

      const [page, setPage] = useState(0);

      const [order, setOrder] = useState('asc');

      const [selected, setSelected] = useState([]);

      const [orderBy, setOrderBy] = useState('ID');

      const [filterName, setFilterName] = useState('');

      const [rowsPerPage, setRowsPerPage] = useState(25);
     
      
      Axios.defaults.withCredentials = false;  // very important on the client side!!!  

      useEffect ( () => {
                 
                   Axios.get(`/api/events`)
                          .then ((res) =>  {
                                setList(res.data)
                                console.log (`res.data:  ${res.data}`) 
                            })
                          .catch( (err) => {
                                setList([])  
                                console.log (err);
                            });
                      
        }, []) 
      
     


      const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
      };

      const handleCloseMenu = () => {
            setOpen(null);
      };

      const handleRequestSort = (event, property) => {
            
            const isAsc = orderBy === property && order === 'asc';
            
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);

      };

      const handleSelectAllClick = (event) => {
         
            if (event.target.checked) {
                const newSelecteds = List.map((n) => n.DeptID);
                setSelected(newSelecteds);
                return;
            }

         setSelected([]);
         
      };


      const handleClick = (event, ID) => {
            
            const selectedIndex = selected.indexOf(ID);
           
            let newSelected = [];
      
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, ID);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            }
      
            setSelected(newSelected);
      
      };


      const handleChangePage = (event, newPage) => {
            setPage(newPage);
      };

      const handleChangeRowsPerPage = (event) => {
            setPage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
      };

      const handleFilterByName = (event) => {
            setPage(0);
            setFilterName(event.target.value);
            // alert(event.target.value)
      };


      function openProfile () {

       return   <Profile data={ {newProfile: true, Mode: 'New'} } />  
      // return alert('dfdf');
          
      }

      
      const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - List.length) : 0;

      const filteredList =  applySortFilter(List, getComparator(order, orderBy), filterName);   

      const isNotFound = !filteredList.length && !!filterName; 
  
      function setNewProfileX () {
          setNewProfile( (prev) => ( {...prev, IsAddNewProfile: false}) ) 
         // alert('111')
      }
      

  return (
    
    <div style={{ width: '95%', height: '80vh', border: 0, overflow: 'hidden'  }} >


      {
         
          newProfile.IsAddNewProfile? 
            
                <Profile data={newProfile} setNewProfile = { () => setNewProfileX()   } /> 
            
          : false
         
      }
        
      
      <Helmet>
         <title> Events </title>
      </Helmet>

      {/* <Container sx={{ minWidth: '95%', minHeight: '55%', overflow: 'hidden', border: 2, borderColor: 'red'}}  > */}
      {/* <Container sx={{ minWidth: '95%', minHeight: '25%', overflow: 'hidden', border: 1, borderColor: 'red'}}  > */}
      <Container sx={{ minWidth: '95%', height: '55vh', border: 0, borderColor: 'red'}}  >
      
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          
              <Typography variant="h4" gutterBottom>
                  Events
              </Typography>

              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} color='secondary' onClick={ () =>  setNewProfile({IsAddNewProfile: !newProfile.IsAddNewProfile, Mode: 'New' })  }>
              {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} color='secondary' onClick={ () =>  setNewProfile({IsAddNewProfile: true, Mode: 'New'}) }> */}
              {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} color='secondary' onClick={ () => openProfile() }> */}
                   New Event
              </Button>
          
        </Stack>

        {/* <Card sx={{ minWidth: '95%', minHeight: '10%' }}> */}
        <Card>

          
                <MasterListToolbar lsType="Events" MasterListToolbar list={selected} numSelected = {selected.length} filterName = {filterName} onFilterName = { handleFilterByName } />
                

                <Scrollbar>
                        
                        {/* <TableContainer component = {Paper} style={{ height: '55vh', maxBodyHeight: '600px',   }} sx={{ border: 0,  maxWidth: '100%', overflow: 'scroll', 'flex-grow': 0, }} > */}
                        <TableContainer component = {Paper} style={{ height: '55vh', maxBodyHeight: '600px',   }} sx={{ border: 0,  maxWidth: '100%', overflow: 'vscroll', }} >
                        {/* <TableContainer component = {Paper} style={{ height: '10%'  }} sx={{ border: 0,  maxWidth: '100%', maxBodyHeight: 80, }} > */}
                        

                            {/* <Table stickyHeader aria-label="sticky table" style={{ height: '10%', maxBodyHeight: 80,  }} sx={{ border: 0,  maxWidth: '100%',  }} > */}
                            {/* <Table stickyHeader aria-label="sticky table"  sx={{ maxWidth: '100%', maxHeight: '55px',  border: 2,  }} > */}
                            <Table stickyHeader aria-label="sticky table"  >
                               
                                <ListHeaders 
                                      order=             {order}
                                      orderBy=           {orderBy}
                                      headLabel=         {TABLE_HEAD}
                                      rowCount=          {List.length}
                                      numSelected=       {selected.length}
                                      onRequestSort=     {handleRequestSort}
                                      onSelectAllClick=  {handleSelectAllClick}
                                />
                                

                                <TableBody>
                                
                                  { 
                                      
                                        filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => {
                                        

                                                const {ID, DecDayName, DecDayDfrom, DecDayDto,isApplyLateRules,isApplyUtRules,isPaid,Remarks } = row;
                                                const selectedRecord = selected.indexOf(ID) !== -1;



                                                return (
                                                
                                                      <TableRow hover key={ID} tabIndex={-1} role="checkbox" selected={selectedRecord}  >
                                                    
                                                          <TableCell padding="checkbox" size='small'>
                                                              <Checkbox checked={selectedRecord} onChange={(event) => handleClick(event, ID)} />
                                                          </TableCell>

                                                          <TableCell align="left"  size='small'  padding="none"> { idx+1 } </TableCell>


                                                          <TableCell component="th" scope="row" padding="none"  size='small' >
                                                              <Stack direction="row" alignItems="center" spacing={1}>
                                                                  {/* <Avatar alt={ID} src={avatarUrl} /> */}
                                                                  <Typography variant="subtitle2" noWrap>
                                                                       {ID}
                                                                  </Typography>
                                                              </Stack>
                                                          </TableCell>

                                                          <TableCell align="left"  size='small' padding="none"> { DecDayName } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { moment(DecDayDfrom).format('MM/DD/YYYY') } </TableCell>
                                                          <TableCell align="left"  size='small' padding="none"> { moment(DecDayDto).format('MM/DD/YYYY') } </TableCell>
                                                          
                                                          <TableCell align="left" size='small' padding="none">
                                                              <Label color={ (isApplyLateRules === 1 && 'success') || 'error'} > {sentenceCase( isApplyLateRules === 1? 'Yes' : 'No')} </Label>
                                                          </TableCell>    
                                                          <TableCell align="left" size='small' padding="none">
                                                              <Label color={ (isApplyUtRules === 1 && 'success') || 'error'} > {sentenceCase( isApplyUtRules === 1? 'Yes' : 'No')} </Label>
                                                          </TableCell>    

                                                          <TableCell align="left" size='small' padding="none">
                                                              <Label color={ (isPaid === 1 && 'success') || 'error'} > {sentenceCase( isPaid === 1? 'Yes' : 'No')} </Label>
                                                          </TableCell>  

                                                          <TableCell align="left"  size='small' padding="none"> { Remarks } </TableCell>  


                                                          {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                                                          
                                                          <TableCell align="right" size='small' padding="none">
                                                              <IconButton  style={styles.button } iconStyle={styles.icon} color="inherit" onClick={handleOpenMenu}>
                                                                  <Iconify icon={'eva:more-vertical-fill'}  />
                                                              </IconButton>
                                                          </TableCell>
                                                    
                                                    </TableRow>

                                                ); // return (
                                     }) // filteredList.slice(page
                                  }
                                  
                                  {
                                    emptyRows > 0 && (
                                      <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={12} />
                                      </TableRow>
                                      )
                                  }
                                
                                </TableBody>


                                { isNotFound && (
                                
                                      <TableBody>
                                            
                                          <TableRow>
                                            
                                              <TableCell align="center" colSpan={24} sx={{ py: 3 }}>
                                                    <Paper
                                                      sx={{
                                                        textAlign: 'center',
                                                      }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found !!!
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{filterName}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                              </TableCell>
                                            
                                          </TableRow>

                                      </TableBody>

                                )}

                          </Table>

                        </TableContainer>

                </Scrollbar>

              <TablePagination
                  rowsPerPageOptions = {[5, 10, 25, 50, 75, 100]}
                  component=           "div"
                  count=               {List.length}
                  rowsPerPage=         {rowsPerPage}
                  page=                {page}
                  onPageChange=        {handleChangePage}
                  onRowsPerPageChange= {handleChangeRowsPerPage}
              />

        </Card>

      </Container>

      <Popover
        open =            {Boolean(open)}
        anchorEl =        {open}
        onClose =         {handleCloseMenu}
        anchorOrigin =    {{ vertical: 'top', horizontal: 'left' }}
        transformOrigin = {{ vertical: 'top', horizontal: 'right' }}
        PaperProps = {{
                        sx: {
                          p: 1,
                          width: 140,
                          '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                          },
                        },
                     }}
      >
          <MenuItem>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Edit
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
          </MenuItem>
      



      </Popover>
 
   

    </div>
  );
}










export default Events;


