
import * as React from 'react';
import dayjs from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePicker(props) {
  
const {name, label, value, isDisabled, onChange}  = props;
 // const [valDate, setValDate] = React.useState(dayjs(value));
  
 const convertToDefaultParam = (name, value) => {
     const evt =   { 
                      target:  { name, value },
                   }
     return evt;           
}

      

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/*  <DemoContainer components={['DatePicker', 'DatePicker']}> */}
        <MUIDatePicker
             name     =  { name }
             label    =  { label }
             inputFormat="MM/DD/YYYY"
             value    =  { dayjs(value) }
             onChange =  { (newValue) => onChange( convertToDefaultParam(name, newValue) ) }
             sx       =  { {
                              width: "100%",
                              height: "20px",
                              padding: "0px" ,
                           }
                         }
                         slotProps={{ textField: { size: 'small' } }}             
              //  { isDisabled && "disabled" }  
        />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  );
}




// // // import React, {useState} from 'react';
// // // import moment from 'moment';


// // // import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// // // import dayjs from 'dayjs';
// // // import { LocalizationProvider, DatePicker  as MUIDatePicker} from '@mui/x-date-pickers';

// // // import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

// // // // import { TextField, Stack } from '@mui/material';
// // // // import { LocalizationProvider, DatePicker as MUIDatePicker} from '@mui/x-date-pickers';

// // // // import  AdapterDateFns from '@mui/lab/AdapterDateFns'; 






// // // export default function DatePicker (props) {
      
// // //       const {name, label, value, onChange}  = props;

// // //       const convertToDefaultParam = (name, value) => (
// // //             { 
// // //                target:  { name, value },
// // //             }
// // //       )

// // //      //  alert (`convertToDefaultParam ${ moment(convertToDefaultParam(name, value).target.value).format('MM/DD/YYYY')} `)
      
// // //      // alert (`moment(value).format('MM/DD/YYYY') ${moment(value).format('MM/DD/YYYY')}`)

// // //       // return (
// // //       //      <LocalizationProvider dateAdapter={AdapterDayjs}>
// // //       //                   < MUIDatePicker
// // //       //                         name     =  {name}
// // //       //                         label    =  {label}
// // //       //                         defaultValue= { dayjs( moment(value).format('MM/DD/YYYY')) }
// // //       //                         value={ dayjs( moment(value).format('MM/DD/YYYY'))}
// // //       //                         // date= { dayjs( moment(value).format('MM/DD/YYYY')) }
// // //       //                         // defaultValue={ dayjs(new Date())}
// // //       //                         // date     =  { moment(value).format('YYYY-MM-DD') }
// // //       //                         // onChange= {e => onChange ( convertToDefaultParam(name, moment(e.target.date).format('MM/DD/YYYY'))) }
// // //       //                          // onChange  = {evt => onChange (convertToDefaultParam(name, evt.target.date)) }
// // //       //                        // onChange  = {onChange}
// // //       //                        //  onChange = { evt => onChange( convertToDefaultParam(name, evt.target.date) ) }  
// // //       //                         // onChange = { (evt) => onChange() }  
// // //       //                         onChange = { evt =>  console.log (evt) }  
                            
// // //       //                   />
// // //       //       </LocalizationProvider>
            
            
// // //       // )

// // // //      import * as React from 'react';
// // // // import dayjs from 'dayjs';

// // // // import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// // // // import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// // // // import { DatePicker } from '@mui/x-date-pickers/DatePicker';


// // //  const [val, setVal] = useState( moment(value).format('MM/DD/YYYY') );

// // //   return (
// // //             <LocalizationProvider dateAdapter={AdapterDayjs}>
                        
// // //                   {/* <DemoContainer components = {['DatePicker', 'DatePicker']}> */}
// // //                         <MUIDatePicker
// // //                               name  = {name}
// // //                               label = {label}
// // //                               value = { dayjs(moment(value).format('MM/DD/YYYY')) }
// // //                               // onChange = { (evt) => onChange (convertToDefaultParam(name, dayjs(evt))) }
// // //                               onChange = { (date) => console.log (date.date.toString)}

// // //                         />
// // //                   {/* </DemoContainer> */}

// // //             </LocalizationProvider>
// // //          );
// // // }
     
 


