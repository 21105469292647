
import React, { useState, useRef, useEffect }  from 'react';
// import Axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';


import { Checkbox, Grid, TextField, FormControlLabel, Paper, Button, Avatar, startAdornment, InputAdornment,  } from '@mui/material';

// https://fonts.google.com/icons?selected=Material+Symbols+Outlined:key:FILL@0;wght@400;GRAD@0;opsz@24&icon.query=key
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import Password from '@mui/icons-material/Password';

import styled from '@emotion/styled'


// routes
import Router from './routes';

// theme
import ThemeProvider from './theme';



// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

import { authenticateUser,fetchUserPrivileges, fetchUserRecordLevelRights, validateUser, getValidate, userAuthData } from './features/users/authsSlice';
// import backgroundImage from  './assets/background.jpg';
import backgroundImage from  './assets/vect_modern-technology.jpg';
import headerImage from  './assets/vtbg.jpg';
// import RegisterForm from './pages/UserPages/UserRegister';



// function showRegisterForm () {
    

//     return <RegisterForm />;

// }
const H1 = styled.h1(
      {
        fontSize: 55,
      },
      props => ({ color: props.color })
)    

const H2 = styled.h1(
      {
        fontSize: 45,
      },
      props => ({ color: props.color })
)

const H3 = styled.h1(
      {
        fontSize: 40,
      },
      props => ({ color: props.color })
)

const styles1 = {formControlLabel:{
      color:'#E05408',
      // border: '1px solid white',
      // span: {
      //   color: '#d7dae0', 
      //   '&:hover':{border: '1px solid red'},
      // }
    },
}

const styles2 = {formControlLabel:{
      color:'#0D47A1',
      // border: '1px solid white',
      // span: {
      //   color: '#d7dae0', 
      //   '&:hover':{border: '1px solid red'},
      // }
    },
}



const Login = () => {
      
      const userRef = useRef();
      const errRef = useRef();

      const [User, setUser] = useState('');
      const [Pwd, setPwd] = useState('');
      const [errMsg, setErrMsg] = useState('');
      // const [success, setSuccess] = useState('');

      const [KeepMeLogIn, setKeepMeLogIn] = useState(false);
      
      const [AccessType, setAccessType] = useState("ADMIN");
      const [AccessCode, setAccessCode] = useState("");
      const [fetchProgressValue, setfetchProgressValue]  = useState (0);


      useEffect(() => {
            userRef.current.focus();
      }, []); 

      useEffect(() => {
            setErrMsg(''); 
      }, [User, Pwd])
     

      useEffect(() => {

            const handleEsc = (event) => {
                  
                  if (event.key === 'Escape') {
                        setAccessCode("")
                        setUser("")
                        setPwd("")
                        setErrMsg("")

                        // document.getElementsByName('AccessCode').value = ""
                        // document.getElementsByName('Username').value = ""
                        // document.getElementsByName('Password').value = ""

                  };
                  
            };

            const handleEnterButton = (event) => {

                  if (event.keyCode === 13) {
                       // Note that this doesn't honour tab-indexes

                        event.preventDefault();

                        // Isolate the node that we're after
                        const currentNode = event.target;

                        // find all tab-able elements: 'audio, button, canvas, details, iframe, input, select, summary, textarea, video, [accesskey], [contenteditable], [href], [tabindex]'
                        const allElements = document.querySelectorAll('audio, canvas, iframe, input, button, a, area, object, select, details, summary, textarea, a[href], [accesskey], [contenteditable], [tabindex]');

                        // Find the current tab index.
                        const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el))

                        // focus the following element
                        const targetIndex = (currentIndex + 1) % allElements.length;
                        allElements[targetIndex].focus();  
                 }
           }

            document.addEventListener('keyup',  handleEsc, );
            document.addEventListener('keydown',  handleEnterButton, );
            

            return () => {
               document.removeEventListener('keyup', handleEsc);
               document.removeEventListener('keydown', handleEnterButton);
            };

      }, []);

     
      const tmpAuthData = useSelector (userAuthData) ;

      const [authData, setAuthData] = useState({isValidate: false, UsrCode: '', GrpCode: ''}); // useSelector (userAuthData) ;

      const dispatch = useDispatch();

      useEffect ( () => {

            setAuthData(tmpAuthData);

      }, [tmpAuthData, dispatch])
  
      const [isValidatePass, setValidatePass] = useState(false);
      const [checked, setChecked] = useState(true);
      
      

      const OnValidatePass  = () => {
            
           // Axios.defaults.withCredentials = true;  // very important on the CLOUD DEPLOYMENT side!!!
           // Axios.post('http://localhost:8800/api/users',
           
           // Axios.post(`${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/api/users`, 
           // Axios.post(`https://www.lockheedhr.com/api/users`,
           
           // Axios.post(`${process.env.PUBLIC_URL}/api/users`, 

           // ${process.env.PUBLIC_URL}:${process.env.REACT_APP_PORT}
           // Axios.post(`${process.env.PUBLIC_URL}/api/users`,       
           
           //  alert(`login validatepass: ${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}`)
           // :${process.env.REACT_APP_PORT}
           
           // Axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/users`, 
           

          //  alert(`login validatepass: with "Access-Control-Allow-Origin":  "https://www.lockheedhr.com (*)"  ${process.env.REACT_APP_PUBLIC_URL}`)
           
      //      Axios({
      //       url: `${process.env.REACT_APP_PUBLIC_URL}/api/employees/:status=All`,
      //       method: 'get',
      //       headers: {
      //           'Content-Type': 'application/json'
      //       }
      //       })
      //       .then(response => {
      //             alert(response)
      //       }) 
      //       .catch(err => {
      //             alert(err);
      //       });


      // { 
      //       "Access-Control-Allow-Origin":        "https://www.lockheedhr.com" ,
      //       "Access-Control-Allow-Headers":       "Host, Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-XSRF-TOKEN, Origin, Access-Control-Request-Origin, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin, access-control-allow-origin, Access-Control-Allow-Credentials, access-control-allow-credentials, Access-Control-Allow-Headers, access-control-allow-headers, Access-Control-Allow-Methods, access-control-allow-methods",
      //       "Access-Control-Allow-Credentials":   "true",
      //       "Access-Control-Allow-Methods":       "GET,HEAD,OPTIONS,POST,PUT"
      //   }

           // alert ('Employees Masterlist ...')

      //      Axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/employees/:status=All`
           
           
      //                  , { headers: 
      //                       { 
      //                           "Access-Control-Allow-Origin":        "*" ,
      //                           "Content-Type": "json/application"
      //                       } 
      //                    }
      //      )
      //      .then ((res) =>  {
      //            // setList(res.data)
      //             alert (`res.data: Employees-all  ${res.data}`) 
      //        })
      //      .catch( (err) => {
      //            // setList([])   
      //              alert(err)
      //        });

 
 





      // Axios.defaults.withCredentials = true;


        // ==========================================================================
           // Axios.post(`${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/api/users`,
           // Axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/users`,

              //  Axios.post(`/api/users`,
              // Axios.post(`${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/api/users`,
             // Axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/users`, {Username: User, Password: Pwd, isValidated: false})

      
      // 0301-2024: Hold for now 
      // ============================================================================================================================================================
            //  Axios.post(`/api/users`, {Username: User, Password: Pwd, isValidate: false})
            //      .then ((res) =>  {
                     
            //        //  alert(`after dispatch -- isValidated: res.data  ${res.data}`)         
            //        //  alert(`after dispatch -- isValidated: res.data.isValidated  ${res.data.isValidated}`)      

            //          dispatch( validateUser( {Username: User, Password: Pwd, isValidate: res.data.isValidate} ) );
                        
            //       })
            //      .catch (err => alert(`login error > ${err}`)); 
      // ============================================================================================================================================================      
           
           // GRP01 === ADMIN : GRP05 === COOORDINATOR
           dispatch ( authenticateUser ( {Username: User, Password: Pwd, isAdminAccess: AccessType === 'ADMIN',  isValidate: false}  ) )      
         
           
            
            // dispatch( validateUser( {Username: User, Password: Pwd, isValidate: false}));
            
            //   ( async () => {
            //       await  dispatch( validateUser( {Username: User, Password: Pwd, isValidate: false}));
            //    })();

            // useEffect ( () => {
            //             if (isValidate === 'idle') {
            //                   dispatch( getValidate( {Username: User, Password: Pwd, isValidate: false}) )
            //              }  
            // })


            
            // alert (`login main form, isValidate: ${isValidate}`)
            

            // if (isValidate === true ) {
            if (authData.isValidate === true ) {
                             
                        // dispatch ( fetchUserPrivileges({GrpCode: authData.GrpCode, setfetchProgressValue}) ) ;     
                        // dispatch ( fetchUserRecordLevelRights(USRCode) ) ;     

                        setUser('');
                        setPwd('');
                        setValidatePass(true);
                        
                        
            } else {
                        // alert('not valid')
                        setValidatePass(false);
                        setErrMsg('Invalid User!');
                         
                         
            }

      }


      const handleChange = (event) => {
            setChecked(event.target.checked);
      };
   

      
      useEffect ( () => {
            
            if (authData.isValidate) {
               dispatch ( fetchUserPrivileges({GrpCode: authData.GrpCode, setfetchProgressValue}) ) ; 
               dispatch ( fetchUserRecordLevelRights({UsrCode: authData.UsrCode, setfetchProgressValue}) ) ; 
            }   

      }, [authData.isValidate, dispatch])




      return (
                  // !isValidate ? 

                  
                  // !isValidate ? 
                  //   Boolean(isValidatePass) === false ?
                     Boolean(authData.isValidate) === false ?

                       (       // sx={{border: 1, marginTop: -5, marginBottom: 0, marginLeft: -1, marginRight: 0, }}  >
                           
                        
                             <Grid  direction="row"  item xs={12} alignItems="center"  style={{ minHeight: "100vh", minWidth: "99vw",flex: 11, 'scrollbar': 'diplay:none', 'overflow-y': 'hidden', 'overflow-x': 'hidden',  
                                                                                                backgroundImage: `url(${backgroundImage})`,
                                                                                                backgroundSize: "cover",
                                                                                                // color: "#f5f5f5"
                                                                                                color: "#00000"
                                                                                               }} 
                                                                                       sx={{border: 0, marginTop: -1, marginBottom: -2, marginLeft: -1, marginRight: -1, }}  >

    
                                    <Grid container direction="row"  item xs={12} alignItems="center" justifyContent="center" 
                                                                     sx={{ border: 0, borderBottom: 10, marginTop: 0, marginBottom: 5, bgcolor: 'darkblue', borderBottomColor: 'blue', padding: 0 }}  
                                                                     style={{ minHeight: "10vh", minWidth: "99vw",flex: 11, 'scrollbar': 'diplay:none', 'overflow-y': 'hidden', 'overflow-x': 'hidden',  
                                                                                                backgroundImage: `url(${headerImage})`,
                                                                                                backgroundSize: "cover",
                                                                                                // color: "#f5f5f5"
                                                                                                color: "#00000"
                                                                                               }} 
                                    >
                                                <Grid container direction="column" item xs={6} alignItems="center" justifyContent="center" sx={{ border: 0,  minWidth: "100%"}}  > 
                                                      <H1><font color='blue'><b> ARCHWAY INTERNATIONAL AND MARKETING SERVICES INC.  </b></font></H1>
                                                      
                                                      {/* <h1> {format(new Date(), 'MMMM dd, yyyy pp')  }  </h1> */}
                                                      
                                                      {/* <Grid container direction="column" item xs={6} alignItems="center" justifyContent="center" sx={{ border: 0,  minWidth: "100%", marginTop: -5}}  > 
                                                         <h1><font color='red'><b> JTI Permanent Fixture & Mty Paint It </b></font></h1>
                                                      </Grid> */}
                                                </Grid>
                                    </Grid>

                                    <Grid container direction="row" item xs={12} alignItems="center" justifyContent="center"  padding-top padding-bottom sx={{border: 0, borderBottom: 0, marginBottom: 7}}   >
                                                {/* <Grid container direction="column" item xs={.1} alignItems="right" justify="space-between"  padding-top padding-bottom border={0} marginRight={1}> 
                                                    <Avatar sx={{  width: 45, height: 45, bgcolor: 'darkblue',  border: '1.1px solid darkblue', borderLeftStyle: 'dotted' }} > <font size='4'color='cyan'><b> Hr24 </b></font></Avatar>
                                                </Grid> */}
                                                <Grid container direction="column" item xs={8} alignItems="center" justify="space-between"  padding-top padding-bottom border={0} > 
                                                     <H3 color='white'> Integrated Human Resources And Payroll Management System </H3>        
                                                </Grid>
                                    </Grid>
                              
                                   
                                    
                                    <Grid container direction="row" item xs={12} alignItems="center" justifyContent="center" padding-top padding-bottom border={0} >     
                                    {/* 'background-color': '#0D5B98', */}
                                    {/* background: 'linear-gradient(to right bottom, #430089, #FFF176)' , */}
                                          
                                          {/* <Paper   sx={{ width: 300, height: AccessType === "===hold==dont run this code COORDINATOR==hold" ? 510: 430,  */}
                                          <Paper   sx={{ width: 300, height: Boolean(isValidatePass) ===  false ? 490: 460, 
                                                                             background: 'linear-gradient(to right bottom, #430089, #FFF176)' ,
                                                                                   color: '#FFC107',
                                                                                   opacity: .9}} 
                                                   variant = 'elevation' elevation={10} >
                                                
                                                <Grid container spacing={2} direction={'column'} alignItems={'center'} justify={'center'} border={0}>

                                                      <br/>
                                                      <Grid item xs={12} marginBottom={0} >
                                                             <h1> <font color="white"> Log In </font> </h1>
                                                            { 
                                                                Boolean(isValidatePass) ===  false? <p ref = {errRef} aria-live='assertive'> <b><font size={3.5} color='red'> {errMsg} </font> </b> </p> : null
                                                            }
                                                      </Grid> 
                                                      
                                                      {
                                                       
                                                         AccessType === "===hold==dont run this code COORDINATOR==hold" &&

                                                                  <Grid item xs={12}>
                                                                        {/* <div> Enter Password </div> */}
                                                                        <div>
                                                                              <TextField 
                                                                                    name = 'AccessCode'
                                                                                    label = "Access Code" 
                                                                                    type = 'password' 
                                                                                    size="md" 
                                                                                    InputLabelProps={{
                                                                                          style : {color: '#FAF2AB', fontSize: 12, fontWeight: 1000, },
                                                                                    
                                                                                    }}
                                                                                    InputProps={{
                                                                                          style : {color: 'cyan', fontSize: 12, fontWeight: 1000,  height: 60, },
                                                                                          width: 500,
                                                                                          maxWidth: '100%',
                                                                                          startAdornment: (
                                                                                                <InputAdornment position="start">
                                                                                                    <Password />
                                                                                                </InputAdornment>
                                                                                          )
                                                                                    }}
                                                                                    value = {AccessCode}
                                                                                    required
                                                                                    onChange = {(e) => setAccessCode(e.target.value)}
                                                                                    variant = "filled"
                                                                              />
                                                                        </div>

                                                                  </Grid>
                                                            
                                                            
                                                      }
                                                      
                                                      {/* sx= { {width: 300, 
                                                                  "& .MuiInputBase-root": {height: 30,      },
                                                                  mb: 2 
                                                               }} */}
                                                      
                                                      
                                                      <Grid item xs={12}>
                                                            {/* <div> Enter Username </div> */}
                                                            <div>
                                                                  <TextField 
                                                                        name  = 'Username'
                                                                        ref = {userRef}   
                                                                        autoComplete = 'off'
                                                                        label = "Enter Username"
                                                                        size="md" 
                                                                        InputLabelProps={{
                                                                              style : {color: '#FAF2AB', fontSize: 12, fontWeight: 1000}
                                                                        }}
                                                                        InputProps={{
                                                                              style : {color: 'cyan', fontSize: 12, fontWeight: 1000, height: 60, },
                                                                              startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                       <AccountCircle />
                                                                                    </InputAdornment>
                                                                              )
                                                                        
                                                                        }}
                                                                        

                                                                        onChange = { (e) => setUser(e.target.value)}
                                                                        value = {User}
                                                                        required
                                                                        variant = "filled"
                                                                  />

                                                            </div>
                                                      </Grid>

                                                      
                                                      
                                                      <Grid item xs={12}>
                                                            {/* <div> Enter Password </div> */}
                                                            <div>
                                                                  <TextField 
                                                                        name = 'Password'
                                                                        label = "Enter Password" 
                                                                        type = 'password' 
                                                                        size="md" 
                                                                        InputLabelProps={{
                                                                              style : {color: '#FAF2AB', fontSize: 12, fontWeight: 1000, },
                                                                        
                                                                        }}
                                                                        InputProps={{
                                                                              style : {color: 'cyan', fontSize: 12, fontWeight: 1000,  height: 60, },
                                                                              width: 500,
                                                                              maxWidth: '100%',
                                                                              startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                    <KeyIcon />
                                                                                    </InputAdornment>
                                                                              )
                                                                        }}
                                                                        value = {Pwd}
                                                                        required
                                                                        onChange = {(e) => setPwd(e.target.value)}
                                                                        variant = "filled"
                                                                  />
                                                            </div>

                                                      </Grid>

                                                      
                                                      
                                                      <Grid item xs={12} >
                                                            <Button variant='contained' color='primary' 
                                                                  //   sx={ {width: "160px", background: 'linear-gradient(to right bottom, #FFC107, #01579B)'} } 
                                                                  // sx={ {width: "160px", fontSize:'12px', background: 'linear-gradient(to right bottom, #FFC107, #01579B)'} } 
                                                                  sx={ {width: "160px", fontSize:'12px'} } 
                                                                    fullWidth 
                                                                    onClick = { () => OnValidatePass() } 
                                                                    
                                                                    > 
                                                                  Login  
                                                            </Button>
                                                      </Grid>


                                                      <Grid item xs={12}>
                                                            <FormControlLabel
                                                                  control={
                                                                  <Checkbox
                                                                        checked={KeepMeLogIn}
                                                                        onChange={ (evt) => setKeepMeLogIn( (prev) => !prev)}
                                                                        label={'Keep me logged in'}
                                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                  />
                                                                  }
                                                                  label="Keep me logged in"
                                                            />
                                                      </Grid>
                                                      
                                                      

                                                      <Grid item xs={12}>
                                                                Need an Account? &nbsp;  
                                                                  {/* <Button variant='contained' onClick = { () => alert('dfdf') } > */}
                                                                  <Button variant='outlined' onClick = { () => alert('dfdf') } >
                                                                         {/* put router link here */}
                                                                          {/* <a href="/Pages/UserPages/UserRegister"> */}
                                                                       Register 
                                                                      
                                                                        {/* <a href="/Pages/UserPages/UserRegister.js"> Register </a>      */}
                                                                 </Button>  
                                                           
                                                      </Grid>
                                                     <br/>
                                                      <Grid item xs={1}>
                                                            <FormControlLabel
                                                                  control={
                                                                  <Checkbox
                                                                        name="AdminAccess"
                                                                        checked={ AccessType === "ADMIN" }
                                                                        onChange={ (evt) => {

                                                                              setValidatePass("undefined");  // sentinel value here!
                                                                              setAccessType("ADMIN"); 
                                                                              
                                                                          }}
                                                                        label={'Keep me logged in'}
                                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                  />
                                                                  }
                                                                  label="Admin"
                                                                  sx={styles1.formControlLabel}
                                                            />
                                                            <FormControlLabel
                                                                  control={
                                                                              <Checkbox
                                                                                    name='CoordinatorAccess'
                                                                                    checked={ AccessType === "COORDINATOR" }
                                                                                    onChange={ (evt) => {
                                                                                          
                                                                                          setValidatePass("undefined");  // sentinel value here!
                                                                                          setAccessType("COORDINATOR");
                                                                                            
                                                                                          }}
                                                                                    label={'Keep me logged in'}
                                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                              />
                                                                          }
                                                                  label="Coordinator"
                                                                  sx={styles2.formControlLabel}
                                                                   
                                                                  
                                                            />

                                                      </Grid>


                                                </Grid>

                                          </Paper>

                                         
                                     

                                    </Grid>   
                                         {/* { 
                                          Boolean(isValidatePass) ===  false? 
                                            <p ref = {errRef} aria-live='assertive'> <b><font size={3.5} color='red'> {errMsg} </font> </b> </p> 
                                            : null
                                          }     */}

                             </Grid>

                              
                       )    

                    :  (

                              <HelmetProvider>
                                    <BrowserRouter>
                                          <ThemeProvider>
                                                <ScrollToTop />
                                                <StyledChart />
                                                <Router />
                                          </ThemeProvider>
                                    </BrowserRouter>
                              </HelmetProvider> 
                       ) 
              
            ) 
};

      
   
const App = () => (
      // <Box  
      // //       class="candles"
      // //       style={{
      // //       // backgroundImage: `url("./HR24-Background.png")`,
      // //       backgroundSize: "cover",
      // //       height: "100vh",
      // //       '&::-webkit-scrollbar': { display: 'none' },
      // //   msOverflowStyle: 'none',
      // //   scrollbarWidth: 'none',
            
      // //       }}
      //       paddingX={0}
      //       mt={0}
      //       sx={{
      //         backgroundSize: "cover",
      //         backgroundColor: "#fff",
      //         width: '100%',
      //         height: '100vh',
      //         border: "1px solid #e1e1e1",
      //         overflow: 'none',
      //         '&::-webkit-scrollbar': { display: 'none' }
      //       //   '&::-webkit-scrollbar': { width : 0 },
      //       }}
      //        >
           
         <Login />

      // </Box>     
      
);     
   
     
export default App;

