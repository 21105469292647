
// import  { Navigate, Link, useRoutes, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// const dotenv = require("dotenv").config({ path: "../../../client/.env" }); 


import Axios from 'axios';
import moment from 'moment';
import swAlert from 'sweetalert2';

// @mui
import { Card, Stack, Paper, Avatar, Button, Popover, Checkbox, 
         TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Container, MenuItem, Typography, 
         IconButton
       } from '@mui/material';

// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';

// sections
import { ListHeaders, MasterListToolbar } from '../../sections/@dashboard/Hr';

// import  { getAllStorageSyncData } from '../../api/functions';

import EmpProfile from './EmployeeProfile';
import { data, allEmployees, fetchEmployees, fetchStatus, doFetch, deleteRecord} from '../../features/hr/employeesSlice';
import { userAuthData } from '../../features/users/authsSlice';

import Modal from '../../components/modals/modal';



const styles = {
    button: {
              width: 30, 
              height: 30,
              padding: 3,
            },
    icon:   {
              width: 5, 
              height: 5,
            },
};



const TABLE_HEAD = [
                      { id: '',                label: 'No.s',             alignRight: true },
                      { id: 'EmployeeID',      label: 'EmployeeID',       alignRight: false },
                      { id: 'Fullname',        label: 'Fullname',         alignRight: false },
                      { id: 'Suffix',          label: 'Title',            alignRight: false },
                      { id: 'Gender',          label: 'Gender',           alignRight: false },
                      { id: 'DateBirth',       label: 'DateBirth',        alignRight: false },
                      { id: 'HireDate',        label: 'HireDate',         alignRight: false },
                      { id: 'CivilStatus',     label: 'CivilStatus',      alignRight: false },
                      { id: 'Address',         label: 'Address',          alignRight: false },
                      { id: 'City',            label: 'City',             alignRight: false },
                      { id: 'Position',        label: 'Position',         alignRight: false },
                      { id: 'Active',          label: 'Status',           alignRight: false },
                      { id: 'PaymentOption',   label: 'Payment-Option',   alignRight: false },
                      { id: 'Department',      label: 'Department',       alignRight: false },
                      { id: 'SubDeptName',     label: 'Section',          alignRight: false },
                      { id: 'Unit',            label: 'Unit',             alignRight: false },
                      { id: 'CompanyID',       label: 'ClientID',         alignRight: false },
                      { id: ''},
                  ];
// ----------------------------------------------------------------------


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  
      return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

}


function applySortFilter(array, comparator, query) {
  
     
    // if (Array.isArray(array) === true) {

          const stabilizedThis = array.map((el, index) => [el, index]);
        
          stabilizedThis.sort((a, b) => {
                      const order = comparator(a[0], b[0]);
                      if (order !== 0) return order;
                          return a[1] - b[1];
                  });
          if (query) {
                  return filter(array, (_user) => _user.Fullname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
          }

          return stabilizedThis.map((el) => el[0]);
    
    //    } 
    
    
   // return []
    
}

 
const Employees = ({Status}) =>  {
      
      const dispatch = useDispatch();
      const hprogress = useRef();
      
      const dataProfile =  { ...data };
      const [Profile, setProfile] = useState( {Mode: '', dataProfile, } );


      // const [open, setOpen] = useState(null);
      const [open, setOpen] = useState({selected: null, id: ''});

      const [page, setPage] = useState(0);

      const [order, setOrder] = useState('asc');

      const [selected, setSelected] = useState([]);

      const [orderBy, setOrderBy] = useState('EmployeeID');

      const [filterName, setFilterName] = useState('');

      const [rowsPerPage, setRowsPerPage] = useState(25);
     
      const [fetchProgressValue, setfetchProgressValue]  = useState (0);
           
      
      // useEffect ( () => {
      //            // Axios.get("http://localhost:8800/api/employees", {params: {status: 'active'} })
                 
      //            // alert (`${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/api/employees/:status=${Status}`)
      //            //  Axios.defaults.withCredentials = true;  // very important on the client side!!!  
      //           // :${process.env.REACT_APP_PORT}
      //           // Axios.get(`${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/api/employees/:status=${Status}`)

      //             Axios.get(`/api/employees/:status=${Status}`)
      //                     .then ((res) =>  {
      //                           setList(res.data)
      //                           // console.log (`res.data:  ${res.data}`) 
      //                       })
      //                     .catch( (err) => {
      //                           setList([])  
      //                           console.log (err);
      //                       });
                      
      //   }, []) 
      
    
      useEffect ( () => {
            dispatch(doFetch())
      }, [dispatch])   // }, [])

      const fStatus  = useSelector (fetchStatus);

      
      const {UserRecordLevelRights}  = useSelector (userAuthData);
      
      
      useEffect ( () => {
                    if (fStatus === 'Idle') {
                        dispatch (fetchEmployees( {Status, UserRecordLevelRights, setfetchProgressValue} ) );
                    } 
      }, [fStatus, Status, dispatch, UserRecordLevelRights])  // }, [fStatus, Status, dispatch]) 

    
      // ====================================================================================================================================  
    
      // const fetchProgressValue  = useSelector (fetchProgress);


      // useEffect(() => {
      //   document.addEventListener("storage", localStorage.getItem("fetchProgress"));
      //   return () => {
      //     fetchProgressValue = localStorage.getItem("fetchProgress");
      //   };
      // }, [fetchProgressValue]);


      // useEffect(() => {
         
      //    if(fStatus === 'Loading') {
      //      setfetchProgressValue(localStorage.getItem('fetchProgress') )
      //    }  

      // }, fetchProgressValue)
      
      // setfetchProgressValue(localStorage.getItem('fetchProgress') )

     // const fetchProgressValue = localStorage.getItem("fetchProgress");
      // hprogress.textContent  = `Waiting ... ${ Number.isNaN(fetchProgressValue)? 0: fetchProgressValue   }%`
      // document.getElementById("hprogress").innerHTML = `Waiting ... $ { Number(fetchProgressValue , 0)  }%`
      //  hprogress.textContent  = `Waiting ... ${ fetchProgressValue } %`


      // document.addEventListener('DOMContentLoaded', e => {
      //   const h1 = document.querySelector("h1")
  
      //   h1.innerText = "Correct Text"
      // })


    // ====================================================================================================================================

      const List  =  useSelector (allEmployees); 

      // const tmpList  =  useSelector (allEmployees); 
      // const [List, setList]  =  useState ([]); 

      // useEffect ( () => {
      //         setList (tmpList)
      // }, [tmpList])
      
      
      
     

      const handleOpenMenu = (evt, empID) => setOpen({selected: evt.currentTarget, id: empID});
      const handleCloseMenu = () => setOpen({selected: null, id: ''});
    


      const handleRequestSort = (event, property) => {
            
            const isAsc = orderBy === property && order === 'asc';
            
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);

      };

      const handleSelectAllClick = (event) => {
         
            if (event.target.checked) {
                const newSelecteds = List.map((n) => n.EmployeeID);
                setSelected(newSelecteds);
                return;
            }

         setSelected([]);
         
      };

    //  const s =  selected.map ( (item, idx) => {
    //       return console.log(`selected: ${idx +1} : item ${item}`)      
    //   } )
      

      const handleClick = (event, EmployeeID) => {
            
            const selectedIndex = selected.indexOf(EmployeeID);
           
            let newSelected = [];
      
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, EmployeeID);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            }
      
            setSelected(newSelected);
          
      };


      const handleChangePage = (event, newPage) => {
            setPage(newPage);
      };

      const handleChangeRowsPerPage = (event) => {
            setPage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
      };

      const handleFilterByName = (event) => {
            setPage(0);
            setFilterName(event.target.value);
            // alert(event.target.value)
      };


      // function openProfile () {

      //  return   <EmpProfile data={ {Profile: true, Mode: 'New'} } />  
      // // return alert('dfdf');
          
      // }

      
      const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - List.length) : 0;

      const filteredList =  applySortFilter(List, getComparator(order, orderBy), filterName);   

      const isNotFound = !filteredList.length && !!filterName; 
  

    
      
    // const obj = getAllStorageSyncData('fetchProgress');

   
  
  return (
    
    <div style={{ width: '95%', height: '80vh', border: 0, overflow: 'hidden'  }} >

      
      {/* { alert(`Profile.Mode ${Profile.Mode}`)} */}

      {
          // Profile.Mode === 'AddNewRecord' &&  <EmpProfile data={Profile} setProfile = { (prev) => setProfile({...prev, Mode: '', dataProfile: {...data} } )  } /> 
          Profile.Mode === 'AddNewRecord' &&  <EmpProfile UserRecordLevelRights={UserRecordLevelRights} data={Profile} setData = { (prev) => setProfile({...prev, Mode: '', dataProfile: {...data} } )  } /> 
      }
      
      {
          // Profile.Mode === 'EditRecord' &&  <EmpProfile data={Profile} setProfile = { (prev) => setProfile({...prev, Mode: ''} )  } /> 
          Profile.Mode === 'EditRecord' &&  <EmpProfile UserRecordLevelRights={UserRecordLevelRights} data={Profile} setData = { (prev) => setProfile({...prev, Mode: ''} )  } /> 
          
      }

      {
        // ModalPDF()
      }


      <Helmet>
         <title> Employees </title>
      </Helmet>

      {/* <Container sx={{ minWidth: '95%', minHeight: '55%', overflow: 'hidden', border: 2, borderColor: 'red'}}  > */}
      {/* <Container sx={{ minWidth: '95%', minHeight: '25%', overflow: 'hidden', border: 1, borderColor: 'red'}}  > */}
      <Container sx={{ minWidth: '95%', height: '55vh', border: 0, borderColor: 'red'}}  >
        
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          
              <Typography variant="h4" gutterBottom>
                  Employees
              </Typography>
              
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} color='secondary' onClick={ () =>  setProfile( {Mode: 'AddNewRecord', dataProfile }) }>
              {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} color='secondary' onClick={ () =>  setProfile({IsAddNewProfile: true, Mode: 'New'}) }> */}
              {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} color='secondary' onClick={ () => openProfile() }> */}
                  New Employee
              </Button>
              
              

        </Stack>

        {/* <Card sx={{ minWidth: '95%', minHeight: '10%' }}> */}
        <Card>

                {/* Search name ... and filter box */}
                <MasterListToolbar lsType="Employees" list={selected} numSelected = {selected.length} filterName = {filterName} onFilterName = { handleFilterByName } />


                <Scrollbar>
                        
                        {/* <TableContainer component = {Paper} style={{ height: '55vh', maxBodyHeight: '600px',   }} sx={{ border: 0,  maxWidth: '100%', overflow: 'scroll', 'flex-grow': 0, }} > */}
                        <TableContainer component = {Paper} style={{ height: '55vh', maxBodyHeight: '600px',   }} sx={{ border: 0,  maxWidth: '100%', overflow: 'vscroll', }} >
                        {/* <TableContainer component = {Paper} style={{ height: '10%'  }} sx={{ border: 0,  maxWidth: '100%', maxBodyHeight: 80, }} > */}
                        

                            {/* <Table stickyHeader aria-label="sticky table" style={{ height: '10%', maxBodyHeight: 80,  }} sx={{ border: 0,  maxWidth: '100%',  }} > */}
                            {/* <Table stickyHeader aria-label="sticky table"  sx={{ maxWidth: '100%', maxHeight: '55px',  border: 2,  }} > */}
                            <Table stickyHeader aria-label="sticky table"  >
                               
                                <ListHeaders 
                                      order=             {order}
                                      orderBy=           {orderBy}
                                      headLabel=         {TABLE_HEAD}
                                      rowCount=          {List.length}
                                      numSelected=       {selected.length}
                                      onRequestSort=     {handleRequestSort}
                                      onSelectAllClick=  {handleSelectAllClick}
                                />
                                

                                <TableBody>
                                
                                {
                                  fStatus === 'Succeeded'?
                                  
                                    (    filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => {
                                        

                                                const { EmployeeID, Fullname, Suffix, Gender,DateBirth, Address,City, HireDate,CivilStatus, Active, Position,PaymentOption,Department,Section,Unit, ClientID } = row;
                                                const selectedRecord = selected.indexOf(EmployeeID) !== -1;


                                                return (
                                                
                                                      <TableRow hover key={EmployeeID} tabIndex={-1} role="checkbox" selected={selectedRecord} style={{cursor: 'pointer'}} onDoubleClick = { 
                                                                () =>  { 
                                                                           setProfile( (prev) => ({...prev, Mode: 'EditRecord',  dataProfile: {...prev.dataProfile, EmployeeID}  }) );
                                                                        } }
                                                         
                                                      >
                                                    
                                                          <TableCell padding="checkbox" size='small'>
                                                              <Checkbox checked={selectedRecord} onChange={(event) => handleClick(event, EmployeeID)} />
                                                          </TableCell>

                                                          <TableCell align="left"  size='small'  padding="none"> { idx+1 } </TableCell>


                                                          <TableCell component="th" scope="row" padding="none"  size='small' >
                                                              <Stack direction="row" alignItems="center" spacing={1}>
                                                                  {/* <Avatar alt={EmployeeID} src={avatarUrl} /> */}
                                                                  <Typography variant="subtitle2" noWrap>
                                                                       {EmployeeID}
                                                                  </Typography>
                                                              </Stack>
                                                          </TableCell>

                                                          <TableCell align="left"  size='small' padding="none">{Fullname}</TableCell>

                                                          {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                                                          
                                                          <TableCell align="left"  size='small'  padding="none"> {Suffix} </TableCell>
                                                          <TableCell align="left"  size='small'  padding="none"> {Gender} </TableCell>
                                                          <TableCell align="left"  size='small'  padding="none"> { moment(DateBirth).format('MM/DD/YYYY') } </TableCell>
                                                          <TableCell align="left"  size='small'  padding="none"> { moment(HireDate).format('MM/DD/YYYY')  } </TableCell>
                                                          <TableCell align="left"  size='small'  padding="none"> {CivilStatus} </TableCell>
                                                          <TableCell align="left"  size='small'  padding="none">{Address}</TableCell>
                                                          <TableCell align="left"  size='small'  padding="none">{City}</TableCell>
                                                          <TableCell align="left"  size='small'  padding="none">{Position}</TableCell>
                                                          
                                                          {/* <TableCell align="left">
                                                            <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                                                          </TableCell> */}
                                                          <TableCell align="left" size='small' padding="none">
                                                              <Label color={ (Active === 1 && 'success') || 'error'} > {sentenceCase( Active === 1? 'Active' : 'Inactive')} </Label>
                                                          </TableCell>    

                                                          <TableCell align="left"  size='small' padding="none">{PaymentOption}</TableCell>
                                                          <TableCell align="left"  size='small' padding="none">{Department}</TableCell>
                                                          <TableCell align="left"  size='small' padding="none">{Section}</TableCell>
                                                          <TableCell align="left"  size='small' padding="none">{Unit}</TableCell>
                                                          <TableCell align="left"  size='small' padding="none">{ClientID}</TableCell>

                                                          
                                                          <TableCell align="right" size='small' padding="none">
                                                              <IconButton style={styles.button } iconStyle={styles.icon} color="inherit" onClick = { (evt) => handleOpenMenu (evt, EmployeeID) }>
                                                                  <Iconify icon={'eva:more-vertical-fill'}  />
                                                              </IconButton>
                                                          </TableCell>
                                                      
                                                    </TableRow>
                                                    

                                                ); // return (
                                        }) // filteredList.slice(page

                                    )  // (    filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => {

                                  : 
                                     
                                    // fStatus === 'Loading'?   <h1> Wait, Loading ... </h1> : <h1> Error has been encountered !!! </h1>
                                    // fStatus === 'Loading'? <div>  <h1 id='hprogress' ref={hprogress}> {`Waiting ... ${fetchProgressValue}%`} </h1>  <progress value={ fetchProgressValue } max="100" >  </progress>  </div>: <h1> Error has been encountered !!! </h1>
                                    fStatus === 'Loading'? <div>  <h1 id='hprogress' ref={hprogress}> {`Waiting ... ${fetchProgressValue}%`} </h1>  <progress value={ fetchProgressValue } max="100" >  </progress>  </div> : <h1> {`Error has been encountered !!! ${fStatus}` } </h1> 
                                    
                                }  

                          

                                  {
                                    emptyRows > 0 && (
                                      <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={12} />
                                      </TableRow>
                                      )
                                  }
                                

                                </TableBody>


                                { isNotFound && (
                                
                                      <TableBody>
                                            
                                          <TableRow>
                                            
                                              <TableCell align="center" colSpan={24} sx={{ py: 3 }}>
                                                    <Paper
                                                      sx={{
                                                        textAlign: 'center',
                                                      }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found !!!
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{filterName}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                              </TableCell>
                                            
                                          </TableRow>

                                      </TableBody>

                                )}

                          </Table>

                        </TableContainer>

                </Scrollbar>

              <TablePagination
                  rowsPerPageOptions = {[5, 10, 25, 50, 75, 100]}
                  component=           "div"
                  count=               {List.length}
                  rowsPerPage=         {rowsPerPage}
                  page=                {page}
                  onPageChange=        {handleChangePage}
                  onRowsPerPageChange= {handleChangeRowsPerPage}
              />

        </Card>

      </Container>
      
      

      {/* <Popover
          open =            {Boolean(open)}
          anchorEl =        {open}
          onClose =         {handleCloseMenu} */}
     
      <Popover
          open =            {Boolean(open.selected)}
          anchorEl =        {open.selected}
          onClose =         {handleCloseMenu}
          anchorOrigin =    {{ vertical: 'top', horizontal: 'left' }}
          transformOrigin = {{ vertical: 'top', horizontal: 'right' }}
          PaperProps = {{
                          sx: {
                            p: 1,
                            width: 140,
                            '& .MuiMenuItem-root': {
                              px: 1,
                              typography: 'body2',
                              borderRadius: 0.75,
                            },
                          },
                      }}
      >
            {/* Mode: 'AddNewRecord' */}
            
            { // alternate code:
            /* <MenuItem  onClick={ () =>  (function (){ 
                                          setProfile( {Mode: 'EditRecord', Id: open.id} );
                                          setOpen ({selected: null, id: ''})
                                        })() */}

            <MenuItem  onClick={ () =>  { 
                                          // setProfile( (prev) => ({...prev, Mode: 'EditRecord',  Id: open.id}) );
                                          setProfile( (prev) => ({...prev, Mode: 'EditRecord',  dataProfile: {...prev.dataProfile, EmployeeID: open.id}  }) );
                                          
                                          setOpen ({selected: null, id: ''});
                                        }
                               } 
            >
                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} /> { `Edit ${open.id}` }
            </MenuItem>

            <MenuItem sx={{ color: 'error.main' }}  
                      onClick={ () => { 
                                    // setProfile( (prev) => ({...prev, Mode: 'DeleteRecord',  dataProfile: {...prev.dataProfile, EmployeeID: open.id}  }) ) 
                                    
                                    // Ref: https://bobbyhadz.com/blog/javascript-edit-alert-title#:~:text=It%20is%20not%20possible%20to,module%20using%20NPM%20or%20YARN.

                                    if (selected.length === 0) {
                                      // alert ("Record is not yet selected!, Just select the record and try again", "Record is not yet selected")
                                      swAlert.fire({
                                        title: 'Record Status',
                                        text: 'Record is not yet selected! Just select the record and try again',
                                        icon: 'info',
                                        confirmButtonText: 'OK',
                                      });
                                   }  
                                    
                                    // if (selected.length > 0 && window.confirm(`Are you sure you want to delete this record?`, `Delete Record (${selected.length})`) === true ) {
                                    //     dispatch (deleteRecord(selected) )
                                    // }

                                    if (selected.length > 0 ) {
                                    
                                              swAlert.fire({
                                                            title: 'Are you sure?',
                                                            text: "You won't be able to revert this!",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: `Yes, delete (${selected.length}) it!`
                                                            })
                                                       .then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                dispatch (deleteRecord(selected) )    
                                                                                swAlert.fire(
                                                                                              'Deleted!',
                                                                                              `Record${selected.length>1? 's':''} has been deleted.`,
                                                                                              'success'
                                                                                )
                                                                            }
                                                       })

                                    }
                        }} 
            >

                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} /> { `Delete ${open.id}` }
            </MenuItem>

      </Popover>
 
      

    </div>
  );
}





export default Employees;


