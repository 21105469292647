import {Routes, Route, useLocation} from 'react-router-dom'
import {Branches, Departments,Sections, Statuses, Positions, TypeofWarnings,TypeofLeaves, Employees, Holidays, Events, Leaves, Warnings, Itineraries, OB, LeaveCreditBalances } from './HrPages';

// import Navbar from './HrPages/HrMenu';
import BurgerMenu from './HrPages/Components/HrHamburgerMenu';


const HrNavigationMenu = () => (
     
      

      <div style={{ width: '90%', position: 'fixed', border: 0, }} >
          {/* <Navbar /> */}
          

          <BurgerMenu />

          <br/>
          <br/>
          
          

          {useLocation().pathname === '/dashboard/hr/branches'? <Branches /> : null}
          {useLocation().pathname === '/dashboard/hr/departments'? <Departments /> : null}
          {useLocation().pathname === '/dashboard/hr/sections'? <Sections /> : null}
          {useLocation().pathname === '/dashboard/hr/statuses'? <Statuses /> : null}
          {useLocation().pathname === '/dashboard/hr/positions'? <Positions /> : null}
          {useLocation().pathname === '/dashboard/hr/typeofwarnings'? <TypeofWarnings /> : null}
          {useLocation().pathname === '/dashboard/hr/typeofleaves'? <TypeofLeaves /> : null}
          
          {useLocation().pathname === '/dashboard/hr'?  <Employees Status='all' /> : null }
          {useLocation().pathname === '/dashboard/hr/employees/active'? <Employees Status='active' /> : null }
          {useLocation().pathname === '/dashboard/hr/employees/inactive'? <Employees Status='inactive'/> : null }
          {useLocation().pathname === '/dashboard/hr/employees/all'?  <Employees Status='all' /> : null }
          


          {useLocation().pathname === '/dashboard/hr/holidays'? <Holidays /> : null}
          {useLocation().pathname === '/dashboard/hr/events'?  <Events />: null}

          {useLocation().pathname === '/dashboard/hr/warnings-and-suspensions'? <Warnings /> : null}

          {useLocation().pathname === '/dashboard/hr/leaves'?  <Leaves />: null}
          {useLocation().pathname === '/dashboard/hr/leave-credit-and-balances'?  <LeaveCreditBalances />: null}

          {useLocation().pathname === '/dashboard/hr/itinerary'? <Itineraries /> : null} 
          
          {/* {
            alert (useLocation().pathname)
          } */}

          {/* <Routes>
                 <Route path='/dashbord/hr' element={ <Employees /> }/>
                 <Route path='/dashbord/hr/travel' element={Travel}/>
                 <Route path='/dashbord/hr/OB' element={OB}/>
          </Routes>  */}
          
      </div>

  )




export default HrNavigationMenu;