import React, { Component } from 'react';



function  OB() {
   
    console.log('I was triggered during render addUser');
    return (
       <div>Hello world! O.B</div>
      );
   
}

export default OB;