import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker as MUITimePicker } from '@mui/x-date-pickers/TimePicker';


export default function TimePicker() {
  // const [value, setValue] = React.useState(dayjs('2022-04-17T15:30'));
  const [value, setValue] = React.useState(dayjs(new Date));

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
             {/* <DemoContainer components={['TimePicker', 'TimePicker']}> */}
                  {/* <TimePicker
                     label="Uncontrolled picker"
                     defaultValue={dayjs('2022-04-17T15:30')}
                  /> */}
                  <MUITimePicker
                        label     =  ""
                        value     =  { value }
                        onChange  =  { (newValue) => setValue(newValue) }
                        sx       =  { {
                           width: "100%",
                           height: "20px",
                           padding: "0px" ,
                        }
                      }
                      slotProps={{ textField: { size: 'small' } }}  
                         
                  />
            {/* </DemoContainer> */}
      </LocalizationProvider>
  );

}