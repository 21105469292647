import React, {useState} from 'react';



export function useForm (initialFieldValues) {
    
   const [values, setValues] = useState(initialFieldValues)
   
   // alert (`useForm's value ${JSON.stringify(value)}`)

   const onInputChange = (evt) => {
       
      // Method #1
      setValues( (prev) => ( {...prev, [evt.target.name]: evt.target.value}) )
      
      // Method #2
      // const {name, values} = evt.target;
      // setValues( 
      //            {...values, [name]: values}
      //          )
      
  }


   return (
               {
                  values,
                  setValues,
                  onInputChange,
               }
          )

}




export function Form (props) {

   return (
           <form>
              {props.children}  
           </form>
   )
   
}