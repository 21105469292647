
// use for user authentication purposes ...
import {createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Axios from 'axios';
import moment from 'moment';


import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {userAuthData} from '../users/authsSlice';




const chrome = null;




// // // export const fetchEmployees =  createAsyncThunk("employees/fetchEmployees",  async (Status) => {
      
// // //     // console.log (`fetchEmployees ${ Status }`)

// // //     let response = [];

// // //     try {
// // //           response = await Axios.get(`/api/employees/:status=${Status}`)
// // //                                 .then ((res) => res.data);
          
// // //     } catch (error) {
// // //           console.error(`fetchEmployees thunk: error: ${error}`);
// // //     }

// // //     // alert (`fetchEmployees thunk ${Object.keys(response)}`)
// // //     return response;

// // // });

export const fetchEmployees =  createAsyncThunk("employees/fetchEmployees",  async ({Status, UserRecordLevelRights, setfetchProgressValue}) => {
      
      
      // console.log (`createAsyncThunk("employees/fetchEmployees" ${JSON.stringify(UserRecordLevelRights)}`)
  
      let response = [];
      // const configProgress = {
      //       onDownloadProgress: progressEvent => {
      //                                                 const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
      //                                                 const current = progressEvent.currentTarget.response.length
                                                
      //                                                 const percentCompleted = Math.floor((current / total) * 100)
      //                                                 console.log('Completed: ', percentCompleted)
      //                                            }
      // };
      
      const configProgress = {
            onDownloadProgress: progressEvent => {
                                                      const total = parseFloat(progressEvent.event.target.getResponseHeader('Content-Length'));
                                                      const current = progressEvent.event.target.response.length
                                                
                                                      const percentCompleted = Math.round((current / total) * 100)
                                                      // dispatch(doFetchProgress(percentCompleted))
                                                    
                                                      setfetchProgressValue(percentCompleted) 

                                                     // localStorage.setItem("fetchProgress", percentCompleted);
                                                     // console.log(`Completed: percentCompleted: ${percentCompleted}`)
                                                     // console.log('Completed: ', percentCompleted)
                                                    
                                                 }
      };

      // const configProgress = { 
      //         onDownloadProgress: (progressEvent) => {
      //                                                       const progress = progressEvent.loaded / progressEvent.event.target.getResponseHeader('x-file-size');
      //                                                       const percent = Math.round(progress * 100);
      //                                                       console.log(`Percent Complete  ${percent}%`)
      //                                                }
      // }

      

      try {
            // response = await Axios.get(`/api/employees/:status=${Status}`,  configProgress)
            response = await Axios.get(`/api/employees/status`, {params: {Status, UserRecordLevelRights }}, configProgress)
                                  .then ((res) => res.data);
            
      } catch (err) {
            console.error(`fetchEmployees thunk: error: ${err}`);
      }
      
            // alert (`fetchEmployees thunk ${Object.keys(response)}`)
            return response;
  
  });

  
 
export const getNewID = createAsyncThunk("employees/getNewID",  async ({AreaCode}) => {
                                          
      // const areacode = action.payload.areacode;
      const zeroPad = (num, places) => String(num).padStart(places, '0');

       const res =  await Axios.get(`/api/employees/newid`, {params: {AreaCode} })
                         .then ((res) =>  {
                             // console.log(`res.data ${ JSON.stringify(res.data[0])}`)
                              console.log ('Record has successfuly been updated!!!')
                              return res.data[0]
             
                         })
                         .catch((err) => {
                              console.log (err);
                         })
                   
              // console.log (`getNewID.res  : ${ JSON.stringify(res)}`)
              const newID = `${AreaCode}-${zeroPad (res.NewID, 4)}`
              console.log (`getNewID.res, zeroPad  : ${newID}`)

             // state = {...state,  fetchProfileStatus: 'Idle', Profile: {...data, EmployeeID: newID } };
             
       return newID; // state;  
});


export const fetchProfile =  createAsyncThunk("employees/fetchprofile",  async (Id) => {
      
      // alert (`JSON.stringify(data) ${JSON.stringify()}`)
     // alert ('jeff now!')
   
      let response = {};
   
       try {
             
            // response = await Axios.get('/api/employees/data-entry/data', { params: { id: Id} }).then ( (result) => result.data[0]);

             response = await Axios.get('/api/employees/data-entry/data', { params: { id: Id} } );
             response = response.data[0];                       

       
       } catch (err) {
            
            console.log(`fetchProfile thunk: error: ${err}`);
            // console.log(`err.response.data : ${err.response.data}`);
            // console.log(`err.response.status : ${err.response.status}`);
            // console.log(`err.response.headers : ${err.response.headers}`);

       } 


   
      // alert (`fetchProfile thunk ${ JSON.stringify(response)}`)
      
      // console.log (`fetchProfile: JSON.stringify(response)`) 
      // console.log (JSON.stringify(response))

      
      return response;
   
   });


export const data = {
                        EmployeeID:       '',
                        EnrollNo:         '',
                        Lastname:         '',
                        Firstname:        '',
                        Middlename:       '',
                        Gender:           'Male',
                        Birthdate:        moment(new Date()).format('MM/DD/YYYY'),
                        Address:          '',
                        City:             '',
                        Hiredate:         moment(new Date()).format('MM/DD/YYYY'),
                        DateofResignation: null,
                        Payperiod:        'Semi-Monthly',
                        Deductionperiod:  'Semi-Monthly',
                        Department:       '',
                        Active:           true,
                        Photo:            null,
                        
                        PosID:            '',
                        DeptID:           '',
                        StatusID:         '',
                        SPCode:           '',
                        ShiftID:          '',

                        RestMon:       false,
                        RestTue:       false,
                        RestWed:       false,
                        RestThu:       false,
                        RestFri:       false,
                        RestSat:       false,
                        RestSun:       false,

                        AutoEarlyOTComputation: false,
                        AutoOTComputation: false,
                        AllowLeave: false,
                        LatesEx: false,
                        UTEx: false,
                        OTEx: false,
                        AbsentEx: false,
                        NDiffEx: false,
                        RestDayEx: false,
                        HolidayEx: false,
                        HolidayRestEx: false,

                        Notes:        '',
                        
                        BankAcctName1:    '',
                        BankAcctNo1:      '',
                        BankAcctName2:    '',
                        BankAcctNo2:      '',
                        TIN:              '',
                        PagIBIGNo:        '',
                        SSSNo:            '',
                        PhilHealthNo:     '',
                        ProvAdd:          '',
                        MobileNo:         '',
                        EmailAddress:     '',
                        ZipCode:          '',
                        Landline:          '',
                        EmergencyContactPerson:     '',
                        EmergencyContactNumber:     '',
                        CivilStatus:      '',

                        BasicRPM:          0,
                        BasicRPD:          0,
                        No_Of_Days:       13,
                        No_Of_Hrs:        8,
                        PayPeriod:        'Semi-Monthly',
                        DeductionPeriod:  'Semi-Monthly',
                        RateHour:         0,
                        RateDay:          0,
                        EEMR_ID:          '' ,
                        isEEMR:           false,
                        PaymentOption:    'CASH',

                        PagIbigECont:     0,
                        PagIbigERCont:    0,
                        MED_EE:           0,
                        MED_ER:           0,
                        ETax:             0,
                        SSS_ER:           0,
                        EC_ER:            0,
                        SSS_EE:           0,

                        SSSContSet:       0,
                        SSSContTableSet:  0,
                        PagIbigContSet:   0,
                        PagIbigContTableSet: 0,
                        PhilHealthContSet : 0,
                        PhilHealthContTableSet: 0,
                        ETaxSet:           0,
                        ETaxTableSet:      0,
                        
   };

const initialState = {
                        List:                [],
                        Profile:             {...data},
 
                        isValidate:          false,              // Validate for saving records.
                        fetchProfileStatus:  'Idle',             // 'Init || Idle' || 'Loading' || 'Succeeded' || 'Failed' ===> fetch records !!!
                        fetchStatus:         'Idle',             // 'Idle' || 'Loading' || 'Succeeded' || 'Failed' ===> fetch records !!!
                        fetchProgress:        0,
                     }


const employeesSlice = createSlice (
   {
      name: 'employees',
      initialState,
      reducers:  {
            
                        setEmployee:    (state, action) => {
                                             
                                             // state = {...state, Username: action.payload.Username, Password: action.payload.Password,  isValidate: getValidate(action.payload)}; 
                                             // state = {...state, isValidate: action.payload.isValidate}

                                             // console.log(`action.payload ${Object.keys(action.payload)}`)

                                            //  console.log(`action.payload ${Object.values(action.payload)}`)
                                             
                                             // state = {...state, ...action.payload};
                                             state.Profile = {...state.Profile, ...action.payload};
                                             // console.log(`state ${state.Profile.EmployeeID}`)
                                             return state;
                                    },

                        setCurrentID:    (state, action) => {
                                    
                              // console.log(`action.payload ${Object.keys(action.payload)}`)
                              //  console.log(`action.payload ${Object.values(action.payload)}`)
                              
                              // state = {...state, ...action.payload};
                              // state = {...state, {...Profile, EmployeeID: action.payload.EmployeeID} };
                              state.Profile = {...state.Profile, EmployeeID: action.payload.EmployeeID };
                              // console.log(`state currentID ${state.EmployeeID}`)
                              return state;
                        },
                        
                        isValidate: (state, action) => {
                              // validate the state.Profile if applicable for saving...
                              
                              // Form level validation or Field Level Validation (ref=useReference)
                              // const isCanSave = [state.Profile.EmployeeID, state.Profile.Lastname, state.Profile.Firstname, state.Profile.Middlename, state.Profile.StatusID, state.Profile.DeptID].every(Boolean) && addRequestStatus === 'Idle' ;
                              const isCanSave = [state.Profile.EmployeeID, state.Profile.Lastname, state.Profile.Firstname, state.Profile.Middlename].every(Boolean) ;


                              state = {...state, isValidate: isCanSave };
                              return state;

                        },

                        setDefaultValues: (state, action) => {
                              
                              state.Profile = {...state.Profile, EmployeeID: '', Lastname: '', Firstname: '', Middlename: '' };
                              return state;

                        },
                        
                        doFetch: (state) => {
                              
                              state = {...state, fetchStatus: 'Idle' };
                              return state;

                        },

                        doFetchProgress: (state, {percentCompleted}) => {
                              
                              state = {...state, fetchProgress: percentCompleted };
                              return state;

                        },

                        
                        doProfileFetch: (state, action) => {
                              console.log (`doProfileFetch - payload ${action.payload}`)

                              // state = {...state, fetchProfileStatus: 'Idle' };
                              state = {...state, Profile: action.payload === 'Init'? {...data} : {...state.Profile},  fetchProfileStatus: action.payload };
                              
                              return state;

                        },

                        
                        

                        addNewRecord:     (state, {payload})  => {
                              
                                                // state = {...state, fetchStatus: 'Idle', Profile: payload  };
                                                state = {...state, fetchStatus: 'Idle', fetchProfileStatus: 'Init', Profile: payload  };
                                                console.log (`addnewrecord-state: ${ JSON.stringify(state.Profile)}`)
                                                // console.log (`addnewrecord-state ...`)
                                                
                                                Axios.post(`/api/employees/:mode='AddNewRecord'/:id='${state.Profile.EmployeeID}'`, state.Profile)
                                                .then ((res) =>  {
                                                      // console.log(`res.data ${ JSON.stringify(res.data[0])}`)
                                                      console.log ('Record has successfuly been updated!!!')
                                                
                                                      })
                                                .catch((err) => {
                                                      console.log (err);
                                                      })
                                                      
                                                
                                                return state;  

                                          },

                        editRecord:     (state, {payload})  => {
                              
                                                state = {...state,  fetchStatus: 'Idle',  Profile: payload  };
                                                console.log (`editrecord-state: ${ JSON.stringify(state.Profile)}`)

                                                // alert(`dispatch app.post ('/api/employees/:mode/:id/:data', (req, res) => {`)
                                                // Axios.post(`/api/employees/:mode='EditRecord'/:id='${state.Profile.EmployeeID}'/:data=${state.Profile}`)
                                                Axios.post(`/api/employees/:mode='EditRecord'/:id='${state.Profile.EmployeeID}'`, state.Profile)
                                                .then ((res) =>  {
                                                      // console.log(`res.data ${ JSON.stringify(res.data[0])}`)
                                                      console.log ('Record has successfuly been updated!!!')
                                                
                                                      })
                                                .catch((err) => {
                                                      console.log (err);
                                                      })
                                                      
                                                
                                                return state;   


                                          },
                        
                        deleteRecord:     (state, {payload})  => {
                              // *** TEST ***
                                                // console.log(`payload ${ JSON.stringify(payload)}`)
                                                
                                                console.log(`deleteRecord {payload} ${payload}`)
                                                
                                                state = {...state,  fetchStatus: 'Idle' };

                                                const selected =  payload;
                                                const s =  selected.map ( (item, idx) => {
                                                      return console.log(`selected: ${idx +1} : ${item}`)      
                                                } )
                                                
                                                // reserved code: axios.delete("/<your endpoint>", { data:<"payload object">})
                                                
                                                Axios.delete(`/api/employees/delete`, {headers: {type: 'Array'}, data: payload} )
                                                .then ((res) =>  {
                                                
                                                console.log ('Record has successfuly been deleted!!!')
                                                
                                                })
                                                .catch((err) => {
                                                      console.log (err);
                                                })
                                                
                                          
                                                return state;   

                                          } 
      },


      extraReducers: (builder) => {
                      builder
                        
                             .addCase(getNewID.pending, (state, action) => {
                                  state = {...state, fetchProfileStatus: 'Idle',  Profile: {...data} };    
                                  return state;       
                              })
                             .addCase(getNewID.fulfilled, (state, action) => {
                                   // console.log('Promise has been fulfilled !!!')  
                                   // console.log(`getNewID/action.payload ${JSON.stringify(action.payload)}`)
                                   state = {...state,  fetchProfileStatus: 'Succeeded', Profile: {...state.Profile, EmployeeID: action.payload, EnrollNo: action.payload } };
                              
                                    // console.log(`state.Profile : ${JSON.stringify(state.Profile)}`)
                                    return state;       
                              })
                             .addCase(getNewID.rejected, (state, action) => {
                                    state = {...state, fetchProfileStatus: 'Rejected',  Profile: {...data} };    
                                    return state;       
                              })
                             
                             
                              // ===================================================================================================================
                             
                              .addCase(fetchEmployees.pending, (state, action) => {
                                    state = {...state, fetchStatus: 'Loading',  List: [] };    
                                    return state;       
                              })
                              .addCase(fetchEmployees.fulfilled, (state, action) => {
                                    // console.log('Promise has been fulfilled !!!')  
                                    state = {...state, fetchStatus: 'Succeeded', List: action.payload };
                                    return state;       
                              })
                              .addCase(fetchEmployees.rejected, (state, action) => {
                                    state = {...state, fetchStatus: 'Rejected', List: [] };    
                                    return state;       
                              })
                              
                        
                              // ===================================================================================================================
                        
                              .addCase(fetchProfile.pending, (state, action) => {
                                    state = {...state, fetchProfileStatus: 'Idle',  Profile: {...data} };    
                                    return state;       
                                    })
                              .addCase(fetchProfile.fulfilled, (state, action) => {
                                    // console.log('Promise has been fulfilled !!!')  
                                    // console.log(`fetchProfile/action.payload ${JSON.stringify(action.payload)}`)
                                    state = {...state, fetchProfileStatus: 'Succeeded', Profile: action.payload !== 'undefined'? action.payload: {...data}  };
                                    
                                    // alert(`state.Profile ${ JSON.stringify(state.Profile)}`)
                                    return state;       
                                    })
                              .addCase(fetchProfile.rejected, (state, action) => {
                                    state = {...state, fetchProfileStatus: 'Rejected',  Profile: {...data} };    
                                    return state;       
                                    })
                              }      
            
   }

)


export const  employeeData = (state) => state.employees.Profile;
export const  allEmployees = (state) => state.employees.List;
export const  fetchStatus = (state) => state.employees.fetchStatus;
export const  fetchProgress = (state) => state.employees.fetchProgress;
export const  fetchProfStatus = (state) => state.employees.fetchProfileStatus;

export const { setEmployee, setCurrentID, addNewRecord, editRecord, deleteRecord, doFetch, doFetchProgress, doProfileFetch } = employeesSlice.actions;
export default employeesSlice.reducer;
